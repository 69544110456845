import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";
import { Map } from "@mui/icons-material";
import SensorIcon from "components/SensorIcon";
import DivExist from "components/DivExist";
import DivSpaceBetween from "components/DivSpaceBetween";
import { lightStyleMenu, sensorStyleMenu } from "asset/string/string";
import { titleText, dataText } from "asset/string/color";
import GatewayIcon from "components/GatewayIcon";
import LightIcon from "components/LightIcon";
import DaliCtl from "asset/svgComp/DaliCtl";
import DeviceMongoModal from "components/DeviceMongoModal";

export default function LightStatisticInfo() {
  const [state] = useContext(SMContext);
  const {
    activeMapID,
    mapObjAll,
    lightObjAll,
    sensorObjAll,
  } = state;
  const lightArray = Object.keys(mapObjAll?.[activeMapID]?.lightObj || {});

  const qtyBatVI = lightArray.filter(
    (s) => lightObjAll[s]?.type?.batVI
  )?.length;
  const qtyPassTest = lightArray.filter(
    (s) => lightObjAll[s]?.reportObj?.result === "OK"
  )?.length;
  const passRate = ((qtyPassTest / qtyBatVI) * 100).toFixed(0);

  const gwArray = Object.keys(mapObjAll?.[activeMapID]?.gatewayObj || {});
  const sensorArray = Object.keys(mapObjAll?.[activeMapID]?.sensorObj || {});
  const daliCtlArray = Object.keys(mapObjAll?.[activeMapID]?.daliCtlObj || {});
  function sensorQtyOf(type) {
    const qty = sensorArray.filter(
      (id) => sensorObjAll?.[id]?.type === type
    )?.length;
    return qty;
  }
  function lightQtyOf(style) {
    const qty = lightArray.filter(
      (s) => lightObjAll?.[s]?.style === style
    )?.length;
    return qty;
  }
  const LightRow = ({ lightStyle }) => {
    return (
      <DivExist show={lightQtyOf(lightStyle) > 0}>
        <DivSpaceBetween
          style={{
            margin: 3,
            width: "90%",
          }}
        >
          <LightIcon
            width={lightStyle === "tubeHalf" ? 2 : 2.3}
            sizeUnit="vw"
            lightStyle={lightStyle}
            tooltip={lightStyleMenu[lightStyle]}
            disabledConnectStatus
            disabledStatus
          />
          <Typography style={{ color: dataText }}>
            {lightQtyOf(lightStyle)}
          </Typography>
        </DivSpaceBetween>
      </DivExist>
    );
  };
  const SensorRow = ({ type }) => {
    return (
      <DivExist show={sensorQtyOf(type) > 0}>
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <SensorIcon
            width={2.5}
            sizeUnit="vw"
            type={type}
            tooltip={sensorStyleMenu[type]}
            disabledConnectStatus
            disabledStatus
            gang={2}
          />
          <Typography style={{ color: dataText }}>
            {sensorQtyOf(type)}
          </Typography>
        </DivSpaceBetween>
      </DivExist>
    );
  };
  return (
    <>
      <Map sx={{ color: titleText }} />
      <Typography
        style={{ color: titleText }}
        display="block"
        align="center"
        variant="caption"
      >
        Map ID
      </Typography>
      <DeviceMongoModal deviceObj={mapObjAll?.[activeMapID]}>
        <Typography
          display="block"
          align="center"
          variant="caption"
          style={{ color: dataText }}
        >
          {activeMapID}
        </Typography>
      </DeviceMongoModal>
      <hr color="lightgrey" size={1} width="90%" />
      <Typography style={{ color: titleText }} variant="caption">
        Light:
      </Typography>
      <LightRow lightStyle="tube" />
      <LightRow lightStyle="tubeHalf" />
      <LightRow lightStyle="owl" />
      <LightRow lightStyle="exit" />
      <LightRow lightStyle="downlight" />
      <LightRow lightStyle="panellight" />

      <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
        <Typography style={{ color: titleText }} variant="caption">
          Total
        </Typography>
        <Typography style={{ color: dataText }}>{lightArray.length}</Typography>
      </DivSpaceBetween>

      <hr color="lightgrey" size={1} width="90%" />
      <DivExist show={qtyBatVI > 0}>
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <Typography style={{ color: titleText }} variant="caption">
            Battery light
          </Typography>
          <Typography style={{ color: dataText }}>{qtyBatVI}</Typography>
        </DivSpaceBetween>
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <Typography style={{ color: titleText }} variant="caption">
            Passed latest test
          </Typography>
          <Typography style={{ color: dataText }}>{qtyPassTest}</Typography>
        </DivSpaceBetween>
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <Typography style={{ color: titleText }} variant="caption">
            Pass rate
          </Typography>
          <Typography style={{ color: dataText }}>{passRate}%</Typography>
        </DivSpaceBetween>
      </DivExist>
      <hr color="lightgrey" size={1} width="90%" />
      <Typography style={{ color: titleText }} variant="caption">
        Gateway:
      </Typography>
      <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
        <GatewayIcon width={2.5} sizeUnit="vw" disabledConnectStatus />
        <Typography style={{ color: dataText }}>{gwArray.length}</Typography>
      </DivSpaceBetween>

      <hr color="lightgrey" size={1} width="90%" />
      <DivExist show={sensorArray.length > 0}>
        <Typography style={{ color: titleText }} variant="caption">
          Sensor:
        </Typography>
        {Object.keys(sensorStyleMenu).map((s) => (
          <SensorRow type={s} key={s} />
        ))}

        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <Typography style={{ color: titleText }} variant="caption">
            Total
          </Typography>
          <Typography style={{ color: dataText }}>
            {sensorArray.length}
          </Typography>
        </DivSpaceBetween>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist show={daliCtlArray.length > 0}>
        <Typography style={{ color: titleText }} variant="caption">
          Dali controller:
        </Typography>
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <DaliCtl width={2.5} sizeUnit="vw" disabledConnectStatus />
          <Typography style={{ color: dataText }}>
            {daliCtlArray.length}
          </Typography>
        </DivSpaceBetween>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
    </>
  );
}
