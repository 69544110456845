import { mgFindOne, mgFindAll, mgFindByArray } from "./mongoApiActions";
import General from "@ecoenghk/general";
const gs = new General();

export const fetchOneMap = async (dispatch, mapID) => {
  const obj = await mgFindOne("map", "mapID", mapID);
  dispatch({
    type: "UPDATED_MAP_OBJ",
    payload: obj,
  });
};
export const fetchAllMap = async () => {
  const data = await mgFindAll("map");
  console.log("fetchAllMap", data);
  let objAll = {};
  data.forEach((obj) => {
    const mapID = obj.mapID;
    objAll[mapID] = obj;
  });
  return objAll;
};
export const createMap = async (userObj, sizeX, sizeY, mapName) => {
  const mapID = "map_" + gs.getRandomHexID(4);
  const jsonData = {
    type: "createMap",
    userObj,
    sizeX,
    sizeY,
    mapID,
    mapName,
  };
  console.log(
    `[SOCKET OUT] createMap ${mapID} ${mapName} at user uid ${userObj.uid}`
  );
  global.socket.emit("fromWeb", jsonData);
  return mapID;
};
export const importMap = async (userObj, mapID, mapObj) => {
  const jsonData = {
    type: "importMap",
    userObj,
    mapID,
    mapObj,
  };
  console.log(`[SOCKET OUT] import map ${mapID} to user uid ${userObj.uid}`);
  global.socket.emit("fromWeb", jsonData);
};
export const removeMap = async (userObj, mapObj) => {
  const jsonData = {
    type: "removeMap",
    userObj,
    mapID: mapObj.mapID,
    mapObj,
  };
  console.log(`[SOCKET OUT] removeMap ${mapObj.mapID} from user uid ${userObj.uid}`);
  global.socket.emit("fromWeb", jsonData);
};
export const updateMapProperty = async (mapID, updateObj) => {
  const jsonData = {
    type: "mapUpdateProperty",
    mapID,
    updateObj,
  };
  console.log(`[SOCKET OUT] mapUpdateProperty ${mapID}`);
  global.socket.emit("fromWeb", jsonData);
};
export const updateDevicePos = async (
  socket,
  mapID,
  deviceType,
  deviceID,
  x,
  y
) => {
  const jsonData = {
    type: "mapUpdateDevicePos",
    mapID,
    deviceType,
    deviceID,
    x,
    y,
  };
  console.log(
    `[SOCKET OUT] ${mapID} ${deviceType} ${deviceID} move to [${x},${y}]`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const mapRemoveLight = async (mapID, serial) => {
  const type = "mapRemoveLight";
  const jsonData = {
    serial,
    mapID,
    type,
  };
  console.log(`[SOCKET OUT] ${type}`, jsonData);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapRemoveManyLights = async (mapID, serialArray) => {
//   if (serialArray.length === 0 || !mapID || !serialArray) return;
//   const type = "mapRemoveManyLights";
//   const jsonData = {
//     serialArray,
//     mapID,
//     type,
//   };
//   console.log(`[SOCKET OUT] ${type}`, jsonData);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapRemoveSensor = async (mapID, sensorID) => {
  const jsonData = {
    type: "mapRemoveSensor",
    mapID,
    sensorID,
  };
  console.log("[SOCKET OUT] mapRemoveSensor", jsonData);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapRemoveManySensors = async (mapID, sensorIDArray) => {
//   if (sensorIDArray.length === 0 || !mapID || !sensorIDArray) return;
//   const jsonData = {
//     type: "mapRemoveManySensors",
//     mapID,
//     sensorIDArray,
//   };
//   console.log("[SOCKET OUT] mapRemoveManySensors", jsonData);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapRemoveGateway = async (mapID, gatewayID, gatewayObj) => {
  const type = "mapRemoveGateway";
  const jsonData = {
    gatewayID,
    gatewayObj,
    mapID,
    type,
  };
  console.log(`[COMMAND OUT] map ${mapID} remove gateway ${gatewayID}`);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapRemoveManyGateways = async (mapID, gatewayIDArray) => {
//   if (gatewayIDArray.length === 0 || !mapID || !gatewayIDArray) return;
//   const jsonData = {
//     gatewayIDArray,
//     mapID,
//     type: "mapRemoveManyGateways",
//   };
//   console.log(`[COMMAND OUT] map ${mapID} remove many gateways`);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapRemoveDaliCtl = async (mapID, daliCtlID) => {
  const type = "mapRemoveDaliCtl";
  const jsonData = {
    type,
    mapID,
    daliCtlID,
  };
  console.log(`[COMMAND OUT] map ${mapID} remove daliCtl ${daliCtlID}`);
  global.socket.emit("fromWeb", jsonData);
};
// export const mapRemoveManyDaliCtls = async (mapID, daliCtlIDArray) => {
//   if (daliCtlIDArray.length === 0 || !mapID || !daliCtlIDArray) return;
//   const type = "mapRemoveManyDaliCtls";
//   const jsonData = {
//     type,
//     mapID,
//     daliCtlIDArray,
//   };
//   console.log(`[COMMAND OUT] map ${mapID} remove many daliCtls`);
//   global.socket.emit("fromWeb", jsonData);
// };
export const mapAddManyDevices = async (mapID, deviceArrayObj, currentMapObj) => {
  if (!mapID || !deviceArrayObj) return;
  const jsonData = {
    mapID,
    deviceArrayObj, //{light:[xxx,xxx], sensor:[xxx,xxx], gateway:[], daliCtl:[]}
    type: "mapAddManyDevices",
    currentMapObj,
  };
  console.log(`[COMMAND OUT] map ${mapID} AddManyDevices ~ `, jsonData);
  global.socket.emit("fromWeb", jsonData);
}
export const mapRemoveManyDevices = async (mapObj, deviceArrayObj) => {
  if (!mapObj || !deviceArrayObj) return;
  const jsonData = {
    mapObj,
    deviceArrayObj, //{light:[xxx,xxx], sensor:[xxx,xxx], gateway:[], daliCtl:[]}
    type: "mapRemoveManyDevices",
  };
  console.log(`[COMMAND OUT] map ${mapObj.mapID} RemoveManyDevices ~ `, jsonData);
  global.socket.emit("fromWeb", jsonData);

}
export const mapAddLight = async (mapID, serial, lightObj, x, y) => {
  const jsonData = {
    type: "mapAddLight",
    serial,
    lightObj,
    mapID,
    x: x ?? gs.randomInteger(140, 180),
    y: y ?? gs.randomInteger(140, 180),
  };
  console.log(`[COMMAND OUT] map ${mapID} add light ${serial}`, jsonData);
  global.socket.emit("fromWeb", jsonData);
};
export const mapAddSensor = async (mapID, sensorID, sensorObj, x, y) => {
  const jsonData = {
    type: "mapAddSensor",
    gatewayID: sensorObj.gatewayID || "",
    mapID,
    sensorID,
    sensorObj,
    x: x ?? gs.randomInteger(190, 230),
    y: y ?? gs.randomInteger(190, 230),
  };
  console.log("[SOCKET OUT] mapAddSensor", jsonData);
  global.socket.emit("fromWeb", jsonData);
};
export const mapAddGateway = async (mapID, gatewayID, gatewayObj, x, y) => {
  const type = "mapAddGateway";
  const jsonData = {
    gatewayID,
    gatewayObj,
    mapID,
    type,
    x: x ?? gs.randomInteger(180, 220),
    y: y ?? gs.randomInteger(180, 220),
  };
  console.log(`[COMMAND OUT] map ${mapID} add gateway ${gatewayID}`);
  global.socket.emit("fromWeb", jsonData);
};
export const mapAddDaliCtl = async (mapID, daliCtlID, daliCtlObj, x, y) => {
  const type = "mapAddDaliCtl";
  const jsonData = {
    daliCtlID,
    daliCtlObj,
    mapID,
    type,
    x: x ?? gs.randomInteger(100, 140),
    y: y ?? gs.randomInteger(100, 140),
  };
  console.log(`[COMMAND OUT] map ${mapID} add daliCtl ${daliCtlID}`);
  global.socket.emit("fromWeb", jsonData);
};
export const updateMapsFsToLs = async (socket) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "map",
  };
  await socket.emit("fromWeb", payload);
};
export const updateSingleMapFsToLs = async (mapID) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "singleMap",
    mapID: mapID,
  };
  await global.socket.emit("fromWeb", payload);
};
export const deviceToolbarPos = (deviceX, deviceY, btnQty, btnSpace, layerProps) => {
  const { iconSize, mapScale } = layerProps;
  const x =
    deviceX < 500
      ? deviceX * mapScale.x
      : deviceX * mapScale.x - (btnQty - 1) * btnSpace * 1.2;
  const y =
    deviceY < 300 ?
      deviceY * mapScale.y + iconSize * 1.1
      : deviceY * mapScale.y - btnSpace * 1.5;
  return { x, y, btnQty, }
}
export const deviceModalPos = (deviceX, deviceY, modalW, modalH, layerProps = {}) => {
  const { iconSize, mapScale, layerScale } = layerProps;
  const x = deviceX < 500 ? deviceX * mapScale.x : deviceX * mapScale.x - modalW / layerScale;
  const y = deviceY < 300 ? deviceY * mapScale.y + iconSize : deviceY * mapScale.y - modalH / layerScale - iconSize;
  return { x, y }
}