import { useState, useEffect } from "react";
import KonvaFaButton from "containers/map/device/KonvaFaButton";
import { Group } from "react-konva";
import QuestionMark from "asset/mapComp/QuestionMark";
import { daliCtlColorDark, gatewayColorDark } from "actions/generalActions";
import RubbishBin from "asset/mapComp/RubbishBin";
import Document from "asset/mapComp/Document";
import Gateway from "asset/mapComp/Gateway";
import { confirmWindow } from "actions/screenActions";
import { mapRemoveDaliCtl, deviceToolbarPos } from "actions/mapActions";
import { daliCtlCheckConnection } from "actions/daliCtlActions";

export default function ToolbarDaliCtl({ layerProps }) {
  const {
    layerRef,
    mapObjAll,
    userObj,
    activeMapID,
    mapScale,
    iconSize,
    selectedID,
    selectedDeviceType,
    selectedDeviceObj,
    dispatch,
    enablePan,
    mapLayer,
  } = layerProps;
  const [scale, setScale] = useState(1);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);
  const { daliType } = selectedDeviceObj || {};
  const activeMapObj = mapObjAll[activeMapID];
  const { x, y } = activeMapObj?.daliCtlObj[selectedID] || {};
  const mapID = activeMapObj.mapID;
  const btnSize = activeMapObj.buttonSize / scale;
  const btnSpace = btnSize * 1.2;
  const handleOpenDetail = () => {
    dispatch({
      type: "OPENED_DALICTL_DETAIL_MODAL",
      payload: selectedID,
    });
  };
  const handleChangeGateway = () => {
    const layerObj = {
      normal: false,
      daliCtl: true,
      gatewaySelect: true,
    };
    dispatch({
      type: "SET_MAP_LAYER",
      payload: layerObj,
    });
  };
  const handleRemove = async () => {
    confirmWindow(
      dispatch,
      `Confirm removing dali controller ${selectedID} from map ${mapID}?`,
      async () => {
        await mapRemoveDaliCtl(mapID, selectedID);
        dispatch({
          type: "ALL_UNSELECTED",
        });
      }
    );
  };
  const handleCheckConnection = async () => {
    await daliCtlCheckConnection(selectedDeviceObj);
  };
  let btnContent = [
    {
      onClick: handleOpenDetail,
      icon: <Document x={0} y={0} size={btnSize} />,
      tooltip: "Dali controller detail",
    },
    {
      onClick: handleChangeGateway,
      icon: <Gateway x={0} y={0} size={btnSize} disabledConnectStatus />,
      tooltip: "Change gateway",
    },
  ];
  if (daliType === "dalidtk") {
    btnContent = [
      ...btnContent,
      {
        onClick: handleCheckConnection,
        icon: <QuestionMark x={0} y={0} size={btnSize} />,
        tooltip: "Check connection",
      },
    ];
  }
  btnContent = [
    ...btnContent,
    {
      onClick: handleRemove,
      icon: <RubbishBin x={0} y={0} size={btnSize} />,
      tooltip: "Remove from map",
    },
  ];
  const pos = deviceToolbarPos(x, y, btnContent.length, btnSpace, layerProps);
  return (
    <>
      <Group x={pos.x} y={pos.y}>
        {btnContent.map((obj, key) => (
          <KonvaFaButton
            key={key}
            x={btnSpace * key}
            y={0}
            mapScale={mapScale}
            enablePan={enablePan}
            stageScale={scale}
            btnSize={btnSize}
            onBtnClick={obj.onClick}
            iconSvg={obj.icon}
            tooltip={obj.tooltip}
            stroke={obj.stroke}
            tagColor={gatewayColorDark}
          />
        ))}
      </Group>
    </>
  );
}
