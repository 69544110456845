import { alertWindow, openSnackbar } from "actions/screenActions";
import { useContext, useEffect } from "react";
import { SMContext } from "../context/smContext";
// import { handleReceiveGeneralDeviceReply } from "actions/generalDeviceActions";
import { mgFindOne } from "actions/mongoApiActions";
import moment from "moment";


export const useSocketListenToWebAtHome = (navigate) => {
  const [state, dispatch] = useContext(SMContext);
  const { socket, userObj } = state;
  useEffect(() => {
    if (socket) {
      socket.on("onUserChange", async (socketInData) => {
        const { uid, email, operationType } = socketInData;
        console.log(
          `[SOCKET IN ON USER CHANGE AT HOME - email ${email} uid: ${uid} ${operationType}`
        );
        let userObj = { ...socketInData };
        if (state.userObj?.uid === uid) {
          dispatch({
            type: "UPDATED_USER_OBJ",
            payload: userObj,
          });
        }
      });
      socket.on("onServerChange", async (socketInData) => {
        console.log("[SOCKET IN -onServerChange]", socketInData);
        dispatch({
          type: "FETCHED_SERVER_OBJ",
          payload: socketInData,
        });
      });
      socket.on(userObj.uid, async (socketInData) => {
        console.log("[SOCKET IN -toUID]", socketInData);
        await handleToWebSocketIn(state, dispatch, socketInData, navigate);
      })
      return () => {
        socket.off("onUserChange");
        socket.off("onServerChange");
        socket.off(userObj.uid);
      }
    }
  }, [socket, userObj]);
}
export const useSocketListenToWeb = (navigate) => {
  const [state, dispatch] = useContext(SMContext);
  const { socket, mapObjAll, activeMapID, userObj } = state;
  const mapObj = mapObjAll[activeMapID] || {};
  const deviceInMapAll = [...Object.keys(mapObj.lightObj || {}), ...Object.keys(mapObj.sensorObj || {}), ...Object.keys(mapObj.gatewayObj || {}), ...Object.keys(mapObj.daliCtlObj || {}), ...Object.keys(mapObj.zoneControlObj || {})];
  useEffect(() => {
    if (socket) {
      console.log("socket listen TO_UI:");
      socket.on("toWeb", async (socketInData) => {
        // console.log(`[SOCKET IN -toWeb]`, socketInData);
        await handleToWebSocketIn(state, dispatch, socketInData, navigate);
      });
      socket.on(userObj.uid, async (socketInData) => {
        console.log("[SOCKET IN -toUID]", socketInData);
        await handleToWebSocketIn(state, dispatch, socketInData, navigate);
      })
      socket.on("deviceStatus", (socketInData) => {
        const { deviceType, serial, sensorID, gatewayID, daliCtlID } = socketInData;
        const deviceID = serial || sensorID || gatewayID || daliCtlID;
        if (!deviceInMapAll.includes(deviceID)) return;
        let msg = `[SOCKET IN -deviceStatus] ${deviceType} ${deviceID}`;
        if (deviceType === "serial") msg += ` lightLv:${socketInData.lightLv} lockLv:${socketInData.lockLv}`;
        if (deviceType === "sensor") {
          if ("currentStatus" in socketInData) msg += ` status:${socketInData.currentStatus}/`;
          if ("currentStatus2" in socketInData) msg += `${socketInData.currentStatus2}/`;
          if ("currentStatus3" in socketInData) msg += `${socketInData.currentStatus3}/`;
          if ("currentStatus4" in socketInData) msg += `${socketInData.currentStatus4}/`;
          if ("lux" in socketInData) msg += ` lux:${socketInData.lux}/ luxLv:${socketInData.luxLv}`;
        }
        // console.log(msg);
        handleUpdateDeviceStatus(state, dispatch, socketInData);
      });

      socket.on("onServerChange", async (socketInData) => {
        console.log("[SOCKET IN -onServerChange]", socketInData);
        dispatch({
          type: "FETCHED_SERVER_OBJ",
          payload: socketInData,
        });
      });
      socket.on("onMapChange", async (socketInData) => {
        const { mapID, operationType } = socketInData;
        console.log(
          `[SOCKET IN ON MAP CHANGE - mapID: ${mapID} ${operationType}`
        );
        const mapArr = Object.keys(state.mapObjAll || {});
        let mapObj = { ...socketInData };
        delete mapObj.operationType;
        delete mapObj.updateDescription;
        if (mapArr.includes(socketInData.mapID)) {
          dispatch({
            type: "UPDATED_MAP_OBJ",
            payload: mapObj,
          });
        }
      });
      socket.on("onUserChange", async (socketInData) => {
        const { uid, email, operationType } = socketInData;
        console.log(
          `[SOCKET IN ON USER CHANGE - email ${email} uid: ${uid} ${operationType}`
        );
        let userObj = { ...socketInData };
        if (state.userObj?.uid === uid) {
          dispatch({
            type: "UPDATED_USER_OBJ",
            payload: userObj,
          });
        }
      });
      socket.on("onZoneControlChange", async (socketInData) => {
        const { zoneControlID } = socketInData;
        const zcArrInMap = Object.keys(
          mapObjAll[activeMapID]?.zoneControlObj || {}
        );
        if (zcArrInMap.includes(zoneControlID)) {
          let zoneControlObj = { ...socketInData };
          console.log(
            `[SOCKET IN ON ZONE CONTROL CHANGE - zoneControlID: ${zoneControlID}`, zoneControlObj
          );
          dispatch({
            type: "UPDATED_ZONE_CONTROL_OBJ",
            payload: zoneControlObj,
          });
        }
      });
      socket.on("onTimeTableChange", async (socketInData) => {
        const { timeTableID } = socketInData;
        console.log(
          `[SOCKET IN ON TIMETABLE CHANGE - timeTableID: ${timeTableID}`
        );
        dispatch({
          type: "UPDATED_TIMETABLE_OBJ",
          payload: socketInData,
        });
      });
      socket.on("onRssiChange", async (socketInData) => {
        let rssiObj = { ...socketInData };
        dispatch({
          type: "UPDATE_RSSI_OBJ",
          payload: rssiObj,
        });
      });
      socket.on("mapDeviceMessage", async (socketInData) => {
        const { deviceIDArr, messageArr } = socketInData;
        // const mapObj = mapObjAll[activeMapID] || {};
        // const deviceInMapAll = [...Object.keys(mapObj.lightObj || {}), ...Object.keys(mapObj.sensorObj || {}), ...Object.keys(mapObj.gatewayObj || {}), ...Object.keys(mapObj.daliCtlObj || {}), ...Object.keys(mapObj.zoneControlObj || {})];
        let deviceIDArrInMap = [];
        let messageArrInMap = [];
        deviceIDArr.forEach((deviceID, key) => {
          if (deviceInMapAll.includes(deviceID)) {
            deviceIDArrInMap.push(deviceID);
            messageArrInMap.push(messageArr[key]);

          }
        });
        dispatch({
          type: "ADD_MAP_DEVICE_MESSAGE",
          payload: {
            deviceIDArr: deviceIDArrInMap,
            messageArr: messageArrInMap,
          }
        });
      });
      return () => {
        socket.off("toWeb");
        socket.off("deviceStatus");
        socket.off("onMapChange");
        socket.off("onUserChange");
        socket.off("onServerChange");
        socket.off("onZoneControlChange");
        socket.off("onTimeTableChange");
        socket.off("onRssiChange");
        socket.off("mapDeviceMessage");
        console.log("socket listening toWeb, deviceStatus, onMapChange off");
      };
    }
    // }, [state.lightObjAll, state.sensorObjAll, state.gatewayObjAll, socket]);
  }, [activeMapID, socket, state.sensorObjAll]);
};
const handleToWebSocketIn = async (state, dispatch, data, navigate) => {
  switch (data.type) {
    case "serverReportOperationInfo": {
      dispatch({
        type: "FETCHED_SERVER_OPERATION_INFO",
        payload: data,
      });
      break;
    }
    case "reportEspNowRoute": {
      break;
    }
    case "mapAddLight": {
      const { serial, lightObj, mapID, x, y } = data;
      dispatch({
        type: "MAP_ADD_LIGHT",
        payload: { serial, lightObj, mapID, x, y },
      });
      break;
    }
    case "mapRemoveLight": {
      const { serial, mapID } = data;
      dispatch({
        type: "MAP_REMOVE_LIGHT",
        payload: { serial, mapID },
      });
      break;
    }
    case "mapAddSensor": {
      const { sensorID, sensorObj, mapID, x, y } = data;
      dispatch({
        type: "MAP_ADD_SENSOR",
        payload: { sensorID, sensorObj, mapID, x, y },
      });
      break;
    }
    case "mapRemoveSensor": {
      const { sensorID, mapID } = data;
      dispatch({
        type: "MAP_REMOVE_SENSOR",
        payload: { sensorID, mapID },
      });
      break;
    }
    case "mapAddGateway": {
      const { gatewayID, gatewayObj, mapID, x, y } = data;
      dispatch({
        type: "MAP_ADD_GATEWAY",
        payload: { gatewayID, gatewayObj, mapID, x, y },
      });
      break;
    }
    case "mapRemoveGateway": {
      const { gatewayID, mapID } = data;
      console.log("mapRemoveGateway", mapID, gatewayID);
      dispatch({
        type: "MAP_REMOVE_GATEWAY",
        payload: { gatewayID, mapID },
      });
      break;
    }
    case "mapAddDaliCtl": {
      const { daliCtlID, daliCtlObj, mapID, x, y } = data;
      dispatch({
        type: "MAP_ADD_DALICTL",
        payload: { daliCtlID, daliCtlObj, mapID, x, y },
      });
      break;
    }
    case "mapRemoveDaliCtl": {
      const { daliCtlID, mapID } = data;
      dispatch({
        type: "MAP_REMOVED_DALICTL",
        payload: { daliCtlID, mapID },
      });
      break;
    }
    case "lightConvertToDaliCtl": {
      const { serial, daliCtlObj } = data;
      // const daliCtlObj = await mgFindOne("daliCtl", "daliCtlID", serial);
      // const lightObj = await mgFindOne("serial", "serial", serial);
      dispatch({
        type: "LIGHT_CONVERT_TO_DALICTL",
        payload: { serial, daliCtlObj },
      });
      break;
    }
    case "createZoneControl": {
      const { zoneControlID, zoneControlObj, mapID, x, y } = data;
      dispatch({
        type: "MAP_ADDED_ZONE_CONTROL",
        payload: { zoneControlID, zoneControlObj, mapID, x, y },
      });
      break;
    }
    case "deleteZoneControl": {
      const { zoneControlID, mapID } = data;
      dispatch({
        type: "MAP_DELETED_ZONE_CONTROL",
        payload: { zoneControlID, mapID },
      });
      break;
    }
    case "sensorConnectLight": {
      const { sensorID, serial, newSensorObj } = data;
      let lightObj = state.lightObjAll[serial];
      let sensorArray = lightObj?.sensorArray || [];
      if (!sensorArray.includes(sensorID)) sensorArray.push(sensorID);
      lightObj = { ...lightObj, sensorArray };
      dispatch({
        type: "UPDATE_LIGHT_OBJ",
        payload: lightObj,
      });
      dispatch({
        type: "UPDATE_SENSOR_OBJ",
        payload: newSensorObj,
      });
      break;
    }
    case "sensorConnectSensor": {
      const { mainSensorID, connectSensorID, newSensorObj } = data;
      let connectSensorObj = state.sensorObjAll[connectSensorID];
      let connectSensorArray = connectSensorObj?.sensorArray || [];
      if (!connectSensorArray.includes(mainSensorID))
        connectSensorArray.push(mainSensorID);
      connectSensorObj = {
        ...connectSensorObj,
        sensorArray: connectSensorArray,
      };
      dispatch({
        type: "UPDATE_SENSOR_OBJ",
        payload: connectSensorObj,
      });
      dispatch({
        type: "UPDATE_SENSOR_OBJ",
        payload: newSensorObj,
      });
      break;
    }
    case "sensorDisconnectLight": {
      const { sensorID, serial, newSensorObj } = data;
      dispatch({
        type: "SENSOR_DISCONNECT_LIGHT",
        payload: { sensorID, serial, newSensorObj },
      });
      break;
    }
    case "sensorDisconnectSensor": {
      const { mainSensorID, connectSensorID, newSensorObj } = data;
      dispatch({
        type: "SENSOR_DISCONNECT_SENSOR",
        payload: { mainSensorID, connectSensorID, newSensorObj },
      });
      break;
    }
    case "sensorDisconnectAllLights": {
      const { sensorID, newSensorObj, serialArray } = data;
      dispatch({
        type: "SENSOR_DISCONNECT_ALL_LIGHTS",
        payload: { sensorID, newSensorObj, serialArray },
      });
      break;
    }
    case "updateSerialProperty": {
      const { serial, updateObj } = data;
      if (!Object.keys(state.lightObjAll || {}).includes(serial)) break;
      dispatch({
        type: "UPDATED_LIGHT_PROPERTY",
        payload: { serial, updateObj },
      });
      break;
    }
    case "updateSerialType": {
      const { serial, typeField, value } = data;
      if (!Object.keys(state.lightObjAll || {}).includes(serial)) break;
      dispatch({
        type: "UPDATED_LIGHT_TYPE",
        payload: { serial, typeField, value }
      })
    }
    case "updateMultiSerialProperty": {
      const { updateObjBySerial } = data;
      dispatch({
        type: "UPDATE_MULTI_SERIAL_PROPERTY",
        payload: updateObjBySerial,
      });
      break;
    }
    case "lightDeletePermanently": {
      const { serial } = data;
      if (!Object.keys(state.lightObjAll || {}).includes(serial)) break;
      dispatch({
        type: "LIGHT_DELETE_PERMANENTLY",
        payload: serial,
      });
      break;
    }
    case "createSlaveLight": {
      const { slaveLightObj } = data;
      dispatch({
        type: "CREATE_SLAVE_LIGHT",
        payload: slaveLightObj
      });
      break;
    }
    case "deleteSlaveLight": {
      const { slaveLightObj } = data;
      dispatch({
        type: "DELETE_SLAVE_LIGHT",
        payload: slaveLightObj
      })
      break;
    }
    case "updateSensorProperty": {
      const { sensorID, updateObj } = data;
      console.log("UPDATE SENSOR PROPERTY", data)
      if (!Object.keys(state.sensorObjAll || {}).includes(sensorID)) break;
      dispatch({
        type: "UPDATED_SENSOR_PROPERTY",
        payload: { sensorID, updateObj },
      });
      break;
    }
    case "updateSensorOneSetting": {
      const { sensorID, updateObj } = data;
      let sensorObj = state.sensorObjAll[sensorID];
      if (!sensorObj) break;
      sensorObj = { ...sensorObj, ...updateObj };
      dispatch({
        type: "UPDATE_SENSOR_OBJ",
        payload: sensorObj,
      });
      break;
    }
    case "latestFirmwareVersion": {
      const { latestFirmwareVersion } = data;
      dispatch({
        type: "GOT_LATEST_FIRMWARE_VERSION",
        payload: latestFirmwareVersion,
      });
      break;
    }
    case "confirmUpdatedServerFirmware": {
      alertWindow(dispatch, "Server Firmware updated");
      break;
    }
    case "daliScanFinish": {
      const { daliCtlID } = data;
      if (!Object.keys(state.daliCtlObjAll || {}).includes(daliCtlID)) break;
      dispatch({
        type: "UPDATED_DALICTL_PROPERTY",
        payload: {
          daliCtlID,
          updateObj: {
            scanning: false,
          },
        },
      });
      break;
    }
    case "daliReplyGeneralQuery": {
      const { daliCtlID, daliCmd, shortAdd, reply } = data;
      if (!Object.keys(state.daliCtlObjAll || {}).includes(daliCtlID)) break;
      dispatch({
        type: "DALI_REPLIED_GENERAL_QUERY",
        payload: { daliCtlID, daliCmd, shortAdd, reply },
      });
      break;
    }
    case "replyLatestGwFirmwareVer": {
      const { gatewayID, latestVer, currentVer, toUpdate } = data;
      if (!Object.keys(state.gatewayObjAll || {}).includes(gatewayID)) break;
      dispatch({
        type: "GATEWAY_REPLIED_LATEST_FIRMWARE_VER",
        payload: {
          gatewayID,
          latestVer,
          currentVer,
          toUpdate,
        },
      });
      break;
    }
    case "replyLatestDeviceFirmwareVer": {
      const { latestVerObj } = data;
      console.log(data)
      dispatch({
        type: "REPLY_LATEST_DEVICE_FIRMWARE_VER",
        payload: latestVerObj
      });
      break;
    }
    case "acknowledgeGatewayFirmwareUpdate": {
      const { gatewayID } = data;
      if (!Object.keys(state.gatewayObjAll || {}).includes(gatewayID)) break;
      openSnackbar(
        dispatch,
        `Gateway ${gatewayID} will update firmware in a minute`
      );
      break;
    }
    case "serverMessageToWeb": {
      const { title, message, severity, timeMs } = data;
      openSnackbar(
        dispatch,
        `Server message ${title} - ${message}`,
        severity,
        timeMs
      );
      break;
    }
    case "singleSensorUpdateSetting": {
      const { newSensorObj } = data;
      if (!Object.keys(state.sensorObjAll || {}).includes(newSensorObj.sensorID)) break;
      dispatch({
        type: "UPDATE_SENSOR_OBJ",
        payload: newSensorObj,
      });
      break;
    }
    case "updateGatewayProperty": {
      const { gatewayID, updateObj } = data;
      if (!Object.keys(state.gatewayObjAll || {}).includes(gatewayID)) break;
      dispatch({
        type: "UPDATED_GATEWAY_PROPERTY",
        payload: { gatewayID, updateObj },
      });
      break;
    }
    case "gatewayConnectLight": {
      const { newLightObj, newGatewayObj, oldGatewayObj, sensorUpdateObjArr } =
        data;
      dispatch({
        type: "GATEWAY_CONNECT_LIGHT",
        payload: {
          newLightObj,
          newGatewayObj,
          oldGatewayObj,
          sensorUpdateObjArr,
        },
      });
      break;
    }
    case "gatewayDisconnectLight": {
      const { gatewayObj, newLightObj, sensorUpdateObjArr } = data;
      dispatch({
        type: "GATEWAY_DISCONNECT_LIGHT",
        payload: { gatewayObj, newLightObj, sensorUpdateObjArr },
      });
      break;
    }
    case "gatewayConnectSensor": {
      const { newSensorObj, newGatewayObj, oldGatewayObj, sensorUpdateObjArr } =
        data;
      dispatch({
        type: "GATEWAY_CONNECT_SENSOR",
        payload: {
          newSensorObj,
          newGatewayObj,
          oldGatewayObj,
          sensorUpdateObjArr,
        },
      });
      break;
    }
    case "gatewayDisconnectSensor": {
      const { gatewayObj, newSensorObj, sensorUpdateObjArr } = data;
      dispatch({
        type: "GATEWAY_DISCONNECT_SENSOR",
        payload: {
          gatewayObj,
          newSensorObj,
          sensorUpdateObjArr,
        },
      });
      break;
    }
    case "gatewayConnectDaliCtl": {
      const {
        newGwUpdateObj,
        oldGwUpdateObj,
        daliCtlUpdateObj,
        sensorUpdateObjArr,
      } = data;
      dispatch({
        type: "GATEWAY_CONNECT_DALICTL",
        payload: {
          newGwUpdateObj,
          oldGwUpdateObj,
          daliCtlUpdateObj,
          sensorUpdateObjArr,
        },
      });
      break;
    }
    case "gatewayDisconnectDaliCtl": {
      const { oldGatewayUpdateObj, daliCtlUpdateObj, sensorUpdateObjArr } =
        data;
      dispatch({
        type: "GATEWAY_DISCONNECT_DALICTL",
        payload: { oldGatewayUpdateObj, daliCtlUpdateObj, sensorUpdateObjArr },
      });
      break;
    }
    case "gatewayReportSurvival": {
      const {
        gwUpdateObj,
        serialUpdateObjArr,
        sensorUpdateObjArr,
        daliCtlUpdateObjArr,
      } = data;
      dispatch({
        type: "GATEWAY_REPORT_SURVIVAL",
        payload: {
          gwUpdateObj,
          serialUpdateObjArr,
          sensorUpdateObjArr,
          daliCtlUpdateObjArr,
        },
      });
      break;
    }
    case "updateDaliCtlProperty": {
      const { daliCtlID, updateObj } = data;
      if (!Object.keys(state.daliCtlObjAll || {}).includes(daliCtlID)) break;
      dispatch({
        type: "UPDATED_DALICTL_PROPERTY",
        payload: { daliCtlID, updateObj },
      });
      break;
    }
    case "daliUpdateSensor": {
      const { daliCtlID, sensorID, shortAdd, instance, sensorType } = data;
      dispatch({
        type: "DALI_UPDATE_SENSOR",
        payload: { daliCtlID, sensorID, sensorType },
      });
      break;
    }
    case "daliAddLight": {
      const { daliCtlID, serial, shortAdd } = data;
      if (!Object.keys(state.daliCtlObjAll || {}).includes(daliCtlID)) break;
      dispatch({
        type: "DALI_ADD_LIGHT",
        payload: { daliCtlID, serial, shortAdd },
      });
      break;
    }
    case "daliDeleteLight": {
      const { daliCtlID, serial, shortAdd } = data;
      if (!Object.keys(state.daliCtlObjAll || {}).includes(daliCtlID)) break;
      dispatch({
        type: "DALI_DELETE_LIGHT",
        payload: { daliCtlID, serial, shortAdd },
      });
      break;
    }
    case "daliAddSensor": {
      const { daliCtlID, sensorID, shortAdd, instance, sensorType } = data;
      if (!Object.keys(state.daliCtlObjAll || {}).includes(daliCtlID)) break;
      dispatch({
        type: "DALI_ADD_SENSOR",
        payload: { daliCtlID, sensorID, shortAdd, instance, sensorType },
      });
      break;
    }
    case "daliDeleteSensor": {
      const { daliCtlID, sensorID, shortAdd, instance } = data;
      if (!Object.keys(state.daliCtlObjAll || {}).includes(daliCtlID)) break;
      dispatch({
        type: "DALI_DELETE_SENSOR",
        payload: { daliCtlID, sensorID, shortAdd, instance },
      });
      break;
    }
    case "daliReportShortAddList": {
      const { daliCtlID, daliLightObj } = data;
      if (!Object.keys(state.daliCtlObjAll || {}).includes(daliCtlID)) break;
      let addList = [];
      Object.values(daliLightObj || {}).forEach((obj) => {
        addList.push(obj.shortAdd);
      });
      addList = addList.sort((a, b) => a - b);
      dispatch({
        type: "UPDATED_DALICTL_PROPERTY",
        payload: {
          daliCtlID,
          updateObj: {
            scanning: false,
            lightObj: daliLightObj,
            addList,
          },
        },
      });
      break;
    }
    case "daliDeleteAllLights": {
      const { daliCtlID } = data;
      if (!Object.keys(state.daliCtlObjAll || {}).includes(daliCtlID)) break;
      dispatch({
        type: "DALI_DELETE_ALL_LIGHTS",
        payload: { daliCtlID },
      });
      break;
    }
    case "daliCtlConvertToLight": {
      const { daliCtlID, lightObj } = data;
      // const daliCtlObj = await mgFindOne("daliCtl", "daliCtlID", daliCtlID);
      // const lightObj = await mgFindOne("serial", "serial", daliCtlID);
      dispatch({
        type: "DALICTL_CONVERT_TO_LIGHT",
        payload: { lightObj, daliCtlID },
      });
      break;
    }
    case "addZcTimeSetting": {
      const { zoneControlID, timeTableObj1, timeTableObj2, timeTableObj3 } = data;
      dispatch({
        type: "ADD_ZC_TIME_SETTING",
        payload: { zoneControlID, timeTableObj1, timeTableObj2, timeTableObj3 },
      });
      break;
    }
    case "updateManyTimeTables": {
      const { updateObjArray } = data;
      dispatch({
        type: "UPDATE_MANY_TIMETABLES",
        payload: { updateObjArray },
      });
      break;
    }
    case "deleteZcTimeSetting": {
      const { zoneControlID, zcUpdateObj, timeTableID1, timeTableID2 } = data;
      dispatch({
        type: "DELETE_ZC_TIME_SETTING",
        payload: { zoneControlID, zcUpdateObj, timeTableID1, timeTableID2 },
      });
      break;
    }
    case "zoneControlConnectLight": {
      const { zoneControlID, newZoneControlObj, serial } = data;
      dispatch({
        type: "ZC_CONNECT_LIGHT",
        payload: { zoneControlID, newZoneControlObj, serial },
      });
      break;
    }
    case "zoneControlDisconnectLight": {
      const { zoneControlID, newZoneControlObj, serial } = data;
      dispatch({
        type: "ZC_DISCONNECT_LIGHT",
        payload: { zoneControlID, newZoneControlObj, serial },
      });
      break;
    }
    case "zoneControlDisconnectAllLights": {
      const { zoneControlID, newZoneControlObj, serialArray } = data;
      dispatch({
        type: "ZC_DISCONNECT_ALL_LIGHTS",
        payload: { zoneControlID, newZoneControlObj, serialArray },
      });
      break;
    }
    case "zoneControlConnectSensor": {
      const { zoneControlID, newZoneControlObj, sensorID } = data;
      dispatch({
        type: "ZC_CONNECT_SENSOR",
        payload: { zoneControlID, newZoneControlObj, sensorID },
      });
      break;
    }
    case "zoneControlDisconnectSensor": {
      const { zoneControlID, newZoneControlObj, sensorID } = data;
      dispatch({
        type: "ZC_DISCONNECT_SENSOR",
        payload: { zoneControlID, newZoneControlObj, sensorID },
      });
      break;
    }
    case "updateZoneControlProperty": {
      const { zoneControlID, updateObj } = data;
      dispatch({
        type: "UPDATED_ZC_PROPERTY",
        payload: { zoneControlID, updateObj }
      });
      break;
    }
    case "reportOneLightSetting": {
      const { serial, settingType, settingVal } = data;
      if (!Object.keys(state.lightObjAll || {}).includes(serial)) break;
      switch (settingType) {
        case "enableA": {
          let newType = state.lightObjAll[serial].type;
          newType = { ...newType, A: settingVal ? true : false };
          dispatch({
            type: "UPDATED_LIGHT_PROPERTY",
            payload: {
              serial,
              updateObj: { type: newType },
            },
          });
          break;
        }
        case "enableL": {
          let newType = state.lightObjAll[serial].type;
          newType = { ...newType, L: settingVal ? true : false };
          dispatch({
            type: "UPDATED_LIGHT_PROPERTY",
            payload: {
              serial,
              updateObj: { type: newType },
            },
          });
          break;
        }
        case "enableBat": {
          let newType = state.lightObjAll[serial].type;
          newType = { ...newType, batVI: settingVal ? true : false };
          dispatch({
            type: "UPDATED_LIGHT_PROPERTY",
            payload: {
              serial,
              updateObj: { type: newType },
            },
          });
          break;
        }
        case "enableDim": {
          let newType = state.lightObjAll[serial].type;
          newType = { ...newType, pwm: settingVal ? true : false };
          dispatch({
            type: "UPDATED_LIGHT_PROPERTY",
            payload: {
              serial,
              updateObj: { type: newType },
            },
          });
          break;
        }
        default: {
          dispatch({
            type: "UPDATED_LIGHT_PROPERTY",
            payload: {
              serial,
              updateObj: { [settingType]: settingVal },
            },
          });
          break;
        }
      }
      openSnackbar(
        dispatch,
        `Light ${serial} report ${settingType}: ${settingVal}`
      );
      break;
    }
    default:
      break;
  }
};
const handleUpdateDeviceStatus = (state, dispatch, socketInData) => {
  const { gatewayObjAll, lightObjAll, sensorObjAll, daliCtlObjAll } = state;
  switch (socketInData.deviceType) {
    case "serial": {
      if (Object.keys(lightObjAll || {}).includes(socketInData.serial)) {
        handleUpdateLightStatus(socketInData, dispatch);
      }
      break;
    }
    case "gateway": {
      if (Object.keys(gatewayObjAll || {}).includes(socketInData.gatewayID)) {
        handleUpdateGatewayStatus(socketInData, dispatch);
      }
      break;
    }
    case "sensor": {
      if (Object.keys(sensorObjAll || {}).includes(socketInData.sensorID)) {
        handleUpdateSensorStatus(socketInData, dispatch);
      }
      break;
    }
    case "daliCtl": {
      if (Object.keys(daliCtlObjAll || {}).includes(socketInData.daliCtlID)) {
        handleUpdateDaliCtlStatus(socketInData, dispatch);
      }
      break;
    }
  }
};
const handleUpdateGatewayStatus = (socketInData, dispatch) => {
  const updateObj = {
    gatewayConnected: socketInData.gatewayConnected,
    timeStamp: socketInData.timeStamp ?? moment().valueOf(),
    timeString: socketInData.timeString || moment().format("YYYY-MM-DD HH:mm:ss"),
  };
  dispatch({
    type: "UPDATED_GATEWAY_PROPERTY",
    payload: {
      gatewayID: socketInData.gatewayID,
      updateObj,
    },
  });
};
const handleUpdateLightStatus = (socketInData, dispatch) => {
  let newCurrentData = {};
  if ("current" in socketInData) newCurrentData = { ...newCurrentData, current: socketInData.current };
  if ("voltage" in socketInData) newCurrentData = { ...newCurrentData, voltage: socketInData.voltage };
  if ("onoffstatusA" in socketInData) newCurrentData = { ...newCurrentData, onoffstatusA: socketInData.onoffstatusA };
  if ("onoffstatusL" in socketInData) newCurrentData = { ...newCurrentData, onoffstatusL: socketInData.onoffstatusL };
  if ("lightLv" in socketInData) newCurrentData = { ...newCurrentData, lightLv: socketInData.lightLv };
  if ("bypass" in socketInData) newCurrentData = { ...newCurrentData, bypass: socketInData.bypass };
  // const newCurrentData = {
  //   current: socketInData.current || 0,
  //   voltage: socketInData.voltage || 0,
  //   onoffstatusA: socketInData.onoffstatusA || 0,
  //   onoffstatusL: socketInData.onoffstatusL || 0,
  //   lightLv: socketInData.lightLv || 0,
  //   bypass: socketInData.bypass || 0,
  // };
  let serialProps = { currentData: newCurrentData, timeStamp: socketInData.timeStamp ?? moment().valueOf(), timeString: socketInData.timeString || moment().format("YYYY-MM-DD HH:mm:ss") };
  if ("lockLevel" in socketInData) serialProps = { ...serialProps, lockLevel: socketInData.lockLevel };
  if ("result" in socketInData) serialProps = { ...serialProps, result: socketInData.result };
  if ("progress" in socketInData) serialProps = { ...serialProps, progress: socketInData.progress };
  if ("testTime" in socketInData) serialProps = { ...serialProps, testTime: socketInData.testTime };
  if ("zigbeeConnected" in socketInData) serialProps = { ...serialProps, zigbeeConnected: socketInData.zigbeeConnected };
  if ("totalTestTime" in socketInData) serialProps = { ...serialProps, totalTestTime: socketInData.totalTestTime };
  if ("reportID" in socketInData) serialProps = { ...serialProps, reportID: socketInData.reportID };

  // console.log("handleUpdateLightStatus", socketInData.serial, serialProps);
  dispatch({
    type: "UPDATED_LIGHT_PROPERTY",
    payload: {
      serial: socketInData.serial,
      updateObj: serialProps,
    },
  });
};
const handleUpdateSensorStatus = (socketInData, dispatch) => {
  let updateObj = {
    zigbeeConnected: socketInData.zigbeeConnected,
    timeStamp: socketInData.timeStamp ?? moment().valueOf(),
    timeString: socketInData.timeString || moment().format("YYYY-MM-DD HH:mm:ss"),
  };
  if ("currentStatus" in socketInData) updateObj = { ...updateObj, currentStatus: socketInData.currentStatus };
  if ("currentStatus2" in socketInData) updateObj = { ...updateObj, currentStatus2: socketInData.currentStatus2 };
  if ("currentStatus3" in socketInData) updateObj = { ...updateObj, currentStatus3: socketInData.currentStatus3 };
  if ("currentStatus4" in socketInData) updateObj = { ...updateObj, currentStatus4: socketInData.currentStatus4 };
  if ("lux" in socketInData) updateObj = { ...updateObj, lux: socketInData.lux };
  if ("luxLv" in socketInData) updateObj = { ...updateObj, luxLv: socketInData.luxLv };
  dispatch({
    type: "UPDATED_SENSOR_PROPERTY",
    payload: {
      sensorID: socketInData.sensorID,
      updateObj,
    },
  });
};
const handleUpdateDaliCtlStatus = (socketInData, dispatch) => {
  const updateObj = {
    zigbeeConnected: socketInData.zigbeeConnected,
    timeStamp: socketInData.timeStamp ?? moment().valueOf(),
    timeString: socketInData.timeString || moment().format("YYYY-MM-DD HH:mm:ss"),
  };
  dispatch({
    type: "UPDATED_DALICTL_PROPERTY",
    payload: {
      daliCtlID: socketInData.daliCtlID,
      updateObj,
    },
  });
};
