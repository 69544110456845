import { useState, useContext, useEffect, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Slider, Button } from "@mui/material";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import { confirmWindow, promptWindow } from "actions/screenActions";
import General from "@ecoenghk/general";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import {
  daliChangeShortAdd,
  daliDeleteLight,
  daliChangeFadeTime,
  daliQueryFadeTime,
  daliReset,
  daliQueryMinLv,
  daliQueryMaxLv,
  daliChangeMaxLv,
  daliChangeMinLv,
} from "actions/daliCtlActions";
import LightIcon from "components/LightIcon";
import ModalSM from "components/ModalSM";
import { switchLightDali } from "actions/lightActions";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import { mapAddLight } from "actions/mapActions";
import { ArrowForwardIos } from "@mui/icons-material";
import { daliCmdJsonArray, daliFadeTimeMenu } from "asset/string/string";
import { mgFindOne } from "actions/mongoApiActions";
import IconButtonRefresh from "components/IconButtonRefresh";
import GeneralDaliQueryModal from "./GeneralDaliQueryModal";
import DialogInput from "components/DialogInput";
const gs = new General();

export default function EditDaliLightModal({
  daliLightObj,
  onClose,
  daliCtlObj,
}) {
  const [state, dispatch] = useContext(SMContext);
  const [arcLv, setArcLv] = useState(0);
  const [fadeTime, setFadeTime] = useState(0);
  const { activeMapID, lightObjAll, mapObjAll } = state;
  const { serial, shortAdd } = daliLightObj;
  const mapObj = mapObjAll[activeMapID];
  const lightObj = lightObjAll[serial];
  const lightCurrentStatus = lightObj?.currentData;
  useEffect(() => {
    if (!serial) {
      setArcLv(0);
    }
  }, [serial]);
  useEffect(() => {
    setFadeTime(lightObj?.fadeTime);
  }, [lightObj?.fadeTime]);
  const handleChangeShortAdd = async () => {
    promptWindow(
      dispatch,
      `Change new dali short address [${shortAdd}] to ?`,
      async (val) =>
        await daliChangeShortAdd(daliCtlObj, Number(shortAdd), Number(val))
    );
  };
  const handleAddLightToMap = async () => {
    const lightObj = await mgFindOne("serial", "serial", serial);
    console.log(lightObj);
    await mapAddLight(activeMapID, serial, lightObj);
  };
  // const handleDeleteLight = async () => {
  //   confirmWindow(
  //     dispatch,
  //     `Confirm to delete this light [short address ${shortAdd}] from dali controller?`,
  //     async () => {
  //       await daliDeleteLight(daliCtlObj, serial, shortAdd);
  //       onClose();
  //     }
  //   );
  // };
  const handleDimDali = async (pwm) => {
    console.log("handle change light lv", pwm);
    const onoffcontrolA = pwm > 0 ? 0 : 1;
    await switchLightDali(serial, onoffcontrolA, pwm, shortAdd, daliCtlObj);
  };

  const pwmMarks = useMemo(() => {
    const arr = gs.newArrayBetween(0, 10).map((n) => {
      const obj = { value: n * 10, label: n * 10 };
      return obj;
    });
    return arr;
  }, []);
  return (
    <ModalSM
      open={serial ? true : false}
      onClose={onClose}
      modalTitle={`Dali light control panel`}
      modalIcon={
        <LightIcon
          lightStyle="tube"
          width={4}
          height={4}
          sizeUnit="vh"
          disabledConnectStatus
          statusA={lightCurrentStatus?.onoffstatusA}
          lightLv={lightCurrentStatus?.lightLv}
        />
      }
      width="70vw"
      height="80vh"
    >
      <SpaceBetweenDiv title="Serial no" data={serial} />
      <SpaceBetweenDiv title="Dali short address" data={shortAdd} />
      {Object.keys(mapObj.lightObj || []).includes(serial) ? (
        <SpaceBetweenDiv
          title="In Map"
          data={
            <div>
              {(lightObjAll[serial]?.mapID || [])
                ?.filter((id) => Object.keys(mapObjAll).includes(id))
                ?.map((id, k) => (
                  <Typography
                    align="right"
                    display="block"
                    variant="caption"
                    key={k}
                  >
                    {mapObjAll[id]?.mapName} [{id}]
                  </Typography>
                ))}
            </div>
          }
        />
      ) : (
        <SpaceBetweenButton
          title="Add to map"
          btnContent="Add"
          onBtnClick={handleAddLightToMap}
        />
      )}
      <SpaceBetweenButton
        title="Change short address"
        btnContent={<ArrowForwardIos />}
        onBtnClick={handleChangeShortAdd}
        variant="text"
      />
      <SpaceBetweenDiv
        title="General dali query"
        data={<GeneralDaliQueryModal shortAdd={shortAdd} daliCtlObj={daliCtlObj} />}
      />
      <SpaceBetweenSelect
        title={
          <DivInline>
            <Typography>Fade time (seconds)</Typography>
            <IconButtonRefresh
              onBtnClick={async () =>
                await daliQueryFadeTime(daliCtlObj, shortAdd)
              }
            />
          </DivInline>
        }
        // menuItemWithData
        data={fadeTime || 0}
        onChange={async (e) => {
          const newFadeTime = e.target.value;
          setFadeTime(newFadeTime);
          await daliChangeFadeTime(daliCtlObj, shortAdd, Number(newFadeTime));
        }}
        menuObj={daliFadeTimeMenu}
      />
      <Divider10 />
      <SpaceBetweenDiv
        title={
          <DivInline>
            <Typography>Minimum level</Typography>
            <IconButtonRefresh
              onBtnClick={async () =>
                await daliQueryMinLv(daliCtlObj, shortAdd)
              }
            />
          </DivInline>
        }
        data={
          <DivInline>
            <Typography>{lightObj?.daliMinLv}</Typography>
            <DialogInput
              title="Change minimum level"
              dataType="number"
              initialVal={lightObj?.daliMinLv}
              handleSave={async (val) => {
                await daliChangeMinLv(daliCtlObj, shortAdd, val);
              }}
            />
          </DivInline>
        }
      />
      <SpaceBetweenDiv
        title={
          <DivInline>
            <Typography>Maximum level</Typography>
            <IconButtonRefresh
              onBtnClick={async () =>
                await daliQueryMaxLv(daliCtlObj, shortAdd)
              }
            />
          </DivInline>
        }
        data={
          <DivInline>
            <Typography>{lightObj?.daliMaxLv}</Typography>
            <DialogInput
              title="Change maximum level"
              dataType="number"
              initialVal={lightObj?.daliMaxLv}
              handleSave={async (val) => {
                await daliChangeMaxLv(daliCtlObj, shortAdd, val);
              }}
            />
          </DivInline>
        }
      />
      <Divider10 />
      <DivInline justifyContent="space-around">
        <Button
          variant="outlined"
          onClick={() => {
            setArcLv(0);
            handleDimDali(0);
          }}
        >
          OFF
        </Button>
        <Slider
          sx={{ width: "30vw", height: "2vh" }}
          value={arcLv}
          onChange={(evt, newVal) => setArcLv(newVal)}
          onChangeCommitted={(e, newVal) => {
            handleDimDali(newVal);
          }}
          max={100}
          min={0}
          step={10}
          marks={pwmMarks}
        />
      </DivInline>
      <Divider10 />
      <SpaceBetweenButton
        title="Reset light dali setting"
        btnContent="reset"
        onBtnClick={() => daliReset(daliCtlObj, shortAdd)}
        color="secondary"
      />
      <Divider10 />
      {/* <SpaceBetweenButton
        title="Delete light from dali controller"
        btnContent="delete"
        onBtnClick={handleDeleteLight}
        color="secondary"
      /> */}
    </ModalSM>
  );
}

// function GeneralDaliQueryModal({ daliCtlObj, shortAdd }) {
//   const [state, dispatch] = useContext(SMContext);
//   const [open, setOpen] = useState(false);
//   const [daliCmd, setDaliCmd] = useState(0);
//   const { socket } = state;
//   const { daliReply, daliCtlID } = daliCtlObj || {};
//   const daliRepDec = daliReply?.reply || 0;
//   const daliRepHex = "0x" + daliRepDec.toString(16);
//   const daliRepBin = "0b" + daliRepDec.toString(2);
//   const daliCmdHex = "0x" + daliReply?.daliCmd?.toString(16);
//   const handleDaliGeneralQuery = async () => {
//     await daliGeneralDaliQuery(daliCtlObj, Number(shortAdd), Number(daliCmd));
//   };
//   const handleClearReply = () => {
//     clearDaliReply(socket, daliCtlID);
//     dispatch({
//       type: "CLEARED_DALI_REPLY",
//       payload: daliCtlID,
//     });
//   };

//   return (
//     <>
//       <Button onClick={() => setOpen(true)}>
//         <ArrowForwardIos />
//       </Button>
//       <ModalSM
//         open={open}
//         onClose={() => setOpen(false)}
//         width="50vw"
//         height="90vh"
//         modalTitle={`Dali command code? [Short add: ${shortAdd}]`}
//         disableBottomClose
//       >
//         <DivInline>
//           <TextField
//             size="small"
//             sx={{ width: "80%" }}
//             value={daliCmd}
//             onChange={(e) => setDaliCmd(e.target.value)}
//           />
//           <Button onClick={handleDaliGeneralQuery}>
//             <ArrowForwardIos />
//           </Button>
//         </DivInline>
//         <DivExist show={daliReply?.daliCmd && daliRepDec}>
//           <DivInline>
//             <Box sx={{ width: "70%", outline: "1px solid #fff" }}>
//               <Typography>
//                 Dali command {daliReply?.daliCmd} / {daliCmdHex}
//               </Typography>
//               <Typography>
//                 Reply {daliRepDec} / {daliRepHex} / {daliRepBin}
//               </Typography>
//             </Box>
//             <IconButtonDelete onBtnClick={handleClearReply} />
//           </DivInline>
//         </DivExist>
//         <Divider10 />
//         {daliCmdJsonArray.map((obj, k) => (
//           <Typography
//             key={k}
//             display="block"
//             variant="caption"
//             sx={{ cursor: "pointer" }}
//             onClick={() => {
//               handleClearReply();
//               setDaliCmd(obj.cmd);
//             }}
//           >
//             {obj.cmd} - {obj.name}
//           </Typography>
//         ))}
//       </ModalSM>
//     </>
//   );
// }
