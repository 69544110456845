import { useState, useContext } from "react";
import { Search, SystemUpdate } from "@mui/icons-material";
import { Typography, Tooltip, IconButton, Button } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import { useNavigate } from "react-router-dom";
import ModalSM from "components/ModalSM";
import { fetchAllMap, fetchOneMap, importMap, updateSingleMapFsToLs } from "actions/mapActions";
import { mgFindOne } from "actions/mongoApiActions";
import DivCol from "components/DivCol";
import { alertWindow } from "actions/screenActions";
import IconButtonBack from "components/IconButtonBack";
import IconButtonDelete from "components/IconButtonDelete";
import { getDocsWhereFS } from "actions/firebaseMgActions";
import AdminContainer from "components/AdminContainer";
import General from "@ecoenghk/general";
const gs = new General();


export default function ImportMapModal() {
  const [state, dispatch] = useContext(SMContext);
  const navigate = useNavigate();
  const { mapObjAll, userObj, serverObj } = state;

  const [open, setOpen] = useState(false);
  const [mapID, setMapID] = useState("");
  const [mapObj, setMapObj] = useState({});
  const [mgMapObjAll, setMgMapObjAll] = useState({});
  const [fsMapObjArray, setFsMapObjArray] = useState([]);
  const [mapSource, setMapSource] = useState("localserver"); // localserver, fs
  const handleOpen = async () => {
    setOpen(true);
    const mapAll = await fetchAllMap();
    setMgMapObjAll(mapAll);
    setMapObj({});
    setMapSource("localserver");
    if (serverObj.enableUploadFS) {
      const mapAllFS = await getDocsWhereFS("SM_map", "array");
      setFsMapObjArray(mapAllFS);
    }
  };
  const checkMapID = async () => {
    const obj = await mgFindOne("map", "mapID", mapID);
    if (obj.result === "fail") {
      alertWindow(dispatch, `Map ID [${mapID}] not exists in database`);
    } else {
      setMapObj(obj);
    }
  };
  const handleImport = async () => {
    if (Object.keys(mapObjAll || {}).includes(mapID)) {
      alertWindow(dispatch, `Map ID [${mapID}] already exists in your map list`);
      return;
    }
    if (mapSource === "localserver") {
      await importMap(userObj, mapID, mapObj);

    }
    if (mapSource === "fs") {
      await updateSingleMapFsToLs(mapID);
    }
    dispatch({
      type: "UPDATED_MAP_OBJ",
      payload: mapObj,
    });
    dispatch({
      type: "SET_ACTIVE_MAP",
      payload: mapID,
    });
    dispatch({ type: "SET_ENABLE_PAN", payload: true });
    dispatch({ type: "SET_ENABLE_ZOOM", payload: "" });
    navigate("/MapPage");
  };

  return (
    <>
      <IconButton onClick={handleOpen} size="large">
        <Tooltip placement="top" title={<Typography>Import map</Typography>}>
          <SystemUpdate style={{ fontSize: "1.2em" }} />
        </Tooltip>
      </IconButton>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        width="85vw"
        height="80vh"
        disableBottomClose
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <SystemUpdate />
          <Typography>Import Map</Typography>
        </DivInline>
        <Divider10 />
        <SpaceBetweenInput
          title="Import map ID"
          data={mapID}
          width="40%"
          autoSelect
          onInput={(evt) => {
            setMapID(evt.target.value);
          }}
        />
        {
          (!mapObj || gs.isEmptyJson(mapObj)) &&
          <Button sx={{ marginTop: "2vh" }} fullWidth variant="outlined" disabled={!mapID} onClick={checkMapID}><Search />Check</Button>
        }
        <Divider10 />
        {mapObj?.mapID && (
          <DivCol alignItems="center">
            {
              mapObj?.mapFileName ?
                <img
                  src={`${global.ip}/img/${mapObj?.mapFileName}`}
                  style={{ width: "20vw", height: "16vh" }}
                  alt=""
                />
                :
                <img
                  src={mapObj?.mapUrl}
                  style={{ width: "20vw", height: "16vh" }}
                  alt=""
                />
            }

            <Typography>{mapObj?.mapName}</Typography>
            <Typography variant="caption">{mapObj?.mapID}</Typography>
            <IconButtonDelete onBtnClick={() => {
              setMapObj({});
              setMapID("");
              setMapSource("localserver");
            }} />
          </DivCol>
        )}
        <DivExist show={mapObj?.mapID}>
          <Button sx={{ marginTop: "2vh" }} fullWidth variant="outlined" onClick={handleImport}>Import</Button>
        </DivExist>
        <AdminContainer>
          <Divider10 />
          <DivInline alignItems="flex-start" justifyContent="flex-start" sx={{ outline: "1px solid grey" }}>
            <DivCol alignItems="flex-start" justifyContent="flex-start" sx={{ width: "50%", marginRight: "2%" }} >
              <Typography>Available map list on server</Typography>
              {Object.keys(mgMapObjAll || {})
                .filter((mid) => {
                  return !Object.keys(mapObjAll || {}).includes(mid);
                })
                .map((mid, key) => {
                  const obj = mgMapObjAll[mid];
                  const outline = mid === mapID && mapSource === "localserver" ? "2px solid red" : "0.3px solid grey";
                  return (
                    <DivInline
                      onClick={() => {
                        setMapID(mid);
                        setMapSource("localserver");
                        setMapObj(obj);
                      }}
                      key={key}
                      sx={{ outline, cursor: "pointer", width: "100%" }}
                      justifyContent="space-between"
                    >
                      <Typography variant="body2"> {obj?.mapName}</Typography>
                      <Typography variant="caption">{mid}</Typography>
                    </DivInline>
                  );
                })}
            </DivCol>
            {/* <DivCol alignItems="flex-start" justifyContent="flex-start" sx={{ width: "48%" }}>
              <DivExist show={serverObj.enableUploadFS}>
                <Typography>Available map list on cloud</Typography>
                {
                  fsMapObjArray
                    .filter((obj) => {
                      return !Object.keys(mapObjAll || {}).includes(obj.mapID);
                    })
                    .map((obj, key) => {
                      const mid = obj.mapID;
                      const outline = mid === mapID && mapSource === "fs" ? "2px solid red" : "0.3px solid grey";
                      return (
                        <DivInline
                          onClick={() => {
                            setMapID(mid);
                            setMapSource("fs");
                            setMapObj(obj);
                          }}
                          key={key}
                          sx={{ outline, cursor: "pointer", width: "100%" }}
                          justifyContent="space-between"
                        >
                          <Typography variant="body2"> {obj?.mapName}</Typography>
                          <Typography variant="caption">{mid}</Typography>
                        </DivInline>
                      )
                    })
                }
              </DivExist>
            </DivCol> */}
          </DivInline>



        </AdminContainer>

      </ModalSM>
    </>
  );
}
