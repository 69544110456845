import { useState, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Slider, Box, Tooltip, Button } from "@mui/material";
import { AddCircle, ArrowForwardIos } from "@mui/icons-material";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import General from "@ecoenghk/general";
import {
  changeDaliCtlOneSetting,
  daliAddSensor,
  daliBroadcastLight,
  daliChangeFadeTime,
  daliControlMultiLight,
  daliQueryLightLevel,
  queryDaliCtlOneSetting,
  daliReset,
} from "actions/daliCtlActions";
import LightIcon from "components/LightIcon";
import EditDaliLightModal from "./EditDaliLightModal";
import SensorIcon from "components/SensorIcon";
import ModalSM from "components/ModalSM";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import EditDaliSensorModal from "./EditDaliSensorModal";
import DivExist from "components/DivExist";
import { switchLightDali } from "actions/lightActions";
import LightBulb from "asset/svgComp/LightBulb";
import ButtonSM from "components/ButtonSM";
import DivCol from "components/DivCol";
import { mgFindOne } from "actions/mongoApiActions";
import { mapAddLight, mapRemoveLight } from "actions/mapActions";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import { daliFadeTimeMenu } from "asset/string/string";
import { useListenKey } from "hooks/mapHooks";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import InputField from "components/InputField";
import DialogInputMinSec from "components/DialogInputMinSec";
import { alertWindow, confirmWindow } from "actions/screenActions";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import AdminContainer from "components/AdminContainer";
import GeneralDaliQueryModal from "./GeneralDaliQueryModal";
const gs = new General();

export default function DaliSlavesControlPanel() {
  const [state, dispatch] = useContext(SMContext);
  const [broadcastLv, setBroadcastLv] = useState(0);
  const [chosenLight, setChosenLight] = useState({});
  const [chosenSensor, setChosenSensor] = useState({});
  const [fadeTime, setFadeTime] = useState(0);
  const [daliSlavesSelectedAry, setDaliSlavesSelectedAry] = useState([]);
  const [shiftHeld, setShiftHeld] = useState(false);
  const [controlOption, setControlOption] = useState({
    lockLv: 0,
    releaseOnDelayEnd: true,
    delayAction: false,
    delaySec: 5,
    delayPwm: 0,
    delayAction2: false,
    delaySec2: 5,
    delayPwm2: 0,
  });
  useListenKey("Shift", setShiftHeld);
  const {
    daliCtlObjAll,
    activeDaliCtlID,
    lightObjAll,
    sensorObjAll,
    activeMapID,
    mapObjAll
  } = state;
  const daliCtlObj = daliCtlObjAll[activeDaliCtlID];
  const { lightObj, sensorObj, addList, daliType } = daliCtlObj || {};
  const arcLvMarks = useMemo(() => {
    const arr = gs.newArrayBetween(0, 5).map((n) => {
      const obj = { value: n * 50, label: n * 50 };
      return obj;
    });
    return arr;
  }, []);
  const handleAddLightToMap = async (serial) => {
    const obj = await mgFindOne("serial", "serial", serial);
    if (obj) {
      const mapObj = mapObjAll[activeMapID];
      const mapDaliPos = mapObj?.daliCtlObj?.[activeDaliCtlID] || {};
      const randomRange = (1.2 * mapObj?.iconSize) || 30;
      const x = mapDaliPos.x + gs.randomInteger(-randomRange, randomRange);
      const y = mapDaliPos.y + gs.randomInteger(-randomRange, randomRange);
      await mapAddLight(activeMapID, serial, obj, x, y);
    }
  };
  const unconnectSerialArrInMap = useMemo(() => {
    const arr = Object.keys(lightObjAll || {}).filter((serial) => {
      if (
        lightObjAll[serial].daliCtlID === activeDaliCtlID &&
        !Object.keys(lightObj || {}).includes(serial)
      ) {
        return true;
      }
    });
    return arr;
  }, [lightObjAll]);
  const addListMatch = useMemo(() => {
    let addAryByLightObj = (Object.values(lightObj)).map((obj) => obj.shortAdd);
    addAryByLightObj = addAryByLightObj.sort((a, b) => a - b);
    return gs.arrayEquals(addList, addAryByLightObj);
  }, [lightObj, addList]);

  return (
    <Box>
      <Typography variant="h6" align="center">
        Control lights
      </Typography>
      <DivExist show={unconnectSerialArrInMap.length > 0}>
        <Divider10 />
        <DivInline>
          <Typography variant="caption">
            {unconnectSerialArrInMap.join(", ") +
              " are in this map but not exist in this dali controller"}
          </Typography>
          <Button
            variant="outlined"
            sx={{ marginLeft: "1vw" }}
            onClick={async () => {
              await gs.asyncForEach(unconnectSerialArrInMap, async (serial) => {
                await mapRemoveLight(activeMapID, serial);
              });
            }}
          >
            Remove from map
          </Button>
        </DivInline>
        <Divider10 />
      </DivExist>
      <Box
        sx={{
          width: "95%",
          overflow: "auto",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {Object.keys(lightObj || {})
          .sort((a, b) => a.split("_")[1] - b.split("_")[1])
          .map((serial, key) => {
            const { shortAdd } = lightObj[serial] || {};
            const lightCurrentStatus = daliCtlObj?.powerOnOffStatus ? lightObjAll[serial]?.currentData : 0;
            const fadeTime = lightObjAll[serial]?.fadeTime;
            const connect = lightObjAll[serial]?.zigbeeConnected;
            const inMap = lightObjAll[serial] ? true : false;
            return (
              <DeviceCard
                key={key}
                width="5vw"
                height="12vh"
                onClick={(e) => {
                  e.stopPropagation();
                  if (shiftHeld) {
                    document.getSelection().removeAllRanges();
                    if (daliSlavesSelectedAry.includes(serial)) {
                      setDaliSlavesSelectedAry((prev) =>
                        prev.filter((s) => s !== serial)
                      );
                    } else {
                      setDaliSlavesSelectedAry((prev) => [...prev, serial]);
                    }
                  } else {
                    setChosenLight(lightObj[serial]);
                  }
                }}
                selected={daliSlavesSelectedAry.includes(serial)}
              >
                <DivCol>
                  <LightIcon
                    lightStyle="tube"
                    width={2}
                    height={2}
                    sizeUnit="vh"
                    connectStatus={connect}
                    disabledConnectStatus={inMap ? false : true}
                    statusA={lightCurrentStatus?.onoffstatusA}
                    lightLv={lightCurrentStatus?.lightLv}
                  />
                  <Typography variant="caption" sx={{ color: "#000" }}>Address</Typography>
                  <Typography sx={{ color: "#000" }}>{shortAdd} </Typography>

                </DivCol>
                <DivInline>
                  <ButtonSM
                    margin={0}
                    onClick={(e) => {
                      e.stopPropagation();
                      switchLightDali(serial, 0, 100, shortAdd, daliCtlObj);
                    }}
                    mouseOverBorder="red"
                    tooltip="100%"
                  >
                    <LightBulb width={1.5} sizeUnit="vw" onoff="on" />
                  </ButtonSM>
                  <ButtonSM
                    margin={0}
                    onClick={(e) => {
                      e.stopPropagation();
                      switchLightDali(serial, 1, 0, shortAdd, daliCtlObj);
                    }}
                    mouseOverBorder="red"
                    tooltip="off"
                  >
                    <LightBulb width={1.5} sizeUnit="vw" onoff="off" />
                  </ButtonSM>
                  <DivExist show={!inMap}>
                    <ButtonSM
                      tooltip="Add to map"
                      margin={0}
                      onClick={async (e) => {
                        e.stopPropagation();
                        await handleAddLightToMap(serial);
                      }}
                      mouseOverBorder="red"
                    >
                      <AddCircle sx={{ color: "darkgray" }} />
                    </ButtonSM>
                  </DivExist>
                </DivInline>
              </DeviceCard>
            );
          })}
        {/* <AddLightModal
          handleAdd={(shortAdd) => {
            daliAddLight(daliCtlObj, shortAdd);
          }}
          daliCtlObj={daliCtlObj}
        /> */}
      </Box>
      <Divider10 />
      <DivExist show={!addListMatch}>
        <Typography variant="caption" color="error">Light object not match address list, scan lights to fix</Typography>
      </DivExist>
      <DivExist show={daliType === "dalidtk"}>
        <DivInline justifyContent="space-between">
          <Typography></Typography>
          <Typography variant="h6" align="center">
            {daliSlavesSelectedAry.length > 0
              ? `Unicast to ${daliSlavesSelectedAry.length} lights`
              : "Broadcast"}
          </Typography>
          {daliSlavesSelectedAry.length > 0 ? (
            <Button
              variant="outlined"
              color="error"
              onClick={() => setDaliSlavesSelectedAry([])}
            >
              Clear
            </Button>
          ) : (
            <Typography></Typography>
          )}
        </DivInline>
        <DivInline justifyContent="space-between">
          <Typography>Direct arc power</Typography>
          <Slider
            sx={{ width: "50%", height: "2vh", marginRight: "1vw" }}
            value={broadcastLv}
            disabled={Object.keys(lightObj || {}).length === 0}
            onChangeCommitted={async (e, newVal) => {
              console.log(newVal);
              if (daliSlavesSelectedAry.length > 0) {
                const light_qty = Object.keys(lightObj).length;
                const shortAddAry = Object.keys(lightObj).map(
                  (serial) => lightObj[serial].shortAdd
                );
                const lightLvAry = Array.from(
                  { length: light_qty },
                  (v, i) => 101
                );
                const D1LightLvAry = Array.from(
                  { length: light_qty },
                  (v, i) => 101
                );
                const D2LightLvAry = Array.from(
                  { length: light_qty },
                  (v, i) => 101
                );
                daliSlavesSelectedAry.map((serial) => {
                  const { shortAdd } = lightObj[serial];
                  const index = shortAddAry.indexOf(shortAdd);
                  lightLvAry[index] = (newVal / 250) * 100;
                  if (controlOption?.delayAction)
                    D1LightLvAry[index] =
                      controlOption?.delayPwm == 0
                        ? 0
                        : (controlOption?.delayPwm / 250) * 100;
                  if (controlOption?.delayAction2)
                    D2LightLvAry[index] =
                      controlOption?.delayPwm2 == 0
                        ? 0
                        : (controlOption?.delayPwm2 / 250) * 100;
                });
                if (controlOption?.delayAction2)
                  await daliControlMultiLight(
                    daliCtlObj,
                    controlOption.lockLv + 1,
                    controlOption.releaseOnDelayEnd,
                    lightLvAry,
                    controlOption.delaySec,
                    D1LightLvAry,
                    controlOption.delaySec2,
                    D2LightLvAry
                  );
                else if (controlOption?.delayAction)
                  await daliControlMultiLight(
                    daliCtlObj,
                    controlOption.lockLv + 1,
                    controlOption.releaseOnDelayEnd,
                    lightLvAry,
                    controlOption.delaySec,
                    D1LightLvAry
                  );
                else
                  await daliControlMultiLight(
                    daliCtlObj,
                    controlOption.lockLv + 1,
                    controlOption.releaseOnDelayEnd,
                    lightLvAry
                  );
              } else await daliBroadcastLight(daliCtlObj, Number(newVal));
              setBroadcastLv(newVal);
            }}
            max={253}
            min={0}
            step={10}
            marks={arcLvMarks}
          />
        </DivInline>
        <AdminContainer>
          <Divider10 />
          <SpaceBetweenDiv
            title="Power on / off all slaves"
            data={
              <DivInline>
                <Button
                  variant={!daliCtlObj?.powerOnOffStatus ? "contained" : "outlined"}
                  color={!daliCtlObj?.powerOnOffStatus ? "error" : "primary"}
                  onClick={async () => {
                    await changeDaliCtlOneSetting(daliCtlObj, 0x0a2a, { powerOnOff: 0 });
                    await gs.waitFor(1500);
                    await queryDaliCtlOneSetting(daliCtlObj, 0x0b18);
                  }}>
                  OFF
                </Button>
                <Button
                  variant={daliCtlObj?.powerOnOffStatus ? "contained" : "outlined"}
                  onClick={async () => {
                    await changeDaliCtlOneSetting(daliCtlObj, 0x0a2a, { powerOnOff: 1 });
                    await gs.waitFor(1500);
                    await queryDaliCtlOneSetting(daliCtlObj, 0x0b18);
                    await gs.waitFor(3000);
                    await daliQueryLightLevel(daliCtlObj, 99);
                  }}>
                  ON
                </Button>
              </DivInline>
            }
            handleRefresh={() => queryDaliCtlOneSetting(daliCtlObj, 0x0b18)}
          />
        </AdminContainer>
        <DivExist show={daliSlavesSelectedAry.length > 0}>
          <Typography>control light option:</Typography>
          <SpaceBetweenSelect
            title="lock level:"
            data={controlOption.lockLv || 0}
            onChange={(e) => {
              setControlOption((prev) => ({
                ...prev,
                lockLv: Number(e.target.value),
              }));
            }}
            menuObj={gs.newArrayBetween(1, 5)}
          />
          <SpaceBetweenCheckbox
            title="release on end"
            data={controlOption.releaseOnDelayEnd ? true : false}
            onCheck={async (e) => {
              setControlOption((prev) => ({
                ...prev,
                releaseOnDelayEnd: e.target.checked,
              }));
            }}
          />
          <SpaceBetweenCheckbox
            title="delay Action"
            data={controlOption.delayAction ? true : false}
            onCheck={async (e) => {
              setControlOption((prev) => ({
                ...prev,
                delayAction: e.target.checked,
              }));
            }}
          />
          <DivExist show={controlOption.delayAction}>
            <DivInline justifyContent="space-between">
              <Typography>delay time(s):</Typography>
              <DialogInputMinSec
                title="delay time 1"
                initialSec={controlOption.delaySec || 5}
                iconSize={30}
                showValue
                showValueVariant="body1"
                onInput={async (val) => {
                  if (val > 28800) {
                    alertWindow(dispatch, "Maximum delay time is 8 hours");
                    return;
                  }
                  setControlOption((prev) => ({ ...prev, delaySec: val }));
                }}
                minSec={5}
                dispatch={dispatch}
              />
              <Slider
                sx={{ width: "50%", height: "2vh", marginRight: "1vw" }}
                value={controlOption?.delayPwm || 0}
                onChangeCommitted={async (e, newVal) => {
                  setControlOption((prev) => ({
                    ...prev,
                    delayPwm: Number(newVal),
                  }));
                }}
                max={253}
                min={0}
                step={10}
                marks={arcLvMarks}
              />
            </DivInline>
            <SpaceBetweenCheckbox
              title="delay Action2"
              data={controlOption.delayAction2 ? true : false}
              onCheck={async (e) => {
                setControlOption((prev) => ({
                  ...prev,
                  delayAction2: e.target.checked,
                }));
              }}
            />
          </DivExist>
          <DivExist show={controlOption.delayAction2}>
            <DivInline justifyContent="space-between">
              <Typography>delay time2(s):</Typography>
              <DialogInputMinSec
                title="delay time 1"
                initialSec={controlOption.delaySec2 || 5}
                iconSize={30}
                showValue
                showValueVariant="body1"
                onInput={async (val) => {
                  if (val > 28800) {
                    alertWindow(dispatch, "Maximum delay time is 8 hours");
                    return;
                  }
                  setControlOption((prev) => ({ ...prev, delaySec2: val }));
                }}
                minSec={5}
                dispatch={dispatch}
              />
              <Slider
                sx={{ width: "50%", height: "2vh", marginRight: "1vw" }}
                value={controlOption.delayPwm2 || 0}
                onChangeCommitted={async (e, newVal) => {
                  setControlOption((prev) => ({
                    ...prev,
                    delayPwm2: Number(newVal),
                  }));
                }}
                max={253}
                min={0}
                step={10}
                marks={arcLvMarks}
              />
            </DivInline>
          </DivExist>
        </DivExist>
        <Divider10 />
        <SpaceBetweenSelect
          title={`Change ${daliSlavesSelectedAry.length > 0
            ? daliSlavesSelectedAry.length
            : "all"
            } light fade time (seconds)`}
          data={fadeTime}
          onChange={async (e) => {
            const newFadeTime = Number(e.target.value);
            setFadeTime(newFadeTime);
            if (daliSlavesSelectedAry.length > 0) {
              const light_qty = Object.keys(lightObj).length;
              const shortAddAry = Object.keys(lightObj).map(
                (serial) => lightObj[serial].shortAdd
              );
              const FadeTimeAry = Array.from(
                { length: light_qty },
                (v, i) => 101
              );
              daliSlavesSelectedAry.map((serial) => {
                const { shortAdd } = lightObj[serial];
                const index = shortAddAry.indexOf(shortAdd);
                FadeTimeAry[index] = shortAdd;
              });
              await daliChangeFadeTime(daliCtlObj, FadeTimeAry, newFadeTime);
            } else await daliChangeFadeTime(daliCtlObj, 100, newFadeTime);
          }}
          menuObj={daliFadeTimeMenu}
        />

      </DivExist>
      <AdminContainer>
        <Divider10 />
        <SpaceBetweenButton
          title="Reset all lights dali setting"
          btnContent={<ArrowForwardIos />}
          onBtnClick={async () => {
            confirmWindow(
              dispatch,
              "Confirm reset all lights dali setting?",
              () => {
                daliReset(daliCtlObj, 100);
              }
            );
          }}
          color="secondary"
        />
        <SpaceBetweenDiv
          title="General dali query"
          data={<GeneralDaliQueryModal daliCtlObj={daliCtlObj} />}
        />
      </AdminContainer>
      <Divider10 />
      <EditDaliLightModal
        daliLightObj={chosenLight}
        onClose={() => setChosenLight({})}
        daliCtlObj={daliCtlObj}
      />
      <DivExist show={daliType === "dalimqtt"}>
        <Divider10 />
        <Typography variant="h6" align="center">
          Sensors
        </Typography>
        <Box
          sx={{
            width: "90%",
            overflow: "auto",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(sensorObj || {})
            .sort((a, b) => {
              const shortAddA = Number(a.split("_")[1]);
              const shortAddB = Number(b.split("_")[1]);
              const instanceA = Number(a.split("_")[2]);
              const instanceB = Number(b.split("_")[2]);
              if (shortAddA === shortAddB) {
                return instanceA < instanceB ? -1 : 1;
              } else {
                return shortAddA < shortAddB ? -1 : 1;
              }
            })
            .map((sensorID, key) => {
              const { shortAdd, connect, instance, type } =
                sensorObj[sensorID] || {};
              const { status, status2, status3, status4 } =
                sensorObjAll[sensorID] || {};
              return (
                <DeviceCard
                  key={key}
                  width="8vw"
                  height="10vh"
                  onClick={() => setChosenSensor(sensorObj[sensorID])}
                >
                  <SensorIcon
                    type={type}
                    status={status}
                    status2={status2}
                    status3={status3}
                    status4={status4}
                    width={3}
                    height={3}
                    sizeUnit="vh"
                    disabledConnectStatus
                    // connectStatus={connect}
                    gang={1}
                  />
                  <Typography variant="caption" sx={{ color: "#000" }}>
                    {sensorID}
                  </Typography>
                  <Typography sx={{ color: "#000" }} variant="caption">
                    Add:{shortAdd}
                  </Typography>
                  <Typography sx={{ color: "#000" }} variant="caption">
                    Instance:{instance}
                  </Typography>
                </DeviceCard>
              );
            })}
          <AddSensorModal
            handleAdd={(shortAdd, instance, type) => {
              daliAddSensor(daliCtlObj, shortAdd, instance, type);
            }}
            daliCtlObj={daliCtlObj}
          />
        </Box>
        <EditDaliSensorModal
          daliSensorObj={chosenSensor}
          onClose={() => setChosenSensor({})}
          daliCtlObj={daliCtlObj}
        />
      </DivExist>
    </Box>
  );
}

function DeviceCard({
  children,
  onClick,
  width,
  height,
  tooltip = "",
  selected,
}) {
  return (
    <Tooltip title={tooltip}>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "0.5vw",
          margin: "0.3vw",
          width: width || "8vw",
          height: height || "8vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            outline: selected ? "5px solid green" : "5px solid blue",
          },
          outline: selected ? "5px solid green" : "none",
        }}
        onClick={onClick}
      >
        {children}
      </Box>
    </Tooltip>
  );
}

function AddSensorModal({ handleAdd, daliCtlObj }) {
  const [open, setOpen] = useState(false);
  const [shortAdd, setShortAdd] = useState(0);
  const [instance, setInstance] = useState(0);
  const [type, setType] = useState("motion");
  const handleClose = () => {
    setOpen(false);
    setShortAdd(0);
    setInstance(0);
    setType("motion");
  };
  const { sensorObj } = daliCtlObj || {};
  const sensorShortAddArr = Object.keys(sensorObj || {}).map(
    (obj) => obj.shortAdd
  );
  const sensorShortAddMenu = gs
    .newArrayBetween(0, 63)
    .filter((n) => !sensorShortAddArr.includes(n));
  return (
    <>
      <DeviceCard onClick={() => setOpen(true)} height="10vh">
        <AddCircle sx={{ color: "darkgrey", fontSize: "3rem" }} />
      </DeviceCard>
      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle="Add slave sensor"
        width="60vw"
        height="60vh"
        onAdd={() => {
          handleAdd(shortAdd, instance, type);
        }}
      >
        <SpaceBetweenSelect
          title="Sensor Short address(0-63)?"
          data={shortAdd || 0}
          onChange={(e) => {
            setShortAdd(e.target.value);
          }}
          menuObj={sensorShortAddMenu}
        />
        <SpaceBetweenSelect
          title="Instance(0-3)?"
          data={instance || 0}
          onChange={(e) => {
            setInstance(e.target.value);
          }}
          menuObj={gs.newArrayBetween(0, 3)}
        />
        <SpaceBetweenSelect
          title="Sensor type"
          type={type || ""}
          onChange={(e) => setType(e.target.value)}
          menuObj={{
            motion: "motion",
            daylight: "daylight",
            scenebutton: "scene button",
            lightswitch: "light switch",
          }}
        />
      </ModalSM>
    </>
  );
}
