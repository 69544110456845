import React, { useState, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, IconButton } from "@mui/material";
import { TouchApp } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import { sensorDisconnectLight, sensorDisconnectSensor, sensorManualExecuteSetting } from "actions/sensorActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import SensorChangeLockLvModal from "./SensorChangeLockLvModal";
import DeviceIdAddGwLine from "components/DeviceIdAddGwLine";
import DeviceActionLine from "components/DeviceActionLine";
import ButtonSM from "components/ButtonSM";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import ModalSM from "components/ModalSM";
import SensorNearFieldPanel from "./SensorNearFieldPanel";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import General from "@ecoenghk/general";
import GroupActionConfirmOneGroup from "components/GroupActionConfirmOneGroup";
import AdminContainer from "components/AdminContainer";
import IconButtonDelete from "components/IconButtonDelete";
import { mgFindOne } from "actions/mongoApiActions";
import { alertWindow, confirmWindow } from "actions/screenActions";
const gs = new General();
export default function ControlDevicesSceneButton({ show }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeSensorID, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const { gang } = sensorObj || {};
  const settingNumArray = gs.newArrayBetween(1, gang || 1);


  if (!show) return null;
  return (
    <DivCol>
      <DivInline justifyContent="center">
        <Typography variant="h6">Control Devices</Typography>
        <GroupActionConfirmOneGroup groupDeviceObj={sensorObj} settingNumArray={settingNumArray} />
      </DivInline>
      <Divider10 />
      <div></div>
      <ButtonGroup buttonNo={1} />
      <DivExist show={gang > 1}>
        <ButtonGroup buttonNo={2} />
        <DivExist show={gang > 2}>
          <ButtonGroup buttonNo={3} />
          <DivExist show={gang > 3}>
            <ButtonGroup buttonNo={4} />
          </DivExist>
        </DivExist>
      </DivExist>
    </DivCol>
  );
}

function ButtonGroup({ buttonNo }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeSensorID, lightObjAll, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const lockLvSet = sensorObj[`lockLevelSet${buttonNo}`];
  const releaseOnDelayEnd = sensorObj[`releaseOnDelayEnd${buttonNo}`];
  const setting = sensorObj[`setting${buttonNo}`];
  const lightArr = Object.keys(setting || {})
    .filter((s) => "serial" in setting[s])
    .sort((a, b) =>
      lightObjAll[a]?.description?.localeCompare(lightObjAll[b]?.description)
    );
  const sensorArr = Object.keys(setting || {})
    .filter((s) => "sensorID" in setting[s])
    .sort((a, b) => sensorObjAll[a]?.sensorName?.localeCompare(sensorObjAll[b]?.sensorName));

  return (
    <div style={{ width: "100%" }}>
      <DivInline justifyContent="space-between">
        <DivInline>
          <Typography>Button {buttonNo}</Typography>
          <Tooltip title={`Manual run Button ${buttonNo}`}>
            <div>
              <IconButton
                size="small"
                disabled={lightArr.length === 0}
                onClick={() => sensorManualExecuteSetting(sensorObj, buttonNo)}
              >
                <TouchApp />
              </IconButton>
            </div>
          </Tooltip>
        </DivInline>
        <DivSpaceBetween>
          <Typography variant="caption">Priority Level</Typography>
          <SensorChangeLockLvModal
            existingLv={lockLvSet}
            existingRelease={releaseOnDelayEnd}
            buttonNo={buttonNo}
            sensorObj={sensorObj}
          />
        </DivSpaceBetween>

      </DivInline>
      <Divider10 />
      {(lightArr || []).map((s) => {
        return (
          <LightRow
            key={s}
            serial={s}
            lightObj={lightObjAll[s]}
            settingObj={setting}
            sensorObj={sensorObj}
            buttonNo={buttonNo}
          />
        );
      })}

      {(sensorArr || []).map((s) => {
        const connectSensorObj = sensorObjAll[s];
        return (
          <SensorRow key={s} controlledSensorObj={connectSensorObj} settingObj={setting} mainSensorObj={sensorObj} buttonNo={buttonNo} />
        );
      }
      )}
      <Divider10 />
    </div>
  );
}

function LightRow({ serial, lightObj, settingObj, sensorObj, buttonNo }) {
  const [state, dispatch] = useContext(SMContext);
  const [openNearField, setOpenNearField] = useState(false);
  const { nearFieldSetting } = sensorObj || {};
  const serialSetting = settingObj[serial] || {};
  const { dtkAdd, gatewayID } = serialSetting;
  const lightDescription = lightObj ? lightObj?.description : "Unknown light"
  return (
    <div>
      <DivInline hide={!lightObj}>
        <Typography sx={{ marginRight: "1vw" }} onClick={(e) => console.log(serialSetting)}
          variant="caption">{lightDescription}</Typography>
        <GroupActionConfirmOneDevice deviceObj={sensorObj} controlledDeviceObj={lightObj} settingNumArray={[buttonNo]} />
      </DivInline>
      <DivInline>
        <DeviceIdAddGwLine deviceID={serial} address={dtkAdd} gatewayID={gatewayID} />
        <DivExist show={lightObj ? true : false}>
          <AdminContainer>
            <ButtonSM
              tooltip="Near field command setting"
              margin={0}
              onClick={() => setOpenNearField(true)}>
              <NearFieldIcon width={2} sizeUnit={'vw'} color={nearFieldSetting?.[`${serial}_${buttonNo}_${lightObj?.shortAdd || 0}`] ? "red" : "#fff"} />
            </ButtonSM>
          </AdminContainer>
        </DivExist>
        <DivExist show={!lightObj ? true : false}>
          <Typography variant="caption" color="error">Light not in this map</Typography>
          <IconButtonDelete
            tooltip="Force disconnect"
            size="small"
            onBtnClick={async () => {
              confirmWindow(dispatch, "Force disconnect?", async () => {
                const obj = await mgFindOne("serial", "serial", serial);
                if (!obj) {
                  await gs.waitFor(1000);
                  alertWindow(dispatch, `Light ${serial} not found in database`);
                  return;
                }
                await sensorDisconnectLight(sensorObj, sensorObj.gatewayID, obj);
              });

            }}
          />
        </DivExist>
      </DivInline>
      <DeviceActionLine deviceSetting={serialSetting} deviceObj={lightObj} mainDeviceObj={sensorObj} buttonNo={buttonNo} />
      <AdminContainer>
        <ModalSM open={openNearField} onClose={() => setOpenNearField(false)} disableBottomClose >
          <SensorNearFieldPanel
            sensorObj={sensorObj}
            deviceObj={lightObj}
            handleClose={() => setOpenNearField(false)}
            dispatch={dispatch}
            buttonNo={buttonNo}
          />
        </ModalSM>
      </AdminContainer>
      <Divider10 space={2} />
    </div>
  );
}
function SensorRow({ controlledSensorObj, settingObj, mainSensorObj, buttonNo }) {
  const [state, dispatch] = useContext(SMContext);
  const { sensorID, sensorName, dtkAdd } = controlledSensorObj || {};
  const deviceSetting = settingObj[sensorID];
  return (
    <div >
      <DivInline hide={!controlledSensorObj}>
        <Typography variant="caption">{sensorName || "unknown sensor"}</Typography>
        <GroupActionConfirmOneDevice deviceObj={mainSensorObj} controlledDeviceObj={controlledSensorObj} settingNumArray={[buttonNo]} />
      </DivInline>

      <DeviceIdAddGwLine deviceID={sensorID} address={dtkAdd} gatewayID={deviceSetting?.gatewayID} />
      <DeviceActionLine deviceSetting={deviceSetting} deviceObj={controlledSensorObj} mainDeviceObj={mainSensorObj} buttonNo={buttonNo} />
      <DivExist show={!controlledSensorObj ? true : false}>
        <Typography variant="caption" color="error">Sensor not in this map</Typography>
        <IconButtonDelete
          tooltip="Force disconnect"
          size="small"
          onBtnClick={async () => {
            confirmWindow(dispatch, "Force disconnect?", async () => {
              const obj = await mgFindOne("sensor", "sensorID", sensorID);
              if (!obj) {
                await gs.waitFor(1000);
                alertWindow(dispatch, `Sensor ${sensorID} not found in database`);
                return;
              }
              await sensorDisconnectSensor(mainSensorObj, controlledSensorObj);
            });

          }}
        />
      </DivExist>
    </div>
  )
}
