import { useState, useEffect } from "react";
import KonvaFaButton from "containers/map/device/KonvaFaButton";
import { Group } from "react-konva";
import { mapRemoveGateway, deviceToolbarPos } from "actions/mapActions";
import { gatewayColorDark } from "actions/generalActions";
import RubbishBin from "asset/mapComp/RubbishBin";
import Magnifier from "asset/mapComp/Magnifier";
import QuestionMark from "asset/mapComp/QuestionMark";
import Document from "asset/mapComp/Document";
import { confirmWindow } from "actions/screenActions";
import General from "@ecoenghk/general";
import { queryGatewayOneSetting } from "actions/gatewayActions";
const gs = new General();
export default function ToolbarGateway({ layerProps }) {
  const {
    layerRef,
    mapObjAll,
    activeMapID,
    mapScale,
    iconSize,
    selectedID,
    selectedDeviceObj,
    dispatch,
    gatewayObjAll,
    lightObjAll,
    enablePan,
  } = layerProps;
  const [scale, setScale] = useState(1);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);

  const activeMapObj = mapObjAll[activeMapID];
  const { x, y } = activeMapObj?.gatewayObj[selectedID] || {};
  const mapID = activeMapObj.mapID;
  const btnSize = activeMapObj.buttonSize / scale;
  const btnSpace = btnSize * 1.2;
  const handleOpenDetail = async () => {
    dispatch({
      type: "OPENED_GATEWAY_DETAIL_MODAL",
      payload: selectedID,
    });
    await queryGatewayOneSetting(selectedID, 0x0015, "queryTimeTable");
    await gs.waitFor(1000);
    await queryGatewayOneSetting(selectedID, 0x0016, "queryBindedDevice");
    await gs.waitFor(1000);
    await queryGatewayOneSetting(selectedID, 0x000d, "queryGwSensorSetting");
  };
  const handleRemove = async () => {
    confirmWindow(
      dispatch,
      `Confirm removing gateway ${selectedID} from map ${mapID}?`,
      async () => {
        await mapRemoveGateway(activeMapID, selectedID, selectedDeviceObj);
        dispatch({
          type: "ALL_UNSELECTED",
        });
      }
    );
  };
  const handleCheckConnection = async () => {
    await queryGatewayOneSetting(selectedID, 0x000c, "queryDeviceSurvival");
  };
  const handleShowEspWeb = async () => {
    dispatch({
      type: "SET_MAP_LAYER",
      payload: {
        normal: false,
        espWeb: true,
      },
    })
  }

  // async function searchIdleLights() {
  //   animateRipple();
  // }
  // const ripple = useRef();
  // const animateRipple = () => {
  //   ripple.current.to({
  //     opacity: 1,
  //     duration: 0.1,
  //     onFinish: () => {
  //       ripple.current.to({
  //         width: 100,
  //         height: 100,
  //         opacity: 0,
  //         duration: 0.8,
  //         onFinish: () => {
  //           ripple.current.to({
  //             width: 50,
  //             height: 50,
  //             duration: 0.1,
  //           });
  //         },
  //       });
  //     },
  //   });
  // };

  const { gwVersion } = gatewayObjAll[selectedID] || {};
  const gwMainVersion = Number(gwVersion?.substring(3, 4));
  let serialMapInMap = {};
  let zbMapInMap = {};
  Object.keys(activeMapObj?.lightObj || {}).forEach((s) => {
    const zb = lightObjAll[s]?.zigbeeAdd;
    serialMapInMap[s] = zb;
    zbMapInMap[zb] = s;
  });
  let btnContent = [
    {
      onClick: handleOpenDetail,
      icon: <Document x={0} y={0} size={btnSize} />,
      tooltip: "Gateway detail",
    },
    {
      onClick: handleRemove,
      icon: <RubbishBin x={0} y={0} size={btnSize} />,
      tooltip: "Remove from map",
    },
    {
      onClick: handleCheckConnection,
      icon: <QuestionMark x={0} y={0} size={btnSize} />,
      tooltip: "Check connection",
    },
    {
      onClick: handleShowEspWeb,
      icon: <Magnifier x={0} y={0} size={btnSize} />,
      tooltip: "ESP Web",
    }
  ];
  const pos = deviceToolbarPos(x, y, btnContent.length, btnSpace, layerProps);
  return (
    <Group x={pos.x} y={pos.y}>
      {btnContent.map((obj, key) => (
        <KonvaFaButton
          key={key}
          x={btnSpace * key}
          y={0}
          mapScale={mapScale}
          enablePan={enablePan}
          stageScale={scale}
          btnSize={btnSize}
          onBtnClick={obj.onClick}
          iconSvg={obj.icon}
          tooltip={obj.tooltip}
          stroke={obj.stroke}
          tagColor={gatewayColorDark}
        />
      ))}
    </Group>
  );
}
