import React, { useState, useContext, useEffect, useRef } from "react";
import { SMContext } from "context/smContext";
import { Typography, IconButton, Tab } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import SensorDetailHistoryModal from "./SensorDetailHistoryModal";
import SensorHistoryItem from "./SensorHistoryItem";
import { fetchSensorHistory } from "actions/sensorActions";
import DivExist from "components/DivExist";
import ChartLine from "components/ChartLine";
import { ViewportList } from "react-viewport-list";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function SensorDataLogPanel() {
  const [state] = useContext(SMContext);
  const ref = useRef(null);
  const { activeSensorID, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const { type } = sensorObj || {};
  const [sensorDataLog, setSensorDataLog] = useState([]);
  const [luxArray, setLuxArray] = useState([]);
  const [luxDurationArray, setLuxDurationArray] = useState([]);
  const [tab, setTab] = useState(type === "daylight" ? "chart" : "history");


  useEffect(() => {
    if (activeSensorID) fetchLogs();
  }, [activeSensorID]);

  const fetchLogs = async () => {
    let arr = await fetchSensorHistory(activeSensorID, moment().subtract(1, "hours").valueOf(), moment().valueOf());
    arr = arr
      .filter((obj) => obj.uploadProtocol !== "gw_zigbeeConnected")
      .sort((a, b) => b.timeStamp - a.timeStamp)
      .filter((obj, key) => key < 1000);
    if (type === "scenebutton") {
      arr = arr.filter((obj) => {
        if (
          obj.uploadProtocol === "8282" &&
          obj.currentStatus === 0 &&
          obj.currentStatus2 === 0 &&
          obj.currentStatus3 === 0 &&
          obj.currentStatus4 === 0
        )
          return false;
        else return true;
      });
    }
    console.log(arr);
    if (type === 'daylight') {
      setSensorDataLog(arr);
      let luxObjArr = arr.filter(obj => "lux" in obj);
      const luxArr = luxObjArr.map(obj => obj.lux);
      const initialTsp = luxObjArr[luxObjArr.length - 1].timeStamp;
      const luxDurationArray = luxObjArr.map(obj => {
        const duration = obj.timeStamp - initialTsp;
        return duration / 1000 / 60;
      })
      setLuxArray(luxArr);
      setLuxDurationArray(luxDurationArray);
    }
  };

  return (
    <div>
      <Divider10 />
      <DivInline justifyContent="center">
        <Typography variant="h6">History</Typography>
        <IconButton onClick={fetchLogs}>
          <Refresh />
        </IconButton>
        <SensorDetailHistoryModal />
      </DivInline>
      <Divider10 />
      {
        type === "daylight" ?
          <TabContext value={tab}>
            <TabList onChange={(e, v) => setTab(v)}>
              <Tab label="Chart" value="chart" />
              <Tab label="History" value="history" />
            </TabList>
            <TabPanel value="chart"><ChartLine
              chartDataArray={[luxArray]}
              lineTitleArray={["lux"]}
              chartLabel={luxDurationArray}
              // chartTitle={"Lux Chart"}
              background="dark"
              displayLegend={false}
              legendPosition="right"
              xAxisTitle="Time[min]"
              yAxisTitleArray={["lux"]}
            /></TabPanel>
            <TabPanel value="history">
              <Timeline ref={ref}>
                <ViewportList viewportRef={ref} items={sensorDataLog}>
                  {(obj, key) => {
                    return (
                      <SensorHistoryItem key={key} data={obj} sensorObj={sensorObj} />
                    );
                  }}
                </ViewportList>
              </Timeline>
            </TabPanel>
          </TabContext>
          :

          <Timeline ref={ref}>
            <ViewportList viewportRef={ref} items={sensorDataLog}>
              {(obj, key) => {
                return (
                  <SensorHistoryItem key={key} data={obj} sensorObj={sensorObj} />
                );
              }}
            </ViewportList>
          </Timeline>

      }


    </div>
  );
}
