import { useContext } from "react";
import { SMContext } from "context/smContext";
import DivExist from "components/DivExist";
import LightIcon from "components/LightIcon";
import LightControlPanel from "./LightControlPanel";
import TestInProgressPanel from "./TestInProgressPanel";
import LightDataReportPanel from "./LightDataReportPanel";
import LightInfoPanel from "./LightInfoPanel";
import DivInline from "components/DivInline";
import { useAutoBackToRoot } from "hooks/generalHooks";
import ModalSM from "components/ModalSM";
import AirconPanel from "./AirconPanel";
import DeviceMongoModal from "components/DeviceMongoModal";
import AdminContainer from "components/AdminContainer";
import LightInfoSuperAdmin from "./LightInfoSuperAdmin";

export default function LightDetailModal() {
  const [state, dispatch] = useContext(SMContext);
  useAutoBackToRoot();
  const {
    userObj,
    activeSerial,
    lightObjAll,
    gatewayObjAll,
    openLightDetailModal,
    daliCtlObjAll
  } = state;
  let lightObj = lightObjAll[activeSerial];
  const { description, masterSerial, result, gatewayID, daliCtlID } = lightObj || {};
  const daliCtlObj = daliCtlObjAll[daliCtlID];

  const handleClose = () => {
    dispatch({ type: "CLOSED_LIGHT_DETAIL_MODAL" });
    dispatch({ type: "ALL_UNSELECTED" });
  };
  return (
    <ModalSM
      open={openLightDetailModal}
      onClose={handleClose}
      width="95vw"
      height="96vh"
      disableBottomClose
      modalTitle={`${description} [${activeSerial}]`}
      modalIcon={
        <DeviceMongoModal deviceObj={lightObj}>
          <LightIcon
            lightObj={lightObj}
            width={3.5}
            sizeUnit="vw"
            gatewayConnected={gatewayObjAll[gatewayID]?.gatewayConnected}
            disabledConnectStatus={masterSerial}
            daliCtlObj={daliCtlObj}
          />
        </DeviceMongoModal>
      }
    >
      <DivInline hide={lightObj?.enableAirconControl === 1}>
        {/* /////////////Information///////////////////////////////Information///////////////////// */}
        <div
          style={{
            width: "38vw",
            height: "82vh",
            margin: 5,
            overflowY: "scroll",
            border: "0.5px solid grey",
            borderRadius: "8px",
            padding: 12,
          }}
        >
          <LightInfoPanel />
          <DivExist show={userObj.level === 0 && !masterSerial}>
            <LightInfoSuperAdmin handleClose={handleClose} />
          </DivExist>
        </div>

        {/* /////////////Data & Report & Log/////////////////////Data & Report & Log/////////////////// */}
        <DivExist show={!masterSerial}>
          <div
            style={{
              width: "35vw",
              height: "82vh",
              margin: 5,
              overflowY: "scroll",
              border: "0.5px solid grey",
              borderRadius: "8px",
              padding: 12,
            }}
          >
            <LightDataReportPanel />
          </div>
        </DivExist>
        {/* ///////////////////Control Panel////////////////////Control Buttons//////////////////Control Buttons/////////////////*/}
        <DivExist show={userObj.level < 2 && !masterSerial}>
          <div
            style={{
              width: "25vw",
              height: "82vh",
              margin: 5,
              border: "0.5px solid grey",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: 10,
              overflowY: "auto",
            }}
          >
            {result === "started" || result === "init" ? (
              <TestInProgressPanel />
            ) : (
              <LightControlPanel serial={activeSerial} />
            )}
          </div>
        </DivExist>
      </DivInline>
      <DivExist show={lightObj?.enableAirconControl === 1}>
        <AirconPanel />
      </DivExist>
    </ModalSM>
  );
}
