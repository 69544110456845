import { useState, useContext, useEffect, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Box, Tabs, CircularProgress } from "@mui/material";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import {
    rebootGateway,
    updateGatewayProperty,
    updateGatewayFirmware,
    restartGatewayProgram,
    queryGatewayOneSetting,
} from "actions/gatewayActions";
import AdminContainer from "components/AdminContainer";
import { tspToDateTime, gatewayConnected } from "actions/generalActions";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import { ArrowForwardIos } from "@mui/icons-material";
import moment from "moment";
import DivCol from "components/DivCol";
import DivSpaceBetween from "components/DivSpaceBetween";
import BtnOpenUrlLink from "components/BtnOpenUrlLink";
import { openSnackbar, confirmWindow } from "actions/screenActions";
import General from "@ecoenghk/general";
import CopyDeviceToMapModal from "containers/map/modal/CopyDeviceToMapModal";
const gs = new General();

export default function GatewayInfoPanel() {
    const [state, dispatch] = useContext(SMContext);
    const { activeGatewayID, gatewayObjAll, mapObjAll, userObj, updateGwFirmwareObj } = state;
    const gatewayObj = gatewayObjAll[activeGatewayID];
    const {
        description,
        mapID,
        gatewayAliveTimeStamp,
        gatewayStartTimeStamp,
        diskStatus,
        cpuStatus,
        ramStatus,
        gatewayIP,
        gatewayMac,
        serverID,
        gwVersion,
        daliMasterID,
    } = gatewayObj || {};
    const [diskStatus_barValue, setDiskStatus_barValue] = useState(100);
    const [cpuStatus_barValue, setCpuStatus_barValue] = useState(100);
    const [ramStatus_barValue, setRamStatus_barValue] = useState(100);
    useEffect(() => {
        setDiskStatus_barValue(diskStatus ? diskStatus?.diskUsagePercent || 100 : 100);
        setCpuStatus_barValue(100 - (cpuStatus ? cpuStatus["10min"]?.idle || 0 : 0));
        setRamStatus_barValue(ramStatus ? ramStatus["10min"]?.usagePercent || 100 : 100);
    }, [diskStatus, cpuStatus, ramStatus]);
    const onDuration = useMemo(() => {
        const gwConnected = gatewayConnected(gatewayObj);
        if (!gwConnected) return "-";
        const diff = moment(gatewayAliveTimeStamp).diff(moment(gatewayStartTimeStamp));
        const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        return diffDays > 0 ? diffDays + " days" : diffHours + " hours";
    }, [gatewayObj]);
    return (
        <>
            <Typography variant="h6" align="center">
                General
            </Typography>
            <Divider10 />
            <SpaceBetweenDiv title="Gateway ID" data={activeGatewayID} />
            <SpaceBetweenDialogInput
                title="Gateway name"
                dialogTitle="Update gateway name"
                data={description || ""}
                handleSave={async (val) =>
                    await updateGatewayProperty(gatewayObj, { description: val }, true)
                }
            />
            <DivExist show={userObj.level < 2}>
                <SpaceBetweenDiv
                    title="In map"
                    data={
                        <div>
                            {(mapID || []).map((mid, k) => (
                                <Typography
                                    variant={(mapID || []).length > 1 ? "caption" : "body2"}
                                    display="block"
                                    align="right"
                                    key={k}
                                >
                                    {mapObjAll[mid]?.mapName}[{mid}]
                                </Typography>
                            ))}
                        </div>
                    }
                />
            </DivExist>
            <SpaceBetweenDiv
                title="Alive time"
                data={tspToDateTime(gatewayAliveTimeStamp)}
            />
            <AdminContainer>
                <SpaceBetweenDiv
                    title="On time"
                    data={onDuration}
                />
            </AdminContainer>
            <AdminContainer>
                <SpaceBetweenDiv
                    title="System Status"
                    data={
                        <DivInline>
                            <Box display='flex' justifyContent='center' alignItems='center' sx={{ position: "relative", marginLeft: "10px" }}>
                                <CircularProgress variant="determinate" value={cpuStatus_barValue} color={cpuStatus_barValue > 90 ? "error" : cpuStatus_barValue > 70 ? "warning" : "success"} />
                                <Typography position='absolute' variant="caption">{cpuStatus ? "Cpu" : "NaN"}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='center' alignItems='center' sx={{ position: "relative", marginLeft: "10px" }}>
                                <CircularProgress variant="determinate" value={ramStatus_barValue} color={ramStatus_barValue > 90 ? "error" : ramStatus_barValue > 70 ? "warning" : "success"} />
                                <Typography position='absolute' variant="caption">{ramStatus ? "Ram" : "NaN"}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='center' alignItems='center' sx={{ position: "relative", marginLeft: "10px" }}>
                                <CircularProgress variant="determinate" value={diskStatus_barValue} color={diskStatus_barValue > 90 ? "error" : diskStatus_barValue > 80 ? "warning" : "success"} />
                                <Typography position='absolute' variant="caption">{diskStatus ? "Disk" : "NaN"}</Typography>
                            </Box>
                        </DivInline>
                    }
                />
            </AdminContainer>
            <Divider10 />
            <Typography variant="h6" align="center">
                Network
            </Typography>
            <Divider10 />
            <DivInline justifyContent="space-between">
                <Typography>Gateway IP</Typography>
                <DivCol alignItems="flex-end">
                    {Object.keys(gatewayIP || {})
                        .sort((a, b) => a.localeCompare(b))
                        .map((conType, k) => (
                            <DivInline key={k} alignItems="center">
                                <Typography variant="caption">
                                    {`${gatewayIP[conType]}[${conType}]`}
                                </Typography>
                                <BtnOpenUrlLink url={`http://${gatewayIP[conType]}:3011`} />

                            </DivInline>
                        ))}
                </DivCol>
            </DivInline>
            <Divider10 />
            <DivInline justifyContent="space-between">
                <Typography>Mac address</Typography>
                <DivCol alignItems="flex-end">
                    {Object.keys(gatewayMac || {})
                        .sort((a, b) => a.localeCompare(b))
                        .map((conType, k) => (
                            <Typography
                                variant="caption"
                                key={k}
                            >{`${gatewayMac[conType]}[${conType}]`}</Typography>
                        ))}
                </DivCol>
            </DivInline>
            <Divider10 />
            {/* /////////////Other///////////////////////////////Other///////////////////// */}
            <Typography variant="h6" align="center">
                Other
            </Typography>
            <Divider10 />
            <SpaceBetweenDiv title="Server ID" data={serverID} />
            <Divider10 />
            <DivExist show={daliMasterID ? true : false}>
                <SpaceBetweenDiv
                    title="Dali Master ID"
                    data={daliMasterID}
                />
                <Divider10 />
            </DivExist>
            {/* <DivExist show={dtkAdd && gatewayObj.comType === "mesh"}>
                <SpaceBetweenDiv title="DTK channel" data={dtkChannel} />
                <Divider10 />
            </DivExist> */}

            <SpaceBetweenDiv
                title="Firmware"
                data={gwVersion?.replace("GWV", "")}
            />
            <Divider10 />
            <DivExist show={userObj.level < 2}>
                <SpaceBetweenButton
                    title="Check alive time"
                    btnContent={<ArrowForwardIos />}
                    btnSize="small"
                    onBtnClick={() => queryGatewayOneSetting(activeGatewayID, 0x0005, "queryAliveTimeStamp")}
                />
                <DivSpaceBetween>
                    <Typography>Check latest firmware</Typography>
                    {updateGwFirmwareObj?.latestVer ? (
                        <Typography>
                            Latest ver {updateGwFirmwareObj?.latestVer}
                        </Typography>
                    ) : (
                        <Button
                            onClick={async () => await queryGatewayOneSetting(activeGatewayID, 0x0003, "checkLatestGwFirmware")}
                            variant="outlined"
                            size="small"
                        >
                            <ArrowForwardIos />
                        </Button>
                    )}
                </DivSpaceBetween>
                <DivExist show={updateGwFirmwareObj?.toUpdate}>
                    <SpaceBetweenButton
                        title="Update firmware"
                        btnContent="update"
                        btnSize="small"
                        color="secondary"
                        onBtnClick={() => updateGatewayFirmware(gatewayObj)}
                    />
                </DivExist>
                <Divider10 />
                <SpaceBetweenDiv
                    hideComponent={userObj.level > 1}
                    title="Copy/Move to other map"
                    variant="outlined"
                    data={
                        <CopyDeviceToMapModal
                            deviceID={activeGatewayID}
                            deviceType="gateway"
                            deviceName={description}
                        />
                    }
                />
                <Divider10 />
                <SpaceBetweenButton
                    title="Soft restart gateway"
                    btnContent={<ArrowForwardIos />}
                    color="secondary"
                    variant="outlined"
                    btnSize="small"
                    marginTop={5}
                    marginBottom={5}
                    onBtnClick={async () => {
                        confirmWindow(dispatch, "Soft restart gateway?", async () => {
                            await restartGatewayProgram(gatewayObj);
                            openSnackbar(dispatch, "command sent to server", "info");
                        });
                    }}
                />
                <SpaceBetweenButton
                    title="Reboot gateway"
                    btnContent={<ArrowForwardIos />}
                    color="secondary"
                    variant="outlined"
                    btnSize="small"
                    marginTop={5}
                    marginBottom={5}
                    onBtnClick={async () => {
                        confirmWindow(
                            dispatch,
                            "Confirm reboot gateway?",
                            async () => {
                                await rebootGateway(gatewayObj);
                                openSnackbar(dispatch, "command sent to server", "info");
                            }
                        );
                    }}
                />
            </DivExist>
        </>
    )
} 
