import { useState } from "react";
import { Typography, Button } from "@mui/material";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import { mapAddSensor } from "actions/mapActions";
import { mgFindQuery } from "actions/mongoApiActions";
import General from "@ecoenghk/general";
import { alertWindow } from "actions/screenActions";
import CardSensor from "components/CardSensor";
import DivExist from "components/DivExist";
import DivCol from "components/DivCol";
import IconButtonBack from "components/IconButtonBack";
const gs = new General();

export default function AddSensorToMapPanel({
  handleClose,
  nameOfDevice,
  targetX,
  targetY,
  state,
  dispatch,
  modalSize
}) {
  const { activeMapID, mapObjAll } = state;
  const [input, setInput] = useState("");
  const [sensorObjArray, setSensorObjArray] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const mapSensorObj = mapObjAll[activeMapID]?.sensorObj || {};
  const searchDb = async () => {
    const query = {
      $or: [
        { dtkAdd: input },
        { sensorID: input },
        { wifiApMacAddress: input },
      ]
    }
    const objArray = await mgFindQuery("sensor", query);
    if (objArray.length > 0) {
      setSensorObjArray(objArray);
    } else {
      setErrorMsg(`ID/address ${input} not found`);
    }
  };
  const handleSave = async (sensorObj) => {
    const { sensorID } = sensorObj;
    if (!sensorID) {
      alertWindow(dispatch, "Invalid sensor info, add fail");
      return;
    }
    await mapAddSensor(activeMapID, sensorID, sensorObj, targetX, targetY);
    handleClose();
  };
  return (
    <>
      <DivInline hide={modalSize === "small"}>
        <IconButtonBack onBtnClick={handleClose} />
        <Typography variant="h6">Add {nameOfDevice} to map</Typography>
      </DivInline>
      <Divider10 />
      <SpaceBetweenInput
        title="ID / address / wifiApMacAddress"
        data={input}
        width="40%"
        onInput={(evt) => {
          setInput(evt.target.value);
          setErrorMsg("");
        }}
      />
      <Button fullWidth size="small" onClick={searchDb} variant="outlined" sx={{ margin: "1vw 0" }} disabled={!input}> Search</Button >
      {errorMsg && (
        <Typography sx={{ color: "red" }} align="right">
          {errorMsg}
        </Typography>
      )}

      <Divider10 />
      <DivInline justifyContent="center">
        {
          sensorObjArray.map((sensorObj, key) => {
            const { sensorID } = sensorObj || {};
            const isInMap = mapSensorObj[sensorID] && sensorObj?.mapID?.includes(activeMapID);
            return (
              <DivCol key={key}>
                <CardSensor
                  iconSize={modalSize === "small" ? 3 : 7}
                  iconSizeUnit="vh"
                  sensorObj={sensorObj}
                  handleSave={() => handleSave(sensorObj)}
                  disableClick={isInMap ? true : false}
                />
                <DivExist show={isInMap}>
                  <Typography color="error">Already in map</Typography>
                </DivExist>
              </DivCol>
            )
          })
        }
      </DivInline>
      {/* {sensorObj?.type && (
        <DivInline justifyContent="center">
          <SensorIcon
            width={4}
            sizeUnit="vw"
            type={sensorObj.type}
            status={0}
            disabledConnectStatus
            gang={sensorObj.type}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "2vw",
            }}
          >
            <Typography variant="caption">ID</Typography>
            <Typography variant="subtitle1">{sensorID}</Typography>
            <Typography variant="caption">Name</Typography>
            <Typography variant="subtitle1">{sensorObj?.sensorName}</Typography>
            <Typography variant="caption">Address</Typography>
            <Typography variant="subtitle1">{sensorObj?.zigbeeAdd}</Typography>
          </div>
        </DivInline>
      )}
      <Divider10 />
      <DivInline justifyContent="center">
        <IconButtonSave onBtnClick={handleSave} disabled={!sensorObj?.type} />
      </DivInline> */}
    </>
  );
}
