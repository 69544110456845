import { useState, useRef, useEffect } from "react";
import { Group } from "react-konva";
import KonvaLabel from "containers/map/device/KonvaLabel";
import MapIconLight from "./MapIconLight";
import { lightColorDark } from "actions/generalActions";
import { konvaShrinkAndBack } from "actions/konvaActions";
import { updateDevicePos } from "actions/mapActions";
import ShiftSelectCircle from "./ShiftSelectCircle";

export default function DeviceSlaveLight({ layerProps, x, y, serial, lightObj }) {
    const {
        mapScale,
        mapObjAll,
        activeMapID,
        selectedID,
        lightObjAll,
        enablePan,
        dispatch,
        layerRef,
        iconSize,
        mapLayer,
        socket,
        shiftSelectedArray,
        mapSize,
        layerScale,
        layerPos
    } = layerProps;
    const activeMapObj = mapObjAll[activeMapID];
    const [showID, setShowID] = useState(false);
    const [scale, setScale] = useState(1);
    const [shadEnabled, setShadEnabled] = useState(false);
    const [toRender, setToRender] = useState(true);
    const deviceRef = useRef(null);
    useEffect(() => {
        if (deviceRef.current) {
            const p = deviceRef.current.absolutePosition();
            if (p.x < 0 || p.x > mapSize.x || p.y < 0 || p.y > mapSize.y) setToRender(false);
            else setToRender(true);
        }
    }, [layerScale, layerPos])
    const handleDragStart = () => {
        setShadEnabled(true);
    };
    const handleDragEnd = async (e) => {
        e.evt.preventDefault();
        const unScaleX = e.target.x() / mapScale.x;
        const unScaleY = e.target.y() / mapScale.y;
        setShadEnabled(false);
        const newX = unScaleX > 1000 ? 970 : unScaleX < 0 ? 30 : unScaleX;
        const newY = unScaleY > 600 ? 570 : unScaleY < 0 ? 30 : unScaleY;
        await updateDevicePos(socket, activeMapID, "light", serial, newX, newY);
    };
    const selected = selectedID === serial ? true : false;
    const shiftSelected = (shiftSelectedArray || [])
        .map((obj) => obj.deviceID)
        .includes(serial);
    const { description, style, masterSerial, type } = lightObj || {};
    const masterLightObj = lightObjAll?.[masterSerial];
    const { currentData } = masterLightObj || {};
    const { onoffstatusA, onoffstatusL, lightLv, current, bypass } = currentData || {};
    let tooltipDescription = description;
    const iconBackground = activeMapObj?.iconBackground || "";
    function handleClickDevice(e) {
        //if (!disableClick) {
        e.evt.preventDefault();
        const layer = layerRef.current;
        const layerScale = layer.scaleX();
        setScale(layerScale);
        if (e.evt.shiftKey) {
            if (!shiftSelected) {
                dispatch({
                    type: "SHIFT_SELECTED_DEVICE",
                    payload: {
                        deviceID: serial,
                        deviceType: "light",
                        deviceObj: lightObj,
                    },
                });
            } else {
                dispatch({
                    type: "SHIFT_UNSELECTED_DEVICE",
                    payload: {
                        deviceID: serial,
                    },
                });
            }
        } else {
            dispatch({
                type: "DEVICE_SELECTED",
                payload: {
                    deviceID: serial,
                    deviceType: "light",
                    deviceObj: lightObj,
                    mapRightMode: "light",
                    x,
                    y,
                    layerScale,
                },
            });
        }
        konvaShrinkAndBack(deviceRef, 0.8, 0.15);
    }

    return (
        <>
            <Group
                x={x}
                y={y}
                ref={deviceRef}
                draggable={!enablePan}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >
                {toRender && (
                    <>
                        {showID && (
                            <>

                                <KonvaLabel
                                    x={0}
                                    y={iconSize * mapScale.y}
                                    text={tooltipDescription}
                                    fontSize={12}
                                    tagColor={lightColorDark}
                                    scale={scale}
                                />
                            </>
                        )}
                        <ShiftSelectCircle
                            shiftSelected={shiftSelected}
                            iconSize={iconSize}
                            scale={scale}
                        />
                        <MapIconLight
                            x={0}
                            y={0}
                            lightStyle={style}
                            iconSize={iconSize}
                            mapScale={mapScale}
                            onClick={handleClickDevice}
                            onMouseOver={(e) => {
                                const layer = layerRef.current;
                                const layerScale = layer.scaleX();
                                setScale(layerScale);
                                setShowID(true);
                                const container = e.target.getStage().container();
                                container.style.cursor = enablePan ? "pointer" : "move";
                            }}
                            onMouseLeave={(e) => {
                                setShowID(false);
                                const container = e.target.getStage().container();
                                container.style.cursor = enablePan ? "grab" : "default";
                            }}
                            onoffstatusL={onoffstatusL}
                            type={type}
                            lightLv={lightLv}
                            outline={selected}
                            shadEnabled={shadEnabled}
                            scale={scale}
                            iconBackground={iconBackground}
                            bypass={bypass}
                            disabledConnectStatus
                        />
                    </>)}
            </Group>

        </>
    );
}
