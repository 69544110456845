import { useState, useContext } from "react";
import { Typography, Button } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import { alertWindow } from "actions/screenActions";
import DivCol from "components/DivCol";
import { mgFindQuery } from "actions/mongoApiActions";
import { mapAddDaliCtl } from "actions/mapActions";
import CardDaliCtl from "components/CardDaliCtl";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import IconButtonBack from "components/IconButtonBack";


export default function AddDaliCtlToMapPanel({ handleClose, targetX, targetY, state, dispatch, modalSize }) {
  const { activeMapID, mapObjAll } = state;
  const [input, setInput] = useState("");
  const [daliCtlObjArray, setDaliCtlObjArray] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const mapDaliCtlObj = mapObjAll[activeMapID]?.daliCtlObj || {};
  const searchDb = async () => {
    const query = {
      $or: [
        { dtkAdd: input },
        { serial: input },
        { wifiApMacAddress: input },
      ]
    }
    const objArray = await mgFindQuery("daliCtl", query);
    if (objArray.length > 0) {
      setDaliCtlObjArray(objArray);
    } else {
      setErrorMsg(`ID/address ${input} not found`);
    }
  };
  const handleSave = async (daliCtlObj) => {
    const { daliCtlID } = daliCtlObj || {};
    if (!daliCtlID) {
      alertWindow(dispatch, "Invalid dali controller info, add fail");
      return;
    }
    await mapAddDaliCtl(activeMapID, daliCtlID, daliCtlObj, targetX, targetY);
    handleClose();
  };
  return (
    <>
      <DivInline hide={modalSize === "small"}>
        <IconButtonBack onBtnClick={handleClose} />
        <Typography variant="h6">Add Dali controller to map</Typography>
      </DivInline>
      <Divider10 space={5} />
      <SpaceBetweenInput
        title="ID / address / wifiApMacAddress"
        data={input}
        width="40%"
        onInput={(evt) => {
          setInput(evt.target.value);
          setErrorMsg("");
        }}
      />
      <Button size="small" fullWidth onClick={searchDb} variant="outlined" sx={{ margin: "1vw 0" }} disabled={!input}> Search</Button >
      {errorMsg && (
        <Typography sx={{ color: "red" }} align="right">{errorMsg}</Typography>
      )
      }
      <Divider10 space={5} />
      <DivInline justifyContent="center">
        {
          daliCtlObjArray.map((daliCtlObj, key) => {
            const { daliCtlID } = daliCtlObj || {};
            const isInMap = mapDaliCtlObj[daliCtlID] && daliCtlObj?.mapID?.includes(activeMapID);
            return (
              <DivCol key={key}>
                <CardDaliCtl
                  iconSize={modalSize === "small" ? 3 : 7}
                  iconSizeUnit="vh"
                  daliCtlObj={daliCtlObj}
                  handleSave={() => handleSave(daliCtlObj)}
                  disableClick={isInMap ? true : false}
                />
                <DivExist show={isInMap}>
                  <Typography color="error">Already in map</Typography>
                </DivExist>
              </DivCol>
            )
          })
        }
      </DivInline>
    </>
  );
}
