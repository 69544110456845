import { useState, useContext } from "react";
import { AddBox } from "@mui/icons-material";
import { Typography, Tooltip, IconButton } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import { useNavigate } from "react-router-dom";
import ModalSM from "components/ModalSM";
import { createMap } from "actions/mapActions";
import General from "@ecoenghk/general";
const gs = new General();

export default function AddMapModal() {
  const [state, dispatch] = useContext(SMContext);
  const navigate = useNavigate();
  const { mapObjAll, userObj, socket } = state;

  const [open, setOpen] = useState(false);
  const [entry, setEntry] = useState({});

  const handleCreateMap = async () => {
    const x = Number(entry.mapSizeInM?.x);
    const y = Number(entry.mapSizeInM?.y);
    console.log(entry);
    const mapID = await createMap(userObj, x, y, entry.mapName);
    gs.waitFor(3000);
    dispatch({
      type: "SET_ACTIVE_MAP",
      payload: mapID,
    });
    dispatch({ type: "SET_ENABLE_PAN", payload: true });
    dispatch({ type: "SET_ENABLE_ZOOM", payload: "" });
    navigate("/MapPage");
  };
  const editEntry = (field, value) => {
    const newEntry = { ...entry, [field]: value };
    setEntry(newEntry);
  };
  return (
    <>
      <IconButton onClick={() => setOpen(true)} size="large">
        <Tooltip placement="top" title={<Typography>Add map</Typography>}>
          <AddBox style={{ fontSize: "1.2em" }} />
        </Tooltip>
      </IconButton>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalTitle="Add Map"
        modalIcon={<AddBox />}
        width="60vw"
        height="50vh"
        onSave={handleCreateMap}
      // disabledSave={
      //   entry.mapName && entry.mapSizeInM?.x > 0 && entry.mapSizeInM?.y > 0
      //     ? false
      //     : true
      // }
      >
        <Divider10 />

        <SpaceBetweenInput
          title="New map name"
          data={entry.mapName || ""}
          width="40%"
          autoSelect
          onInput={(evt) => editEntry("mapName", evt.target.value)}
        />
        <SpaceBetweenInput
          title="Map width in meter"
          data={entry.mapSizeInM?.x || 0}
          width="40%"
          autoSelect
          onInput={(evt) => {
            const newX = evt.target.value;
            const sizeObj = { ...entry.mapSizeInM, x: newX };
            editEntry("mapSizeInM", sizeObj);
          }}
        />
        <SpaceBetweenInput
          title="Map height in meter"
          data={entry.mapSizeInM?.y || 0}
          width="40%"
          autoSelect
          onInput={(evt) => {
            const newY = evt.target.value;
            const sizeObj = { ...entry.mapSizeInM, y: newY };
            editEntry("mapSizeInM", sizeObj);
          }}
        />
      </ModalSM>
    </>
  );
}
