import { useContext } from "react";
import { SMContext } from "context/smContext";
import SkymonLogo from "asset/svgComp/SkymonLogo";
import { Typography, Box } from "@mui/material";
import DivInline from "components/DivInline";
import SkylightLogo from "asset/svgComp/SkylightLogo";
import { isTablet, isDesktop } from "react-device-detect";

export default function MapContainerBottom({ vw, vh }) {
  const [state] = useContext(SMContext);
  const { activeMapID, mapObjAll, selectedID, selectedDeviceType, selectedDeviceObj } = state;
  const { mapName } = mapObjAll[activeMapID] || {};
  return (
    <div
      style={{
        width: `${vw}vw`,
        height: `${vh}vh`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <>
        <DivInline>
          <Box sx={{ width: "27vh", height: "9vh" }} onClick={() => console.log(state)}>
            <SkylightLogo
              width={16}
              height={5}
              sizeUnit="vw"
              background="light"
            />
          </Box>
          <Typography
            sx={{ color: "darkgrey", marginLeft: "1vw", marginRight: "1vw" }}
          >
            powered by
          </Typography>
          <SkymonLogo width={8} sizeUnit="vh" background="light" />
        </DivInline>
        {isDesktop && (
          <Typography
            variant={"h2"}
            sx={{ color: "darkgrey", marginRight: "0.5vw" }}
          >
            {mapName}
          </Typography>
        )}
        {/* {isTablet && (
          <Typography>{selectedID}</Typography>
        )} */}
      </>
    </div>
  );
}
