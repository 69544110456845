import { useState, useContext } from "react";
import { Typography, Button } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import { mapAddLight } from "actions/mapActions";
import { mgFindQuery } from "actions/mongoApiActions";
import General from "@ecoenghk/general";
import { daliAddLight } from "actions/daliCtlActions";
import { alertWindow } from "actions/screenActions";
import CardLight from "components/CardLight";
import DivCol from "components/DivCol";
import DivExist from "components/DivExist";
import IconButtonBack from "components/IconButtonBack";
const gs = new General();
export default function AddLightToMapPanel({ handleClose, targetX, targetY, state, dispatch, modalSize }) {
  const { activeMapID, mapObjAll, daliCtlObjAll } = state;
  const [input, setInput] = useState("");
  const [lightObjArray, setLightObjArray] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const mapLightObj = mapObjAll[activeMapID]?.lightObj || {};
  const searchDb = async () => {
    const query = {
      $or: [
        { dtkAdd: input },
        { serial: input },
        { wifiApMacAddress: input },
      ]
    }
    const objArray = await mgFindQuery("serial", query);
    if (objArray.length > 0) {
      setLightObjArray(objArray);
    } else {
      setErrorMsg(`ID/address ${input} not found`);
    }
  };
  const handleSave = async (lightObj) => {
    const { serial, daliCtlID } = lightObj || {};
    if (!serial) {
      alertWindow(dispatch, "Invalid light info, add fail");
      return;
    }
    if (daliCtlID) {
      const daliCtlObj = daliCtlObjAll[daliCtlID];
      if (!daliCtlObj) {
        alertWindow(
          dispatch,
          "Light is under a dali controller that is not in this map, add fail"
        );
        return;
      }
      await daliAddLight(daliCtlObj, lightObj.shortAdd);
      await gs.waitFor(2000);
    }
    await mapAddLight(activeMapID, serial, lightObj, targetX, targetY);
    handleClose();
  };
  return (
    <>
      <DivInline hide={modalSize === "small"}>
        <IconButtonBack onBtnClick={handleClose} />
        <Typography variant="h6">Add Light to map</Typography>
      </DivInline>
      <Divider10 />
      <SpaceBetweenInput
        title="ID / address / wifiApMacAddress"
        titleVariant="body2"
        data={input}
        width="40%"
        onInput={(evt) => {
          setInput(evt.target.value);
          setErrorMsg("");
        }}
      />
      <Button fullWidth onClick={searchDb} variant="outlined" sx={{ margin: "1vw 0" }} disabled={!input} size="small"> Search</Button >
      {errorMsg && (
        <Typography sx={{ color: "red" }} align="right">
          {errorMsg}
        </Typography>
      )
      }

      <Divider10 space={5} />
      <DivInline justifyContent="center">
        {
          lightObjArray.map((lightObj, key) => {
            const { serial } = lightObj || {};
            const isInMap = mapLightObj[serial] && lightObj?.mapID?.includes(activeMapID);
            return (
              <DivCol key={key}>
                <CardLight
                  iconSize={modalSize === "small" ? 3 : 7}
                  iconSizeUnit="vh"
                  lightObj={lightObj}
                  handleSave={() => handleSave(lightObj)}
                  disableClick={isInMap ? true : false}
                />
                <DivExist show={isInMap}>
                  <Typography color="error">Already in map</Typography>
                </DivExist>
              </DivCol>
            )
          })
        }
      </DivInline>
    </>
  );
}
