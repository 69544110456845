import moment from "moment";
import { tify, sify } from "chinese-conv";
import { alertWindow } from "./screenActions";

export const gatewayColorDark = "#BA4A00";
export const gatewayColorLight = "#F6DDCC";
export const sensorColorDark = "#0E6655";
export const sensorColorLight = "#7DCEA0";
export const lightColorDark = "#1A5276";
export const lightColorLight = "#AED6F1";
export const zoneControlColorDark = "#9A7D0A";
export const zoneControlColorLight = "#F9E79F";
export const serverColorDark = "#1A5276";
export const serverColorLight = "#A9CCE3";
export const daliCtlColorDark = "#145A32";
export const daliCtlColorLight = "#ABEBC6";

export const printPdf = (
  mainTitle,
  subTitle,
  printBody,
  disableMainTitle,
  disableSubTitle,
  classStyles
) => {
  var mywindow = window.open("", "Print", "height=600,width=800");
  mywindow.document.write(
    `<html><head><title>${mainTitle}-${subTitle}</title>`
  );
  mywindow.document.write("<style>");
  mywindow.document.write(
    "table {margin:auto;width:100%;boarder: 1px solid grey;border-collapse:collapse}"
  );
  mywindow.document.write(
    "td,th {border: 1px solid grey;border-collapse: collapse;font-size:0.8em}"
  );
  mywindow.document.write(".noPrint {display:none;}");
  mywindow.document.write(".blue {color:blue;}");
  mywindow.document.write("</style>");
  mywindow.document.write("</head><body>");
  if (!disableMainTitle) {
    mywindow.document.write(`<h3>${mainTitle}</h3>`);
  }
  if (!disableSubTitle) {
    mywindow.document.write(`<h4>${subTitle}</h4>`);
  }
  mywindow.document.write(`<hr />`);
  mywindow.document.write(printBody);
  mywindow.document.write("</body></html>");
  mywindow.document.close();
  mywindow.focus();
  mywindow.print();
  mywindow.close();
  return true;
};
export const lightColorAt = (disabledStatus, statusL, lightLv) => {
  if (disabledStatus || lightLv === undefined || lightLv === null) return "transparent";
  if (statusL === 1) return "#FFF599";
  if (lightLv === 0) return "#b2babb";
  // lightColor = "#FFE600";
  return hexColorFromLightLevel(lightLv);
};
function interpolateColor(color1, color2, factor) {
  if (arguments.length < 3) {
    factor = 0.5;
  }
  const result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
  }
  return result;
}

function hexColorFromLightLevel(lightLevel) {
  const white = [255, 255, 255]; // RGB for white
  const targetColor = [255, 230, 0]; // RGB for #FFE600

  // Ensure lightLevel is within 0-100
  lightLevel = Math.max(0, Math.min(100, lightLevel));

  // Calculate interpolation factor
  const factor = lightLevel / 100;

  // Interpolate between white and targetColor
  const interpolatedColor = interpolateColor(white, targetColor, factor);

  // Convert RGB to hex
  const hexColor = interpolatedColor
    .map((c) => c.toString(16).padStart(2, '0'))
    .join('');

  return `#${hexColor}`;
}
export const sensorStringsOfType = (
  type,
  status,
  status2,
  status3,
  status4
) => {
  let sensorTypeName, statusTerm;
  switch (type) {
    case "motion": {
      sensorTypeName = "Motion sensor";
      statusTerm = status ? "Motion detected" : "No motion detected";
      break;
    }
    case "daylight": {
      sensorTypeName = "Daylight Sensor";
      statusTerm = `lux level ${status}`;
      break;
    }
    case "lightswitch": {
      sensorTypeName = "Light switch";
      statusTerm = status ? "Switch On" : "Switch Off";
      break;
    }
    case "daylightmotion": {
      sensorTypeName = "Daylight Motion Sensor";
      statusTerm = "lux level";
      break;
    }
    case "scenebutton": {
      sensorTypeName = "Scene button";
      statusTerm = status
        ? "Button1 pressed"
        : status2
          ? "Button2 pressed"
          : status3
            ? "Button3 pressed"
            : status4
              ? "Button4 pressed"
              : "";
      break;
    }


    case "dimcontroller": {
      sensorTypeName = "Dimmer Controller";
      statusTerm = status ? "Up" : status2 ? "Down" : "";
      break;
    }
    case "fire": {
      sensorTypeName = "General fire alarm";
      statusTerm = status ? "Fire Alarm" : "Normal";
      break;
    }
    case "breakglass": {
      sensorTypeName = "Fire breakglass unit";
      statusTerm = status ? "Alarm" : "Normal";
      break;
    }
    case "smoke": {
      sensorTypeName = "Smoke detector";
      statusTerm = status ? "Alarm" : "Normal";
      break;
    }
    case "nursecall": {
      sensorTypeName = "Nurse call button";
      statusTerm = status ? "Call" : "Normal";
      break;
    }
    default: {
      sensorTypeName = "Unknown sensor";
      statusTerm = status ? "Sensor On" : "Sensor Off";
    }
  }
  return { sensorTypeName, statusTerm };
};
export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
export function getSchBoxColorText(reportType) {
  let backgroundColor, tooltipText;
  switch (reportType) {
    case "check_6month": {
      backgroundColor = "#ffb3d9";
      tooltipText = "6-month test";
      break;
    }
    case "check_12month": {
      backgroundColor = "#ff6600";
      tooltipText = "Annual test";
      break;
    }
    case "check_36month": {
      backgroundColor = "#ff3300";
      tooltipText = "3-year test";
      break;
    }
    default: {
      backgroundColor = "#669999";
      tooltipText = "Monthly test";
    }
  }
  return { backgroundColor, tooltipText };
}
export const getConnectStatusOf = (lightObj) => {
  const { zigbeeConnected, timeStamp, comm } = lightObj || {};
  let status = "disconnected";
  const latestDataTimeFromNow = moment().diff(moment(timeStamp), "minutes");
  if (latestDataTimeFromNow > 15 && timeStamp) {
    status = "timeout";
  } else if (
    (zigbeeConnected || comm === "wifi" || latestDataTimeFromNow < 10) &&
    timeStamp
  ) {
    status = "connected";
  }
  return status;
};

// export const lineColor = (no) => {
//   const arr = [
//     "#7D3C98",
//     "#E74C3C",
//     "#28B463",
//     "#76D7C4",
//     "#AAB7B8",
//     "#F7DC6F",
//     "#BB8FCE",
//     "#E59866",
//     "#AAB7B8",
//     "#AAB7B8",
//     "#CD6155",
//     "#1ABC9C",
//     "#34495E",
//     "#8E44AD",
//     "#CB4335",
//     "#85C1E9",
//     "#76D7C4",
//     "#BB8FCE",
//     "#F7DC6F",
//     "#E59866",
//     "#AAB7B8",
//     "#CD6155",
//     "#1ABC9C",
//     "#34495E",
//     "#8E44AD",
//     "#CB4335",
//   ];
//   return arr[no];
// };
// export const getConnectStatus = (tsp) => {
//   const m = moment(tsp);
//   if (tsp === 0 || !tsp) {
//     return false;
//   }
//   if (moment().diff(m, "minutes") < 10) {
//     return true;
//   } else {
//     return false;
//   }
// };

// export function getZoneControlIconArray() {
//   return [
//     "finger",
//     "greenPwr",
//     "redPwr",
//     "greenPwrSq",
//     "redPwrSq",
//     "bluePwr",
//     "orangePwr",
//     "clock",
//     "dimColor",
//     "dimOrange",
//   ];
// }
export const secToMin = (secNum) => {
  if (!secNum) return "0s";
  let dur = moment.duration(secNum, "seconds");
  if (secNum < 60) {
    return `${secNum}s`;
  } else if (secNum >= 60 && secNum < 3600) {
    const min = dur.minutes();
    const sec = secNum % 60;
    let output = `${min}min `;
    if (sec > 0) output += `${sec}s`;
    return output;
  } else {
    const h = dur.hours();
    const min = dur.minutes();
    const sec = dur.seconds();
    let output = `${h}h `;
    if (min > 0 || sec > 0) output += `${min}min `;
    if (sec > 0) output += `${sec}s`;
    return output;
  }

};
export const darkColorArr = [
  "#1A5276",
  "#B9770E",
  "#B03A2E",
  "#515A5A",
  "#0E6655",
  "#5B2C6F",
];
export const tspToDateTime = (tsp, hideSecond) => {
  if (!tsp) return "-";
  if (hideSecond) {
    return moment(tsp).format("YYYY-MM-DD HH:mm");
  } else {
    return moment(tsp).format("YYYY-MM-DD HH:mm:ss");
  }
};
export const deviceConnected = (
  zigbeeConnected,
  timeStamp,
  gatewayConnected
) => {
  if (!gatewayConnected) {
    return false;
  } else if (
    zigbeeConnected &&
    moment().diff(moment(timeStamp), "minutes") < 10
  ) {
    return true;
  } else {
    return false;
  }
};
export const gatewayConnected = (gatewayObj) => {
  if (!gatewayObj) {
    return false;
  } else if (
    gatewayObj.gatewayConnected &&
    moment().diff(moment(gatewayObj.gatewayAliveTimeStamp), "minutes") < 10
  ) {
    return true;
  } else {
    return false;
  }

}
export async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}
export const isMatch = (s1, s2) => {
  if (!s1 || !s2) return false;
  return (
    s1?.toString().toUpperCase()?.includes(s2?.toUpperCase()?.toString()) ||
    s1?.toString().toLowerCase()?.includes(s2?.toLowerCase()?.toString()) ||
    s1?.includes(tify(s2 || "")) ||
    s1?.includes(sify(s2) || "") ||
    s1 === s2
  );
};
export const meshFreqMap = {
  [0b00]: "430-446MHz [0]",
  [0b01]: "454-469MHz [1]",
  [0b10]: "470-486MHz [2]",
  [0b11]: "494-509MHz [3]",
  [99]: "-"
}
export const meshSpeedMap = {
  0: "62.5 [0]",
  0x10: "37.5 [10]",
  0x20: "21.8 [20]",
  0x30: "12.5 [30]",
  0x40: "7 [40]",
  0x50: "3.9 [50]",
  0x60: "2.1 [60]",
  99: "-"
}
export const meshPowerDbmMap = {
  0: "22 [0]",
  1: "20 [1]",
  2: "17 [2]",
  3: "14 [3]",
  4: "11 [4]",
  5: "8 [5]",
  6: "5 [6]",
  7: "2 [7]",
  99: "-"
}
export const meshBaudrateMap = {
  0: "2400 [0]",
  1: "4800 [1]",
  2: "9600 [2]",
  3: "14400 [3]",
  4: "19200 [4]",
  5: "38400 [5]",
  6: "57600 [6]",
  7: "115200 [7]",
  99: "-"
}
export const meshChannelMap = { 0: "0", 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6", 7: "7", 8: "8", 9: "9", 10: "10", 11: "11", 12: "12", 13: "13", 14: "14", 15: "15", 16: "16", 17: "17", 18: "18", 19: "19", 20: "20", 21: "21", 22: "22", 23: "23", 24: "24", 25: "25", 26: "26", 27: "27", 28: "28", 29: "29", 30: "30", 31: "31", 99: "-" }

export const deviceInfoOfObj = (deviceObj) => {
  let deviceID = "";
  let deviceType = "";
  let deviceName = "";
  if (deviceObj?.serial && deviceObj?.style) {
    deviceID = deviceObj?.serial;
    deviceType = "light";
    deviceName = deviceObj?.description;
  } else if (deviceObj?.sensorID) {
    deviceID = deviceObj?.sensorID;
    deviceType = "sensor";
    deviceName = deviceObj?.sensorName;
  } else if (deviceObj?.zoneControlID && !deviceObj?.hour) {
    deviceID = deviceObj?.zoneControlID;
    deviceType = "zoneControl";
    deviceName = deviceObj?.zoneControlName;
  } else if (deviceObj?.gatewayID && deviceObj?.localserverUrl) {
    deviceID = deviceObj?.gatewayID;
    deviceType = "gateway";
    deviceName = deviceObj?.description;
  } else if (deviceObj?.daliCtlID && deviceObj?.daliType) {
    deviceID = deviceObj?.daliCtlID;
    deviceType = "daliCtl";
    deviceName = deviceObj?.description;
  } else if (deviceObj?.timeTableID && deviceObj?.hour) {
    deviceID = deviceObj?.timeTableID;
    deviceType = "timeTable";
    deviceName = "-";
  } else {
    deviceID = "unknown";
    deviceType = "unknown";
    deviceName = "unknown";
  }
  return { deviceID, deviceType, deviceName };
}
export const alertIfDisconnected = (deviceObj, state, dispatch) => {
  const { gatewayObjAll } = state;
  const deviceInfo = deviceInfoOfObj(deviceObj);
  let disconnected;
  if (deviceInfo.deviceType === "gateway") {
    disconnected = !gatewayConnected(deviceObj);
  } else {
    const gatewayConnected = gatewayObjAll[deviceObj.gatewayID]?.gatewayConnected;
    disconnected = !deviceConnected(deviceObj.zigbeeConnected, deviceObj.timeStamp, gatewayConnected);
  }
  if (disconnected) {
    alertWindow(dispatch, "Device is disconnected");
    return true;
  } else {
    return false;
  }
}
export const deviceInterfaceTypeOf = (deviceObj) => {
  if (!deviceObj) return "unknown";
  else if (deviceObj.zigbeeAdd) return "zigbee";
  else if (deviceObj.dtkAdd) {
    if (deviceObj.dtkChannel === undefined && Number(deviceObj.meshChannel >= 0)) return "mesh255";
    else if (Number(deviceObj.dtkChannel) >= 0) return "DTK";
    else return "DTK or mesh255";
  } else {
    return "unknownType";
  }
}
export const sensorHeadTypeNameOf = (code) => {
  switch (code) {
    case 0:
      return "multi-functional sensor head";
    case 1:
      return "pcf touch panel";
    case 2:
      return "esp touch panel";
    default:
      return "unknown";
  }
}
export const settingNumArrayOf = (type, gang = 1) => {
  switch (type) {
    case "motion":
    case "zcSceneButton":
      return [1];
    case "timer":
    case "zcSwitch":
      return [1, 2];
    case "scenebutton":
    case "dimcontroller":
      let arr1 = [];
      for (let i = 1; i <= gang; i++) {
        arr1.push(i);
      }
      return arr1;
    case "lightswitch":
      let arr2 = [];
      for (let i = 1; i <= gang * 2; i++) {
        arr2.push(i);
      }
      return arr2;
    case "daylight":
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    default:
      return [1];
  }
}
export const confirmAllGroupAction = (groupDeviceObj, settingNumArray) => {
  let allConfirmed = "success";
  let k = 0;
  settingNumArray.forEach((settingNum) => {
    const set = groupDeviceObj?.[`setting${settingNum}`] || {};
    const GAS_set = groupDeviceObj?.[`GAS_setting${settingNum}`];
    if (GAS_set) {
      k++;
      Object.keys(set).forEach(s => {
        const daliCtlID = set[s]?.daliCtlID;
        if (daliCtlID) {
          const confirmed = GAS_set[daliCtlID]?.confirmed || false;
          if (!confirmed) allConfirmed = "error";
        } else {
          const confirmed = GAS_set[s]?.confirmed || false;
          if (!confirmed) allConfirmed = "error";
        }
      });
    }
  });
  if (k === 0) allConfirmed = "disabled";
  return allConfirmed;
}