import { useContext, useState, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box, Button } from "@mui/material";
import DivInline from "components/DivInline";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
import IconButtonDelete from "components/IconButtonDelete";
import { deleteOneDeviceGroupAction } from "actions/gatewayActions";
import { confirmWindow } from "actions/screenActions";
import ZoneControlIcon from "components/ZoneControlIcon";
import AdminContainer from "components/AdminContainer";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import { zoneCtlDefault } from "asset/string/string";

const gs = new General();
const w = [8, 6, 8, 7, 7, 32, 32];
export default function SensorControlledByZoneControlModal({ sensorObj }) {
    const [state, dispatch] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const { zoneControlObjAll, userObj } = state;
    const { sensorID, zoneControlArray } = sensorObj;
    let modalTitle = `Sensor ${sensorID} controlled by zone control`;
    const zoneControlArrayAll = useMemo(() => {
        return Object.keys(zoneControlObjAll).filter(z => {
            const zoneControlObj = zoneControlObjAll[z];
            if (Object.values(zoneControlObj.sensorMap || {}).includes(sensorID)) return true;
            for (let i = 1; i < 3; i++) {
                if (Object.keys(zoneControlObj?.[`setting${i}`] || {}).includes(sensorID)) return true;
                if (Object.keys(zoneControlObj?.[`GAS_setting${i}`] || {}).includes(sensorID)) return true;
            }
            return false;
        });

    }, [zoneControlObjAll, sensorID]);
    const handleOpen = () => {
        if (userObj.level > 0) return;
        setOpen(true);
    }
    const handleDeleteGroupAction = async (zoneControlID, settingNum, command) => {
        const zcObj = zoneControlObjAll[zoneControlID];
        const zcType = zcObj?.type;
        const zcName = zoneCtlDefault[zcType]?.typeName;
        const groupID = zoneControlID.replace("zc_", "");
        confirmWindow(dispatch, `Delete group action of ${zcName}[${zoneControlID}] setting ${settingNum} inside ${sensorObj.type} sensor ${sensorID} command ${command}`, async () => {
            await deleteOneDeviceGroupAction(sensorObj.gatewayID, groupID, sensorID, "sensor", sensorObj.dtkAdd, [settingNum]);
        });
    }
    return (

        <>
            {
                (zoneControlArray || []).length === 0 ?
                    <Button onClick={handleOpen} variant="outlined" size="small">No zone control</Button>
                    :
                    <DivInline onClick={handleOpen}>
                        {
                            (zoneControlArray || []).map((s, key) => {
                                const zcObj = zoneControlObjAll[s];
                                return (
                                    <Box key={key} sx={{ margin: "0 0.3vw" }}>
                                        <ZoneControlIcon
                                            width={2}
                                            sizeUnit="vw"
                                            type={zcObj?.type}
                                            tooltip={s}
                                        />
                                    </Box>
                                )
                            })
                        }
                    </DivInline>
            }


            <AdminContainer>
                <ModalSM modalTitle={modalTitle}
                    open={open}
                    onClose={() => setOpen(false)}
                    height="80vh"
                    width="90vw"
                    disableBottomClose
                >
                    <Box sx={{ display: 'flex', borderBottom: "1px solid lightgrey" }}>
                        <Typography variant="caption" sx={{ width: `${w[0]}%` }}>zoneControlID</Typography>
                        <Typography variant="caption" sx={{ width: `${w[1]}%` }}>Type</Typography>
                        <Typography variant="caption" sx={{ width: `${w[2]}%` }}>Under sensor zoneControl Array</Typography>
                        <Typography variant="caption" sx={{ width: `${w[3]}%` }}>In sensorMap</Typography>
                        <Typography variant="caption" sx={{ width: `${w[4]}%` }}>Group action</Typography>
                        <Typography variant="caption" sx={{ width: `${w[5]}%` }}>In zoneControl settings</Typography>
                        <Typography variant="caption" sx={{ width: `${w[6]}%` }}>GAS command</Typography>
                    </Box>
                    {
                        zoneControlArrayAll.map((z, key) => {
                            const zoneControlObj = zoneControlObjAll[z];
                            let presentSetting = [], gasCmdArray = [], gasConfirmedArray = [];
                            for (let i = 1; i < 3; i++) {
                                const setting = zoneControlObj?.[`setting${i}`]?.[sensorID];
                                if (setting && !gs.isEmptyJson(setting)) presentSetting.push(i);
                                const gasSetting = zoneControlObj?.[`GAS_setting${i}`]?.[sensorID];
                                if (gasSetting && !gs.isEmptyJson(gasSetting)) {
                                    let command = gasSetting?.command;
                                    let confirmed = false;
                                    const cmdJson = gs.dtKStrToJson(command);
                                    let cmdMsg = gs.dtkActionCmdToMsg(cmdJson);
                                    const cmdObj = { settingNum: i, cmdMsg, command, commandCode: cmdJson?.commandCode };
                                    gasCmdArray.push(cmdObj);

                                }
                            }
                            return (
                                <Box key={key} sx={{ display: 'flex', borderBottom: "1px solid lightgrey" }}>
                                    <Typography variant="caption" sx={{ width: `${w[0]}%` }}> {z} </Typography>
                                    <Box sx={{ width: `${w[1]}%` }}>
                                        <ZoneControlIcon
                                            width={2}
                                            sizeUnit="vw"
                                            type={zoneControlObj?.type}
                                        />
                                    </Box>
                                    <Typography sx={{ width: `${w[2]}%` }}>{(sensorObj.zoneControlArray || []).includes(z) ? `\u{2705}` : `\u{274c}`}</Typography>
                                    <Typography sx={{ width: `${w[3]}%` }}>{Object.values(zoneControlObj.sensorMap || {}).includes(sensorID) ? `\u{2705}` : `\u{274c}`}</Typography>
                                    <Typography variant="caption" sx={{ width: `${w[4]}%` }}>{zoneControlObj.enableGroupAction ? `Enabled` : `Disabled`}</Typography>
                                    <Box sx={{ width: `${w[5]}%` }}>

                                        {
                                            (presentSetting).map((settingNum) => {
                                                const deviceSetting = zoneControlObj?.[`setting${settingNum}`]?.[sensorID];
                                                return (
                                                    <Box key={settingNum} sx={{ borderBottom: "1px solid grey" }}>
                                                        <Typography display="block" variant="caption">{`Setting${settingNum}`}</Typography>
                                                        <Typography display="block" variant="caption">{gs.dtkActionCmdToMsg(deviceSetting)}</Typography>
                                                        <Typography display="block" variant="caption">{`Cmd: ${gs.jsonCommandToDtk(deviceSetting)}`}</Typography>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                    <Box sx={{ width: `${w[6]}%` }}>
                                        {
                                            gasCmdArray.map((cmdObj, k) => {
                                                let msg = `Setting${cmdObj.settingNum}-Code:${cmdObj.commandCode}`;
                                                let settingObj = gs.dtKStrToJson(cmdObj.command);
                                                return (
                                                    <Box key={k} >
                                                        <DivInline>
                                                            <Typography variant="caption">{`Setting${cmdObj.settingNum}`}</Typography>
                                                            <GroupActionConfirmOneDevice deviceObj={zoneControlObj} controlledDeviceObj={sensorObj} settingNumArray={[cmdObj.settingNum]} />
                                                            <IconButtonDelete size="small" onBtnClick={async () => await handleDeleteGroupAction(z, cmdObj.settingNum, cmdObj.command)} />
                                                        </DivInline>
                                                        <Typography display="block" variant="caption">{gs.dtkActionCmdToMsg(settingObj)}</Typography>
                                                        <Typography display="block" variant="caption">{`Cmd: ${cmdObj.command}`}</Typography>
                                                    </Box>

                                                )
                                            })
                                        }
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </ModalSM>
            </AdminContainer>
        </>
    )
}