import { useState, useEffect, useRef } from "react";
import KonvaFaButton from "containers/map/device/KonvaFaButton";
import { Group, Rect, Text } from "react-konva";
import { gatewayColorDark } from "actions/generalActions";
import Tube from "asset/mapComp/Tube";
import SensorGeneral from "asset/mapComp/SensorGeneral";
import Gateway from "asset/mapComp/Gateway";
import ZcGeneral from "asset/mapComp/ZcGeneral";
import { confirmWindow } from "actions/screenActions";
import General from "@ecoenghk/general";
import AddDeviceDeviceInMapModal from "../modal/AddDeviceInMapModal";
import { Add } from "@mui/icons-material";
import DaliCtl from "asset/mapComp/DaliCtl";
const gs = new General();
export default function ToolbarMapRightClick({ layerProps }) {
    const {
        layerRef,
        mapObjAll,
        activeMapID,
        mapScale,
        iconSize,
        enablePan,
        mapRightClickPos,
        dispatch
    } = layerProps;
    const [scale, setScale] = useState(1);
    const [addDeviceType, setAddDeviceType] = useState(""); //light, daliCtl, sensor, gateway, zoneControl
    const activeMapObj = mapObjAll[activeMapID];
    const btnSize = activeMapObj.buttonSize / scale;
    const btnSpace = btnSize * 1.2;
    const { x, y, position } = mapRightClickPos || {};
    useEffect(() => {
        const layer = layerRef.current;
        const layerScale = layer.scaleX();
        setScale(layerScale);
    }, []);
    let btnContent = [
        {
            onClick: () => setAddDeviceType("light"),
            icon: <AddLightIcon x={0} y={0} size={btnSize} />,
            tooltip: "Add light",
        },
        {
            onClick: () => setAddDeviceType("daliCtl"),
            icon: <AddDaliCtlIcon x={0} y={0} size={btnSize} />,
            tooltip: "Add dali controller",
        },
        {
            onClick: () => setAddDeviceType("sensor"),
            icon: <AddSensorIcon x={0} y={0} size={btnSize} />,
            tooltip: "Add sensor/light switch",
        },
        {
            onClick: () => setAddDeviceType("gateway"),
            icon: <AddGatewayIcon x={0} y={0} size={btnSize} />,
            tooltip: "Add gateway",
        },
        {
            onClick: () => setAddDeviceType("zoneControl"),
            icon: <AddZoneControlIcon x={0} y={0} size={btnSize} />,
            tooltip: "Add vitrual button / timer",
        },
    ]
    const modalWidth = window.innerWidth * 0.28;
    const modalHeight = window.innerHeight * 0.35;
    const modalX = position?.includes("left") ? x : x - modalWidth / scale;
    const modalY = position?.includes("top") ? y + iconSize * mapScale.x + btnSpace * mapScale.x : y - modalHeight / scale - btnSpace * mapScale.x;
    const toolbarX = position?.includes("left") ? x : x - (btnContent.length * btnSpace * mapScale.x) + (iconSize * mapScale.x / scale);
    const toolbarY = position?.includes("top") ? y + (iconSize * mapScale.x) : y - (iconSize * mapScale.x / scale);
    return (
        <>
            <Rect fill="green" opacity={0.4} x={x} y={y} width={iconSize * mapScale.x} height={iconSize * mapScale.y} />
            <Group x={toolbarX} y={toolbarY}>
                {btnContent.map((obj, key) => (
                    <KonvaFaButton
                        key={key}
                        x={btnSpace * key}
                        y={0}
                        mapScale={mapScale}
                        enablePan={enablePan}
                        stageScale={scale}
                        btnSize={btnSize}
                        onBtnClick={obj.onClick}
                        iconSvg={obj.icon}
                        tooltip={obj.tooltip}
                        stroke={obj.stroke}
                        tagColor={gatewayColorDark}
                    />
                ))}
            </Group>
            <AddDeviceDeviceInMapModal
                x={modalX}
                y={modalY}
                modalWidth={modalWidth}
                modalHeight={modalHeight}
                layerProps={layerProps}
                open={addDeviceType ? true : false}
                onClose={() => {
                    setAddDeviceType("");
                    dispatch({
                        type: "ALL_UNSELECTED",
                    });
                }}
                addDeviceType={addDeviceType}
            />
        </>
    );
}

function AddLightIcon({ x, y, size }) {
    return (
        <Group x={x} y={y}>
            <Text text="+" fill="red" fontSize={size / 1.2} />
            <Tube x={0.3 * size} y={0.2 * size} size={size} shadEnabled={false} disabledStatus={true} />
        </Group>
    )
}
function AddDaliCtlIcon({ x, y, size }) {
    return (
        <Group x={x} y={y}>
            <Text text="+" fill="red" fontSize={size / 1.2} />
            <DaliCtl x={0.3 * size} y={0.3 * size} size={size} />
        </Group>
    )
}
function AddSensorIcon({ x, y, size }) {
    return (
        <Group x={x} y={y}>
            <Text text="+" fill="red" fontSize={size / 1.2} />
            <SensorGeneral x={0.3 * size} y={0.3 * size} size={size} />
        </Group>
    )
}
function AddGatewayIcon({ x, y, size }) {
    return (
        <Group x={x} y={y}>
            <Text text="+" fill="red" fontSize={size / 1.2} />
            <Gateway x={0.3 * size} y={0.3 * size} size={size} disabledConnectStatus={true} shadEnabled={false} />
        </Group>
    )
}
function AddZoneControlIcon({ x, y, size }) {
    return (
        <Group x={x} y={y}>
            <Text text="+" fill="red" fontSize={size / 1.2} />
            <ZcGeneral x={0.3 * size} y={0.2 * size} size={size * 0.8} />
        </Group>
    )
}