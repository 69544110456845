import { useState, useEffect } from "react";
import KonvaFaButton from "containers/map/device/KonvaFaButton";
import { Group } from "react-konva";
import { sensorColorDark } from "actions/generalActions";
import { confirmWindow, openSnackbar } from "actions/screenActions";
import Gateway from "asset/mapComp/Gateway";
import RubbishBin from "asset/mapComp/RubbishBin";
import Document from "asset/mapComp/Document";
import QuestionMark from "asset/mapComp/QuestionMark";
import { mapRemoveSensor, deviceToolbarPos } from "actions/mapActions";
import { gatewayDisconnectSensor } from "actions/gatewayActions";
import {
  sensorDisconnectAllLights,
  transferSettingToSensor,
  flashSensorHeadLED,
  requestSensorCurrentData,
} from "actions/sensorActions";
import General from "@ecoenghk/general";
import CopyIcon from "asset/mapComp/CopyIcon";
import PasteIcon from "asset/mapComp/PasteIcon";
import ZcSceneButton from "asset/mapComp/ZcSceneButton";
const gs = new General();
export default function ToolbarSensor({ layerProps }) {
  const {
    layerRef,
    socket,
    mapObjAll,
    activeMapID,
    mapScale,
    iconSize,
    selectedID,
    selectedDeviceObj,
    dispatch,
    enablePan,
    mapLayer,
    sensorObjAll,
    copiedDeviceObj,
    userObj
  } = layerProps;
  const [scale, setScale] = useState(2);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);
  const activeMapObj = mapObjAll[activeMapID];
  const { x, y } = activeMapObj?.sensorObj[selectedID] || {};
  const btnSize = activeMapObj.buttonSize / scale;
  const btnSpace = btnSize * 1.2;
  const { gatewayID, daliCtlID, serialMap, type, gang } =
    selectedDeviceObj || {};

  const enablePasteSetting =
    copiedDeviceObj.sensorID &&
      copiedDeviceObj.type === type &&
      copiedDeviceObj !== selectedID &&
      copiedDeviceObj.gang === gang
      ? true
      : false;

  const handleOpenDetail = () => {
    dispatch({
      type: "OPENED_SENSOR_DETAIL_MODAL",
      payload: selectedID,
    });
  };
  const handleChangeGateway = () => {
    const layerObj = {
      normal: false,
      sensor: true,
      gatewaySelect: true,
    };
    dispatch({
      type: "SET_MAP_LAYER",
      payload: layerObj,
    });
  };
  const handleRemove = async () => {
    confirmWindow(
      dispatch,
      `Confirm removing sensor ${selectedID} from map ${activeMapID}?`,
      async () => {
        await mapRemoveSensor(activeMapID, selectedID);
        await gs.waitFor(300);
        if (!daliCtlID && gatewayID) {
          confirmWindow(
            dispatch,
            "Disconnect sensor from gateway?",
            async () => {
              await gatewayDisconnectSensor(selectedDeviceObj);
              await gs.waitFor(300);
            },
            async () => { },
            async () => await handleDisconnectAllLights()
          );
        } else {
          await handleDisconnectAllLights();
        }
      }
    );
  };
  const handleFlashLED = async () => {
    await flashSensorHeadLED(selectedDeviceObj, 10);
  };
  const unselectAll = () => {
    dispatch({
      type: "ALL_UNSELECTED",
    });
  };
  const handleDisconnectAllLights = async () => {
    if (Object.keys(serialMap || {}).length > 0) {
      confirmWindow(
        dispatch,
        "Disconnect sensor from all lights",
        async () => {
          await sensorDisconnectAllLights(selectedID);
        },
        () => { },
        () => {
          unselectAll();
        }
      );
    } else {
      unselectAll();
    }
  };

  const handleCheckConnection = async () => {
    await requestSensorCurrentData(selectedDeviceObj, true);
  };
  const handleCopySetting = () => {
    dispatch({
      type: "SET_COPIED_DEVICE_OBJ",
      payload: selectedDeviceObj,
    });
    openSnackbar(dispatch, "Sensor settings copied");
  };
  const handlePasteSetting = async () => {
    confirmWindow(
      dispatch,
      `Confirm paste settings from zone control ID ${copiedDeviceObj.zoneControlID}?`,
      async () => {
        await transferSettingToSensor(
          copiedDeviceObj.sensorID,
          selectedID,
          sensorObjAll
        );
        openSnackbar(dispatch, "Setting pasted");
      }
    );
  };
  let btnContent = [
    {
      onClick: handleOpenDetail,
      icon: <Document x={0} y={0} size={btnSize} />,
      tooltip: "Sensor detail",
    },
  ];
  if (!daliCtlID) {
    btnContent = [
      ...btnContent,
      {
        onClick: handleChangeGateway,
        icon: <Gateway x={0} y={0} size={btnSize} disabledConnectStatus />,
        tooltip: "Change gateway",
        stroke: mapLayer.gatewaySelect ? "red" : "",
      },
    ];
  }
  btnContent = [
    ...btnContent,
    {
      onClick: handleRemove,
      icon: <RubbishBin x={0} y={0} size={btnSize} />,
      tooltip: "Remove from map",
    },
  ];
  if (!daliCtlID) {
    btnContent = [
      ...btnContent,
      {
        onClick: handleCheckConnection,
        icon: <QuestionMark x={0} y={0} size={btnSize} />,
        tooltip: "Check connection",
      },
    ];
  }
  if (userObj.level < 1) {
    btnContent = [
      ...btnContent,
      {
        onClick: handleCopySetting,
        icon: <CopyIcon x={0} y={0} size={btnSize} />,
        tooltip: "Copy setting",
      },
    ];
  }
  if (enablePasteSetting) {
    btnContent = [
      ...btnContent,
      {
        onClick: handlePasteSetting,
        icon: <PasteIcon x={0} y={0} size={btnSize} />,
        tooltip: "Paste setting",
      },
    ];
  }
  if (
    selectedDeviceObj.dtkAdd &&
    Number(selectedDeviceObj.deviceVersion) >= 1.02 &&
    type !== "scenebutton" &&
    type !== "lightswitch"
  ) {
    btnContent = [
      ...btnContent,
      {
        onClick: handleFlashLED,
        icon: <ZcSceneButton x={0} y={0} size={btnSize} />,
        tooltip: "Flash sensor head LED 10 seconds",
      },
    ];
  }
  const pos = deviceToolbarPos(x, y, btnContent.length, btnSpace, layerProps);
  return (
    <Group x={pos.x} y={pos.y}>
      {btnContent.map((obj, key) => (
        <KonvaFaButton
          key={key}
          x={btnSpace * key}
          y={0}
          mapScale={mapScale}
          enablePan={enablePan}
          stageScale={scale}
          btnSize={btnSize}
          onBtnClick={obj.onClick}
          iconSvg={obj.icon}
          tooltip={obj.tooltip}
          stroke={obj.stroke}
          tagColor={sensorColorDark}
        />
      ))}
    </Group>
  );
}
