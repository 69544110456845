import { useState, useEffect } from "react";
import KonvaFaButton from "containers/map/device/KonvaFaButton";
import { Group, Text } from "react-konva";
import {
  switchLight,
  setPower,
  switchLightDali,
  airconSwitchOn,
  airconSwitchOff,
  airconSetTemp,
  createSlaveLight,
  deleteSlaveLight,
  requestLightCurrentData
} from "actions/lightActions";
import { lightColorDark } from "actions/generalActions";
import LightBulb from "asset/mapComp/LightBulb";
import Gateway from "asset/mapComp/Gateway";
import PowerPlug from "asset/mapComp/PowerPlug";
import RubbishBin from "asset/mapComp/RubbishBin";
import Document from "asset/mapComp/Document";
import QuestionMark from "asset/mapComp/QuestionMark";
import { deviceToolbarPos, mapRemoveLight } from "actions/mapActions";
import { confirmWindow, promptWindow } from "actions/screenActions";
import ZcSceneButton from "asset/mapComp/ZcSceneButton";
import { blue, red } from "@mui/material/colors";
import DegreeCelsius from "asset/mapComp/DegreeCelsius";
import ControlPointDuplicate from "asset/mapComp/ControlPointDuplicate";
import General from "@ecoenghk/general";
import { daliQueryLightLevel } from "actions/daliCtlActions";
const gs = new General();
export default function ToolbarLight({ layerProps }) {
  const {
    layerRef,
    mapObjAll,
    activeMapID,
    mapScale,
    iconSize,
    selectedID,
    selectedDeviceObj,
    dispatch,
    gatewayObjAll,
    daliCtlObjAll,
    enablePan,
    userObj,
  } = layerProps;
  const [scale, setScale] = useState(2);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);
  const activeMapObj = mapObjAll[activeMapID];
  const { x, y } = activeMapObj?.lightObj[selectedID] || {};
  const btnSize = activeMapObj.buttonSize / scale;
  const btnSpace = btnSize * 1.2;
  const {
    type,
    dtkAdd,
    gatewayID,
    disableButton,
    daliCtlID,
    shortAdd,
    enableAirconControl,
    masterSerial,
  } = selectedDeviceObj || {};

  const handleSwitchLight = async (status) => {
    const lockLv = status === 0 ? 1 : 0;
    const pwm = status === 0 ? 100 : 0;
    if (!daliCtlID) {
      const gatewayObj = gatewayObjAll[gatewayID];
      await switchLight(
        selectedID,
        selectedDeviceObj,
        status,
        pwm,
        lockLv,
        gatewayObj
      );
    } else {
      const daliCtlObj = daliCtlObjAll[daliCtlID];
      await switchLightDali(selectedID, status, pwm, shortAdd, daliCtlObj);
    }
  };
  const handleSetPower = async (command) => {
    await setPower(selectedID, selectedDeviceObj, command);
  };
  const handleRemove = async () => {
    if (!masterSerial) {
      confirmWindow(
        dispatch,
        `Confirm removing light ${selectedDeviceObj.description} [${selectedID}] from map ${activeMapID}?`,
        async () => {
          await mapRemoveLight(activeMapID, selectedID);
          dispatch({ type: "ALL_UNSELECTED" });
        }
      );
    } else {
      confirmWindow(dispatch,
        `Confirm delete slave light ${selectedDeviceObj.description} [${selectedID}]?`,
        async () => {
          await deleteSlaveLight(selectedDeviceObj, activeMapObj);
          dispatch({ type: "ALL_UNSELECTED", });
        }
      );
    }
  };
  const handleOpenDetail = () => {
    dispatch({
      type: "OPENED_LIGHT_DETAIL_MODAL",
      payload: selectedID,
    });
  };
  const handleChangeGateway = () => {
    const layerObj = {
      normal: false,
      light: true,
      gatewaySelect: true,
    };
    dispatch({
      type: "SET_MAP_LAYER",
      payload: layerObj,
    });
  };
  const handleCheck = async () => {
    if (daliCtlID) {
      const daliCtlObj = daliCtlObjAll[daliCtlID];
      daliQueryLightLevel(daliCtlObj, selectedDeviceObj.shortAdd);
    } else {
      await requestLightCurrentData(selectedID, selectedDeviceObj, true);
    }
  };
  const handleAirconSetTemp = () => {
    promptWindow(
      dispatch,
      "Set temperature",
      (val) => {
        airconSetTemp(selectedDeviceObj, Number(val));
      },
      24
    );
  };
  const handleCreateSlave = () => {
    confirmWindow(dispatch, "Create slave light?", async () => {
      const deltaX = gs.randomInteger(20, 80);
      const deltaY = gs.randomInteger(20, 80);
      const lightX = x < 500 ? x + deltaX : x - deltaX;
      const lightY = y < 300 ? y + deltaY : y - deltaY;
      createSlaveLight(selectedDeviceObj, lightX, lightY, activeMapObj);
    });
  }
  let btnContent = [];
  if (type?.A && !masterSerial) {
    btnContent = [
      ...btnContent,
      {
        onClick: () => handleSwitchLight(0),
        icon: <LightBulb x={0} y={0} size={btnSize} onoff="on" />,
        tooltip: "Switch ON",
      },
      {
        onClick: () => handleSwitchLight(1),
        icon: <LightBulb x={0} y={0} size={btnSize} onoff="off" />,
        tooltip: "Switch OFF",
      },
    ];
  }
  if (type?.L) {
    btnContent = [
      ...btnContent,
      {
        onClick: () => handleSetPower(1),
        icon: <PowerPlug x={0} y={0} size={btnSize} cutResume="cut" />,
        tooltip: "Cut power /Battery discharge",
      },
      {
        onClick: () => handleSetPower(0),
        icon: <PowerPlug x={0} y={0} size={btnSize} cutResume="resume" />,
        tooltip: "Resume power /Battery charge",
      },
    ];
  }
  if (!daliCtlID && !masterSerial) {
    btnContent = [
      ...btnContent,
      {
        onClick: handleChangeGateway,
        icon: <Gateway x={0} y={0} size={btnSize} disabledConnectStatus />,
        tooltip: "Change gateway",
      },
    ];
  }
  if (enableAirconControl === 1) {
    btnContent = [
      ...btnContent,
      {
        onClick: () => airconSwitchOn(selectedDeviceObj),
        icon: <ZcSceneButton x={0} y={0} size={btnSize} status={1} />,
        tooltip: "AC ON",
      },
      {
        onClick: () => airconSwitchOff(selectedDeviceObj),
        icon: <ZcSceneButton x={0} y={0} size={btnSize} status={0} />,
        tooltip: "AC OFF",
      },
      {
        onClick: handleAirconSetTemp,
        icon: <DegreeCelsius x={0} y={0} color={blue[700]} size={btnSize} />,
        tooltip: "Set Temp",
      },
    ];
  }

  btnContent = [
    ...btnContent,
    {
      onClick: handleOpenDetail,
      icon: <Document x={0} y={0} size={btnSize} />,
      tooltip: `Details`,
    }
  ];

  btnContent = [
    ...btnContent,
    {
      onClick: handleRemove,
      icon: <RubbishBin x={0} y={0} size={btnSize} />,
      tooltip: "Remove from map",
    }
  ];
  if (!masterSerial) {
    btnContent = [
      ...btnContent,
      {
        onClick: handleCheck,
        icon: <QuestionMark x={0} y={0} size={btnSize} />,
        tooltip: daliCtlID ? "Check light level" : "Check connection",
      }
    ];
  }
  // if (!daliCtlID && zigbeeAdd) {
  //   btnContent = [
  //     ...btnContent,
  //     {
  //       onClick: checkRSSI,
  //       icon: <Rssi x={0} y={0} size={btnSize} />,
  //       tooltip: "Measure rssi",
  //     },
  //   ];
  // }
  if (!daliCtlID && dtkAdd && userObj?.level === 0) {
    btnContent = [
      ...btnContent,
      {
        onClick: handleCreateSlave,
        icon: <ControlPointDuplicate x={0} y={0} size={btnSize} />,
        tooltip: "Create slave light"
      }
    ];
  }

  const pos = deviceToolbarPos(x, y, btnContent.length, btnSpace, layerProps);
  return (
    <Group x={pos.x} y={pos.y}>
      <>
        {btnContent.map((obj, key) => (
          <KonvaFaButton
            key={key}
            x={btnSpace * key}
            y={0}
            mapScale={mapScale}
            enablePan={enablePan}
            stageScale={scale}
            btnSize={btnSize}
            onBtnClick={obj.onClick}
            iconSvg={obj.icon}
            tooltip={obj.tooltip}
            stroke={obj.stroke}
            tagColor={lightColorDark}
          />
        ))}
      </>
    </Group>
  );
}
