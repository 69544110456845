import React, { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, Box, IconButton } from "@mui/material";
import { Lock, TouchApp, Block, CheckCircleOutline, Workspaces } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import { purple, green } from "@mui/material/colors";
import SensorIcon from "components/SensorIcon";
import ModalSM from "components/ModalSM";
import { sensorManualExecuteSetting, sensorDisconnectLight } from "actions/sensorActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import SensorChangeLockLvModal from "./SensorChangeLockLvModal";
import General from "@ecoenghk/general";
import DivCol from "components/DivCol";
import DeviceIdAddGwLine from "components/DeviceIdAddGwLine";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import GroupActionConfirmOneGroup from "components/GroupActionConfirmOneGroup";
import IconButtonDelete from "components/IconButtonDelete";
import { mgFindOne } from "actions/mongoApiActions";
import { alertWindow, confirmWindow } from "actions/screenActions";
const gs = new General();

export default function ControlDevicesDaylightPanel({ show }) {
  const [state, dispatch] = useContext(SMContext);
  const {
    activeSensorID,
    sensorObjAll,
    lightObjAll,
    activeMapID,
    mapObjAll,
  } = state;
  const activeMapObj = mapObjAll[activeMapID];
  const sensorObj = sensorObjAll[activeSensorID];
  const { setting1, setting12 } = sensorObj || {};
  const deviceIDArrayAll = Object.keys(setting1 || {});

  const lightArray = deviceIDArrayAll
    .filter((s) => setting1[s]?.serial)
    .sort((a, b) => lightObjAll[a]?.description?.localeCompare(lightObjAll[b]?.description));
  const sensorArray = deviceIDArrayAll
    .filter((s) => setting1[s]?.sensorID)
    .sort((a, b) => sensorObjAll[a]?.sensorName?.localeCompare(sensorObjAll[b]?.sensorName));
  // const deviceIDArrayNotInMap = deviceIDArrayAll.filter(
  //   (s) => lightArray.includes(s) === false && sensorArray.includes(s) === false
  // );
  const settingNumArray = gs.newArrayBetween(1, 11);
  if (setting12 && !gs.isEmptyJson(setting12)) settingNumArray.push(12);
  if (!show) return null;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DivInline justifyContent="center">
        <Typography variant="h6" align="center">Control Devices</Typography>
        <ManualControlModal sensorObj={sensorObj} />
        <GroupActionConfirmOneGroup groupDeviceObj={sensorObj} settingNumArray={settingNumArray} />
      </DivInline>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>
          <Lock />
          Priority Level
        </Typography>
        <SensorChangeLockLvModal
          sensorObj={sensorObj}
          buttonNo={1}
          existingLv={sensorObj.lockLevelSet1}
          existingRelease={sensorObj.releaseOnDelayEnd1 || 0}
        />
      </DivSpaceBetween>
      <Divider10 />
      {lightArray
        .map((s) => {
          const lightObj = lightObjAll[s];
          return (
            <LightRow key={s} serial={s} lightObj={lightObj} sensorObj={sensorObj} />
          );
        })}
      <Divider10 />
      {sensorArray.map((s) => {
        const controlledSensorObj = sensorObjAll[s];
        return (
          <React.Fragment key={s}>
            <DeviceIdAddGwLine
              deviceID={s}
              address={controlledSensorObj?.dtkAdd}
              gatewayID={controlledSensorObj?.gatewayID}
              withIconAndName
              deviceObj={controlledSensorObj}
            />
            <DivInline>
              {
                gs.newArrayBetween(1, 11).map((n) => {
                  const setting = sensorObj[`setting${n}`];
                  const deviceSetting = setting?.[s];
                  const { commandCode, disabledSensor } = deviceSetting || {};
                  const l = n - 1;
                  let tooltipMsg = `Level ${l} ${l * 100}lux-${l * 100 + 99}lux`;
                  if (n === 11) tooltipMsg = "Level10  >1000lux";
                  if (disabledSensor) tooltipMsg += " - Disabled";
                  else tooltipMsg += " - Enabled"

                  return (
                    <Tooltip title={tooltipMsg} key={n}>
                      <Box sx={{ width: "3vw" }} key={n}>
                        <SensorIcon
                          type="daylight"
                          status={n - 1}
                          disabledConnectStatus
                          luxLv={l}
                        />
                        {
                          disabledSensor ?
                            <Block sx={{ color: purple[200] }} />
                            :
                            <CheckCircleOutline sx={{ color: green[300] }} />
                        }
                      </Box>
                    </Tooltip>
                  );
                })
              }

            </DivInline>
          </React.Fragment>
        )
      })}
      <Divider10 />
      {/* <DivExist show={deviceIDArrayNotInMap.length > 0}>
        <Divider10 />
        <Typography>Connected devices not in this map:</Typography>
        {deviceIDArrayNotInMap.map((s) => (
          <Typography variant="caption" key={s} display="block">{s}</Typography>
        ))}
      </DivExist> */}
    </div>
  );
}


function LightRow({ serial, lightObj, sensorObj }) {
  const [state, dispatch] = useContext(SMContext);
  const setting12 = sensorObj?.setting12;
  const settingNumQty = setting12 && !gs.isEmptyJson(setting12) ? 12 : 11;
  const serialSettingOnDisable = setting12?.[serial];
  const disablePwm = serialSettingOnDisable?.pwm;
  const lightDescription = lightObj ? lightObj?.description : "Unknown light";
  return (
    <div>
      <DivInline>
        <Typography variant="caption" sx={{ marginRight: "1vw" }}>{lightDescription}</Typography>
        <DivExist show={lightObj}>
          <GroupActionConfirmOneDevice deviceObj={sensorObj} controlledDeviceObj={lightObj} settingNumArray={gs.newArrayBetween(1, settingNumQty)} />
        </DivExist>
      </DivInline>
      <DivInline>
        <DeviceIdAddGwLine
          deviceID={serial}
          address={lightObj?.dtkAdd}
          gatewayID={lightObj?.gatewayID}
          deviceObj={lightObj}
        />
        <DivExist show={!lightObj ? true : false}>
          <Typography variant="caption" color="error">Light not in this map</Typography>
          <IconButtonDelete
            tooltip="Force disconnect"
            size="small"
            onBtnClick={async () => {
              confirmWindow(dispatch, "Force disconnect?", async () => {
                const obj = await mgFindOne("serial", "serial", serial);
                if (!obj) {
                  await gs.waitFor(1000);
                  alertWindow(dispatch, `Light ${serial} not found in database`);
                  return;
                }
                await sensorDisconnectLight(sensorObj, sensorObj.gatewayID, obj);
              });
            }}
          />
        </DivExist>
      </DivInline>
      <DivInline>
        {gs.newArrayBetween(1, 11).map((n) => {
          const setting = sensorObj[`setting${n}`];
          const lightSetting = setting?.[serial];
          const l = n - 1;
          let tooltipMsg = `Level ${l} ${l * 100}lux-${l * 100 + 99}lux`;
          if (n === 11) tooltipMsg = "Level10  >1000lux";
          if (lightSetting?.pwm === 0) tooltipMsg += " - light off";
          else tooltipMsg += ` - light ${lightSetting?.pwm}%`;
          if (lightSetting) {
            return (
              <Tooltip title={tooltipMsg} key={n}>
                <Box sx={{ width: "3vw", height: "6vh", display: "flex", flexDirection: "column", alignItem: "center" }} key={n}>
                  <SensorIcon
                    type="daylight"
                    status={n - 1}
                    disabledConnectStatus
                    luxLv={l}
                  />
                  <Typography variant="caption">
                    {lightSetting?.pwm === 0 ? "off" : `${lightSetting?.pwm}%`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          } else {
            return <React.Fragment key={n}></React.Fragment>;
          }
        })}
        <DivExist show={serialSettingOnDisable}>
          <Tooltip title="light level on sensor disable">
            <Box sx={{ width: "3vw", height: "6vh", display: "flex", flexDirection: "column", alignItem: "center" }}>
              <Block sx={{ color: purple[200], fontSize: "1.2rem", marginBottom: "0.6vh" }} />
              <Typography variant="caption" >{disablePwm === 0 ? "off" : `${disablePwm}%`}</Typography>
            </Box>
          </Tooltip>
        </DivExist>
      </DivInline>
      <Divider10 space={2} />
    </div>
  )
}

function ManualControlModal({ sensorObj }) {
  const modalTitle = "Manual execute light actions";
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(0);
  return (
    <>
      <Tooltip title={modalTitle}>
        <IconButton onClick={() => setOpen(true)} size="small">
          <TouchApp />
        </IconButton>
      </Tooltip>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalTitle={modalTitle}
        width="80vw"
      >
        <DivInline>
          {gs.newArrayBetween(1, 11).map((n) => (
            <DivCol
              key={n}
              alignItems="center"
              onClick={() => sensorManualExecuteSetting(sensorObj, n)}
              sx={{
                outline: `1px solid ${hover === n ? "yellow" : "grey"}`,
                margin: "0.5vw",
                height: "12vh",
                padding: "0.5vh",
              }}
              onMouseEnter={() => setHover(n)}
              onMouseLeave={() => setHover(0)}
            >
              <SensorIcon
                type="daylight"
                status={n - 1}
                disabledConnectStatus
              />
              <Typography align="center" variant="caption">{`simulate ${(n - 1) * 100
                }-${n * 100}lux`}</Typography>
            </DivCol>
          ))}
        </DivInline>
      </ModalSM>
    </>
  );
}
