import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import {
    Typography,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import DivInline from "components/DivInline";
import { SwipeRightAlt } from "@mui/icons-material";
import SensorIcon from "components/SensorIcon";
import {
    sensorDisconnectSensor,
    updateSensorProperty,
    sensorRemoveDeviceSetting,
    sensorConnectSensor,
} from "actions/sensorActions";
import { deviceModalPos } from "actions/mapActions";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import { confirmWindow } from "actions/screenActions";
import General from "@ecoenghk/general";
import DivCol from "components/DivCol";
import IconButtonAdd from "components/IconButtonAdd";
const gs = new General();

export default function SenSceneButtonSensorModal({
    open,
    handleClose,
    x,
    y,
    // modalLocation,
    layerProps,
    selectedSensor,
    sensorID,
    sensorObj,
}) {
    const { dispatch, sensorObjAll, iconSize, layerScale } = layerProps;
    const connectSensorObj = sensorObjAll[selectedSensor];
    const [optionMode, setOptionMode] = useState([0, 0, 0]);
    const { gang, gatewayID } = sensorObj || {};
    useEffect(() => {
        gs.newArrayBetween(1, gang || 2).forEach((n) => {
            const setting = sensorObj?.[`setting${n}`] || {};
            const obj = setting?.[selectedSensor];
            if (obj) {
                let arr = [...optionMode];
                arr[n - 1] = obj.commandCode;
                setOptionMode(arr);
            }

        });

    }, [selectedSensor, open]);
    const handleSave = async () => {
        let updateObj = {};
        for (let i = 0; i < gang; i++) {
            let setting = sensorObj?.[`setting${i + 1}`] || {};
            let obj = setting?.[selectedSensor];
            if (obj) {
                obj.commandCode = optionMode[i];
                setting[selectedSensor] = obj;
                updateObj[`setting${i + 1}`] = setting;
            }
        }
        await updateSensorProperty(sensorID, sensorObj, gatewayID, updateObj);
        handleClose();
    }
    const handleDisconnect = async () => {
        confirmWindow(
            dispatch,
            `Confirm disconnect sensor [${selectedSensor}]?`,
            async () => {
                await sensorDisconnectSensor(sensorObj, connectSensorObj);
                handleClose();
            }
        );
    };
    const modalWidth = window.innerWidth * 0.33;
    const modalHeight = window.innerHeight * 0.45;
    const pos = deviceModalPos(x, y, modalWidth, modalHeight, layerProps);
    return (
        <MapModal
            x={pos.x}
            y={pos.y}
            open={open}
            handleCloseModal={handleClose}
            width={modalWidth}
            height={modalHeight}
            layerProps={layerProps}
            disableBottomAction
        >
            <DivInline>
                <IconButtonClose
                    tooltip="Close dialog without saving"
                    onBtnClick={handleClose}
                />
                <DivCol>
                    <SensorIcon
                        type="scenebutton"
                        width={2.5}
                        sizeUnit="vw"
                        disabledConnectStatus
                        gang={gang}
                    />
                    <Typography variant="caption">{sensorID}</Typography>
                </DivCol>
                <SwipeRightAlt sx={{ fontSize: "2rem", margin: "0 2vw" }} />
                <DivCol>
                    <SensorIcon
                        type={connectSensorObj?.type}
                        width={2.5}
                        sizeUnit="vw"
                        disabledConnectStatus
                        gang={connectSensorObj?.gang}
                    />
                    <Typography variant="caption">{selectedSensor}</Typography>
                </DivCol>
            </DivInline>
            <Divider10 />
            {
                gs.newArrayBetween(1, gang || 2).map((n) => {
                    return (
                        <ButtonGroup
                            buttonNo={n}
                            key={n}
                            sensorObj={sensorObj}
                            connectSensorObj={connectSensorObj}
                            optionMode={optionMode}
                            onUpdate={(arr) => setOptionMode(arr)}
                            layerProps={layerProps}
                        />
                    )
                })
            }
            <Divider10 />
            <IconButtonClose
                tooltip="Close dialog without saving"
                onBtnClick={handleClose}
            />
            <IconButtonDone
                tooltip="Save settings and close dialog"
                onBtnClick={handleSave}
            />
            <IconButtonDelete
                tooltip="Disconnect from this scene button"
                onBtnClick={handleDisconnect}
            />
        </MapModal>
    )
}

function ButtonGroup({ buttonNo, sensorObj, connectSensorObj, onUpdate, optionMode, layerProps }) {
    const { dispatch } = layerProps;
    const connectSensorID = connectSensorObj?.sensorID;
    const sensorSetting = sensorObj?.[`setting${buttonNo}`]?.[connectSensorID];
    const delaySec = sensorSetting?.delaySec;
    let msgI = "ENABLE on button press";
    let msgII = "DISABLE on button press";
    if (delaySec > 0) {
        msgI += `, delay ${delaySec}s and then DISABLE`;
        msgII += `, delay ${delaySec}s and then ENABLE`;
    }
    return (
        <Box>
            <DivInline>
                <SensorIcon
                    type="scenebutton"
                    width={2}
                    sizeUnit="vw"
                    disabledConnectStatus
                    gang={sensorObj?.gang}
                    status={buttonNo === 1 ? 1 : 0}
                    status2={buttonNo === 2 ? 1 : 0}
                    status3={buttonNo === 3 ? 1 : 0}
                    status4={buttonNo === 4 ? 1 : 0}
                />
                <Typography>Button {buttonNo} </Typography>
                <DivExist show={sensorSetting}>
                    <IconButtonDelete tooltip={`Remove from button ${buttonNo}`}
                        onBtnClick={async () => {
                            confirmWindow(
                                dispatch,
                                `Remove ${connectSensorID} from button no ${buttonNo}?`,
                                async () => await sensorRemoveDeviceSetting(sensorObj, connectSensorObj, [buttonNo])
                            );
                        }}
                    />
                </DivExist>
                <DivExist show={!sensorSetting}>
                    <IconButtonAdd tooltip={`Connect ${connectSensorID} to button ${buttonNo}`}
                        onBtnClick={async () => {
                            confirmWindow(
                                dispatch,
                                `Connect ${connectSensorID} to button no ${buttonNo}?`,
                                async () => await sensorConnectSensor(sensorObj, connectSensorObj, buttonNo)
                            );
                        }}
                    />
                </DivExist>
            </DivInline>
            {
                sensorSetting ?
                    <RadioGroup
                        value={optionMode[buttonNo - 1]}
                        onChange={(e) => {
                            let arr = [...optionMode];
                            arr[buttonNo - 1] = Number(e.target.value);
                            // setOptionMode(arr);
                            onUpdate(arr);
                        }}
                    >
                        <ButtonSizeRadio
                            value={0x062c}
                            name={msgI}
                        />
                        <ButtonSizeRadio
                            value={0x062d}
                            name={msgII}
                        />
                    </RadioGroup>
                    :
                    <Typography sx={{ marginLeft: "2vw" }} variant="caption">Not connect to this button</Typography>
            }
            <Divider10 />
        </Box>
    )
}

function ButtonSizeRadio({ value, name }) {
    return (
        <FormControlLabel
            value={value}
            control={<Radio />}
            label={
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            background: "lightgrey",
                            marginRight: 5,
                        }}
                    />
                    <Typography variant="caption">{name}</Typography>
                </div>
            }
        />
    );
}
