import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import {
  Typography,
  Box,
  Tooltip,
  Slider,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import { CopyAll, FastForward, ArrowDownward, ArrowForward } from "@mui/icons-material";
import {
  sensorDisconnectSensor,
  updateSensorProperty,
} from "actions/sensorActions";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import { confirmWindow } from "actions/screenActions";
import DivCol from "components/DivCol";
import SensorMapModalHeader from "./SensorMapModalHeader";
import DeviceActionLine from "components/DeviceActionLine";
import SenNearFieldModal from "./SenNearFieldModal";
import { deviceModalPos } from "actions/mapActions";
const sxArrow = {
  outline: "1px solid #fff",
  margin: "1vh",
  padding: "0 0.5vw 0 0.5vw ",
};

export default function SenMotionSensorModal({
  open,
  handleClose,
  x,
  y,
  layerProps,
  selectedSensor,
  sensorID,
  sensorObj,
}) {
  const { dispatch, sensorObjAll, iconSize, layerScale, userObj } = layerProps;
  const [optionMode, setOptionMode] = useState(0);
  const [openNearField, setOpenNearField] = useState(false);
  const totalDelaySec = sensorObj.delaySec || sensorObj.motion_off_delay + (sensorObj.delaySec2 || sensorObj.motion_off_delay2 || 0);
  const sensorObjConnect = sensorObjAll[selectedSensor];
  useEffect(() => {
    const setting1 = sensorObj?.setting1 || {};
    const deviceSetting = setting1?.[selectedSensor] || {};
    if (deviceSetting && sensorObjConnect?.zigbeeAdd) setOptionMode(deviceSetting.commandMode);
    if (deviceSetting && sensorObjConnect?.dtkAdd) setOptionMode(deviceSetting.commandCode);
  }, [selectedSensor, open, sensorObj?.setting1]);
  const handleDisconnect = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect sensor [${selectedSensor}]?`,
      async () => {
        await sensorDisconnectSensor(sensorObj, sensorObjConnect);
        handleClose();
      }
    );
  };
  const handleSave = async () => {
    let newSetting1 = { ...(sensorObj.setting1 || {}) };
    let deviceSetting = newSetting1?.[selectedSensor] || {};
    if (sensorObjConnect.dtkAdd) {
      deviceSetting = { ...deviceSetting, commandCode: Number(optionMode), delaySec: totalDelaySec, delayTime: totalDelaySec, };
    }
    if (sensorObjConnect.zigbeeAdd) {
      deviceSetting = {
        ...deviceSetting,
        controlMode: "91",
        commandMode: Number(optionMode),
        delaySec: totalDelaySec,
        delayTime: totalDelaySec,
        sensorID: sensorObjConnect?.sensorID,
        zigbeeAdd: sensorObjConnect?.zigbeeAdd,
        gatewayID: sensorObjConnect?.gatewayID,
      };
    }
    newSetting1[selectedSensor] = deviceSetting;
    await updateSensorProperty(sensorID, sensorObj, sensorObj.gatewayID, { setting1: newSetting1 });
    handleClose();
  };
  const hasNearField = sensorObj?.nearFieldSetting?.[`${selectedSensor}_1_0`] ? true : false;
  const modalWidth = window.innerWidth * 0.35;
  const modalHeight = window.innerHeight * 0.35;
  const pos = deviceModalPos(x, y, modalWidth, modalHeight, layerProps);
  return (
    <>
      <MapModal
        x={pos.x}
        y={pos.y}
        open={open}
        handleCloseModal={handleClose}
        width={modalWidth}
        height={modalHeight}
        layerProps={layerProps}
        disableBottomAction
      >
        <SensorMapModalHeader
          sensorObj={sensorObj}
          deviceObj={sensorObjConnect}
          handleClose={handleClose}
          layerProps={layerProps}
        />
        <Divider10 />
        <RadioGroup
          value={optionMode}
          onChange={(e) => setOptionMode(e.target.value)}
        >
          {
            sensorObjConnect?.dtkAdd && (
              <>
                <FormControlLabel
                  value={0x062c}
                  control={<Radio />}
                  label={
                    <div style={{ marginLeft: "2vw" }}>
                      <DeviceActionLine deviceSetting={{ commandCode: 0x062c, delaySec: totalDelaySec }} mainDeviceObj={sensorObj} deviceObj={sensorObjConnect} disableClick />
                    </div>
                  }
                />
                <FormControlLabel
                  value={0x062d}
                  control={<Radio />}
                  label={
                    <div style={{ marginLeft: "2vw" }}>
                      <DeviceActionLine deviceSetting={{ commandCode: 0x062d, delaySec: totalDelaySec }} mainDeviceObj={sensorObj} deviceObj={sensorObjConnect} disableClick />
                    </div>
                  }
                />
              </>
            )
          }
        </RadioGroup>
        <Divider10 />
        <IconButtonClose
          tooltip="Close dialog without saving"
          onBtnClick={handleClose}
        />
        <IconButtonDone
          tooltip="Save settings and close dialog"
          onBtnClick={handleSave}
        />
        <IconButtonDelete
          tooltip="Disconnect light from sensor"
          onBtnClick={handleDisconnect}
        />
        {
          userObj?.level === 0 &&
          <Tooltip title="Save near field action to sensor">
            <IconButton
              onClick={() => setOpenNearField(true)}
            >
              <NearFieldIcon width={2} sizeUnit="vw" color={hasNearField ? "red" : "#fff"} />
            </IconButton>
          </Tooltip>
        }

      </MapModal>
      <SenNearFieldModal
        x={pos.x}
        y={pos.y}
        width={modalWidth}
        height={modalHeight}
        open={openNearField}
        handleClose={() => setOpenNearField(false)}
        layerProps={layerProps}
        sensorObj={sensorObj}
        deviceObj={sensorObjConnect}
        buttonNo={1}
      />
    </>
  );
}

function ButtonSizeRadio({ mode, name }) {
  return (
    <FormControlLabel
      value={mode}
      control={<Radio />}
      label={
        <div style={{ display: "flex" }}>
          <div
            style={{
              background: "lightgrey",
              marginRight: 5,
            }}
          />
          <Typography variant="body2">{name}</Typography>
        </div>
      }
    />
  );
}
