import { useContext, useRef, useState } from "react";
import { SMContext } from "context/smContext";
import MapTopToolbar from "./toolbar/MapTopToolbar";
import { Stage, Layer, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
import { useListenMapKeyPress, useListenWindowResize } from "hooks/mapHooks";
import LayerAll from "./layer/LayerAll";
import { useNavigate } from "react-router-dom";

export default function MapContainer({ vw, vhMap, vhToolbar }) {
  const [state, dispatch] = useContext(SMContext);
  const [tempDisablePan, setTempDisablePan] = useState(false);
  const navigate = useNavigate();
  const { activeMapID, mapObjAll, mapSize, enablePan, enableZoom } = state;
  const stageRef = useRef(null);
  const layerRef = useRef(null);
  const mapObj = mapObjAll[activeMapID] || {};
  const localMapUrl = `${global.ip}/img/${mapObj?.mapFileName}`;
  useListenMapKeyPress(layerRef);
  useListenWindowResize(vw, vhMap);
  const handleClickZoom = (e) => {
    e.evt.preventDefault();
    const scaleBy = 0.2;
    if (
      stageRef.current !== null &&
      layerRef.current !== null &&
      enableZoom !== ""
    ) {
      const stage = stageRef.current;
      const layer = layerRef.current;
      const oldScale = layer.scaleX();
      const newScale =
        enableZoom === "in" ? oldScale + scaleBy : oldScale - scaleBy;

      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - layer.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - layer.y() / oldScale,
      };
      layer.scale({ x: newScale, y: newScale });
      const newPos = {
        x:
          -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
          newScale,
        y:
          -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
          newScale,
      };
      layer.position(newPos);
      layer.batchDraw();
      stage.batchDraw();
      dispatch({
        type: "SET_LAYER_SCALE",
        payload: {
          layerScale: newScale,
          layerPos: newPos
        },
      });
    }
  };
  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.03;
    if (stageRef.current !== null && layerRef.current !== null) {
      const stage = stageRef.current;
      const layer = layerRef.current;
      const oldScale = layer.scaleX();
      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - layer.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - layer.y() / oldScale,
      };
      const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      layer.scale({ x: newScale, y: newScale });
      const newPos = {
        x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
      };
      layer.position(newPos);
      layer.batchDraw();
      stage.batchDraw();
      dispatch({
        type: "SET_LAYER_SCALE",
        payload: {
          layerScale: newScale,
          layerPos: newPos
        },
      });
    }
  };
  const handleDragLayer = (e) => {
    if (enablePan) {
      const layer = layerRef.current;
      const newPos = { x: e.target.x(), y: e.target.y() };
      layer.position(newPos);
      dispatch({
        type: "SET_LAYER_SCALE",
        payload: {
          layerScale: layer.scaleX(),
          layerPos: newPos
        },
      });

    }
  };
  const cursorOnLayer = () => {
    let c =
      enableZoom === "in"
        ? "zoom-in"
        : enableZoom === "out"
          ? "zoom-out"
          : enablePan
            ? "grab"
            : "default";
    return c;
  };
  const layerProps = {
    ...state,
    dispatch,
    stageRef,
    layerRef,
    navigate,
    iconSize: mapObjAll[activeMapID]?.iconSize,
    buttonSize: mapObjAll[activeMapID]?.buttonSize,
  };
  const handlePinch = (e) => {
    e.evt.preventDefault();
    if (stageRef.current !== null && layerRef.current !== null) {
      const stage = stageRef.current;
      const layer = layerRef.current;
      const oldScale = layer.scaleX();
      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      if (touch1 && touch2) {
        setTempDisablePan(true);
        // Calculate the current distance between the two touch points
        const currentDist = Math.sqrt(
          Math.pow(touch2.clientX - touch1.clientX, 2) +
          Math.pow(touch2.clientY - touch1.clientY, 2)
        );

        // Store the initial distance in a ref or state when the pinch starts
        if (!layer.initialDist) {
          layer.initialDist = currentDist;
        }

        // Calculate the scale factor based on the change in distance
        const scaleFactor = currentDist / layer.initialDist;

        // Adjust the scale factor to make zooming less sensitive
        const adjustedScaleFactor = 1 + (scaleFactor - 1) * 0.1; // Adjust the 0.1 to control sensitivity

        // Calculate the new scale
        const newScale = oldScale * adjustedScaleFactor;

        // Calculate the midpoint of the two touch points
        const mousePointTo = {
          x: (touch1.clientX + touch2.clientX) / 2 / oldScale - layer.x() / oldScale,
          y: (touch1.clientY + touch2.clientY) / 2 / oldScale - layer.y() / oldScale,
        };

        // Apply the new scale and position
        layer.scale({ x: newScale, y: newScale });
        const newPos = {
          x: -(mousePointTo.x - (touch1.clientX + touch2.clientX) / 2 / newScale) * newScale,
          y: -(mousePointTo.y - (touch1.clientY + touch2.clientY) / 2 / newScale) * newScale,
        };
        layer.position(newPos);
        layer.batchDraw();
        stage.batchDraw();
        dispatch({
          type: "SET_LAYER_SCALE",
          payload: {
            layerScale: newScale,
            layerPos: newPos
          },
        });
      }
    }
  };

  // Reset the initial distance when the touch ends
  const handleTouchEnd = () => {
    if (layerRef.current) {
      layerRef.current.initialDist = null;
      setTempDisablePan(false);
    }
  };
  const handleRightClick = (e) => {
    const layer = layerRef.current;
    const layPos = layer.position();
    console.log("RIGHT Click", e.evt, window.innerWidth, window.innerHeight);
    const layerScaleX = layer.scaleX();
    const layerScaleY = layer.scaleY();
    const x = (e.evt.layerX - layPos.x) / layerScaleX;
    const y = (e.evt.layerY - layPos.y) / layerScaleY;
    let position = "";
    if (e.evt.x > window.innerWidth / 2) position += "right";
    else position += "left";
    if (e.evt.y > window.innerHeight / 2) position += " bottom";
    else position += " top";
    dispatch({
      type: "SET_RIGHT_CLICK_POS",
      payload: {
        x: x,
        y: y,
        position: position
      }
    })
  }
  return (
    <div>
      <MapTopToolbar vw={vw} vh={vhToolbar} layerRef={layerRef} />
      <div
        style={{
          width: `${vw}vw`,
          height: `${vhMap}vh`,
          backgroundColor: "lightgrey",
          border: "1px solid lightgrey",
        }}
      >
        <Stage
          ref={stageRef}
          width={mapSize?.x || 300}
          height={mapSize?.y || 200}
          onContextMenu={(e) => e.evt.preventDefault()}
        >
          <Layer
            ref={layerRef}
            draggable={enablePan && !tempDisablePan}
            onDragEnd={handleDragLayer}
            onWheel={handleWheel}
            onTouchMove={handlePinch}
            onTouchEnd={handleTouchEnd}
            onMouseLeave={(e) => {
              const container = e.target.getStage().container();
              container.style.cursor = "default";
            }}
            onMouseEnter={(e) => {
              const container = e.target.getStage().container();
              container.style.cursor = cursorOnLayer();
            }}
          >
            <BackgroundImage
              width={mapSize?.x || 300}
              height={mapSize?.y || 200}
              mapUrl={mapObj?.mapFileName ? localMapUrl : mapObj.mapUrl}
              onClick={(e) => {
                if (e.evt.which === 3) {
                  handleRightClick(e);
                } else {
                  dispatch({ type: "ALL_UNSELECTED" });
                  handleClickZoom(e);
                }
              }}
              onTap={(e) => {
                dispatch({ type: "ALL_UNSELECTED" });
                handleClickZoom(e);
              }}
              onDblTap={(e) => {
                handleRightClick(e);
              }}
            />
            <LayerAll layerProps={layerProps} />
            {/* <Rect ref={refRect} width={100} height={100} x={800} y={0} fill="red"/> */}
            {/* <Text text={`mapSizeX: ${mapSize?.x}, mapScaleX:${mapScale.x?.toFixed(2)} scale:${layerProps.layerScale}`} x={400} y={300} onClick={() => alert("Clicked X")} /> */}
            {/* <Text text={`mapSizeY: ${mapSize?.y}, mapScaleY:${mapScale.y?.toFixed(2)} `} x={400} y={330} onClick={() => alert("Clicked Y")} listening={false} /> */}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}

const BackgroundImage = ({ width, height, mapUrl, onClick, onTap, onDblTap }) => {
  const [image] = useImage(mapUrl);

  return (
    <Image
      image={image}
      width={width}
      height={height}
      onClick={onClick}
      onTap={onTap}
      onDblTap={onDblTap}
    />
  );
};
