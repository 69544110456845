import { useState, useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, IconButton, Button } from "@mui/material";
import { Refresh, Summarize } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import Space10 from "components/Space10";
import DivExist from "components/DivExist";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import CurrentWithPolarity from "components/CurrentWithPolarity";
import ResultIcon from "components/ResultIcon";
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import PowerPlug from "asset/svgComp/PowerPlug";
import DetailHistoryModal from "./DetailHistoryModal";
import { LightHistoryItem } from "./LightHistoryItem";
import { mgFindByMatchAndRange } from "actions/mongoApiActions";
import DivInline from "components/DivInline";
import SingleReportModal from "containers/testReport/SingleReportModal";
import LightReportListModal from "containers/testReport/LightReportListModal";
import {
  fetchLightHistory,
  fetchOneLight,
  fetchOneReport,
} from "actions/lightActions";
import IconButtonRefresh from "components/IconButtonRefresh";
import LightIcon from "components/LightIcon";
import LightStatusPanel from "./LightStatusPanel";

export default function LightDataReportPanel() {
  const [state, dispatch] = useContext(SMContext);
  const { activeSerial, lightObjAll, userObj, daliCtlObjAll } = state;
  let lightObj = lightObjAll[activeSerial];
  const { type, currentData, reversePolarityI, timeStamp, reportObj } = lightObj || {};
  const { onoffstatusA, onoffstatusL, lightLv, voltage, lux, bypass } = currentData || {};
  let daliIndex;
  if (lightObj.daliCtlID) {
    const daliCtlObj = daliCtlObjAll[lightObj.daliCtlID];
    const addList = daliCtlObj?.addList || [];
    daliIndex = addList.findIndex(add => add === lightObj.shortAdd);
    lightObj.daliIndex = daliIndex;
  }
  const [dataLog, setDataLog] = useState([]);

  useEffect(() => {
    fetchSerialLog();
  }, []);
  const fetchSerialLog = async () => {
    let deviceID = lightObj.daliCtlID ? lightObj.daliCtlID : activeSerial;
    let arr = await fetchLightHistory(deviceID, moment().subtract(1, "hours").valueOf(), moment().valueOf());
    arr = arr
      .sort((a, b) => b.timeStamp - a.timeStamp)
      .filter(
        (obj) =>
          obj.uploadProtocol !== "gw_zigbeeConnected" &&
          obj.uploadProtocol !== "p0p0" &&
          obj.uploadProtocol !== "d2d2" &&
          obj.uploadProtocol !== "d3d3" &&
          obj.uploadProtocol !== "regular_serial"
      );
    if (lightObj.daliCtlID) {
      const lightDaliCmd = [0x0c03, 0x0c06, 0x0c08, 0x0c09, 0x0c0a, 0x0c0b, 0x0c0c, 0x0c0f, 0x0c10, 0x0c11, 0x0c12, 0x0c17, 0x0aa04, 0x0a07, 0x0a08, 0x0a09, 0x0a0a, 0x0a0b, 0x0a0c, 0x0a0d, 0x0a0f, 0x0a10, 0x0a11, 0x0a13, 0x0a14, 0x0a15, 0x0a16, 0x0a17, 0x0a18, 0x0a19, 0x0a1a, 0x0a1b, 0x0a2a, 0x0a33, 0x0a34, 0x0a35, 0x0a36, 0x0a37, 0x0a38, 0x0b02, 0x0b03, 0x0b04, 0x0b05, 0x060b, 0x0b07, 0x0b08, 0x0b09, 0x0b0a, 0x0b0b, 0x0b0c]
      arr = arr.filter(obj => lightDaliCmd.includes(obj.commandCode));
    }
    setDataLog(arr);
  };

  return (
    <div>
      <LightStatusPanel />
      {/* //////////////// report////////////////////// report////////////////////// report///////////		   */}
      <DivExist show={type?.batVI}>
        <Divider10 />
        <DivInline justifyContent="center">
          <Typography variant="h6">Latest Test report</Typography>
          <Space10 />
          <LightReportListModal />
          <IconButtonRefresh
            size="small"
            onBtnClick={async () => await fetchOneLight(dispatch, activeSerial)}
          />
        </DivInline>
        <Divider10 />
        <DivExist show={!reportObj?.reportID}>
          <Typography>No latest report available.</Typography>
        </DivExist>
        <DivExist show={reportObj?.reportID ? true : false}>
          <div style={{ width: "100%" }}>
            <>
              <SpaceBetweenDiv title="Test No" data={reportObj?.no} />
              <LatestReport reportID={reportObj?.reportID} />
              <SpaceBetweenDiv
                title="Report type"
                data={reportObj?.reportType}
                dataVariant="body2"
              />
              <SpaceBetweenDiv
                title="Test time"
                data={moment(reportObj?.schedule_timeStamp).format(
                  "YYYY-MM-DD, HH:mm"
                )}
                dataVariant="body2"
              />

              <SpaceBetweenDiv
                title="Result"
                data={<ResultIcon result={lightObj?.result} size="1.5em" />}
              />
            </>
          </div>
        </DivExist>
      </DivExist>
      {/* //////////////// slave lights //////////////////////  slave lights//////////////////////  slave lights///////////		   */}
      <DivExist show={lightObj?.slaveLightArray?.length > 0}>
        <Divider10 />
        <Typography variant="h6" align='center'>Slave lights</Typography>
        <Divider10 />
        <div style={{ width: "100%" }}>
          {lightObj?.slaveLightArray?.map((slaveSerial, key) => {
            const slaveLightObj = lightObjAll[slaveSerial];
            return (
              <LightIcon key={key} tooltip={slaveSerial} width={3} sizeUnit="vw" lightStyle={slaveLightObj?.style} />
            )
          })}
        </div>
      </DivExist>
      {/* //////////////data history//////////////////data history/////////////data history//////		   */}
      <DivExist show={userObj.level < 2}>
        <Divider10 />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">1-hour History</Typography>
          <DivExist show={userObj.level < 2}>
            <IconButton
              variant="outlined"
              size="small"
              onClick={fetchSerialLog}
            >
              <Refresh />
            </IconButton>
          </DivExist>

          <DetailHistoryModal />
        </div>
        <Divider10 />
        <div style={{ width: "100%" }}>
          <Timeline>
            {dataLog.map((data, key) => (
              <LightHistoryItem key={key} data={data} lightObj={lightObj} />
            ))}
          </Timeline>
        </div>
      </DivExist>
    </div>
  );
}

function LatestReport({ reportID }) {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const handleOpenModal = async () => {
    const reportObj = await fetchOneReport(reportID);
    dispatch({
      type: "SET_ACTIVE_REPORT",
      payload: reportObj,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: "SET_ACTIVE_REPORT",
      payload: {},
    });
  };
  return (
    <>
      <DivInline justifyContent="space-between">
        <Typography>Report ID</Typography>
        <Button
          variant="outlined"
          onClick={handleOpenModal}
          size="small"
          startIcon={<Summarize />}
        >
          {reportID}
        </Button>
      </DivInline>
      <SingleReportModal open={open} onClose={handleClose} />
    </>
  );
}
