import { useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, MenuItem } from "@mui/material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import DialogSelect from "components/DialogSelect";
import LightFunction from "components/LightFunction";
import DivInline from "components/DivInline";
import { lightStyleMenu } from "asset/string/string";
import LightIcon from "components/LightIcon";
import LightInfoDimPanel from "./LightInfoDimPanel";
import LightInfoBatPanel from "./LightInfoBatPanel";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import {
  updateSerialProperty,
  restartLight,
  lightQueryMacAddress,
  changeOneLightSetting,
  queryOneLightSetting,
} from "actions/lightActions";
import AdminContainer from "components/AdminContainer";
import ModalRemarks from "components/ModalRemarks";
import DivSpaceBetween from "components/DivSpaceBetween";
import CopyDeviceToMapModal from "containers/map/modal/CopyDeviceToMapModal";
import General from "@ecoenghk/general";
import { confirmWindow } from "actions/screenActions";
import DeviceUpdateFirmwareModal from "containers/server/DeviceUpdateFirmwareModal";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import LightControlledSensorModal from "./LightControlledBySensorModal";
import LightControlledZoneControlModal from "./LightControlledByZoneControlModal";
const gs = new General();

export default function LightInfoPanel() {
  const [state, dispatch] = useContext(SMContext);

  const {
    userObj,
    activeSerial,
    lightObjAll,
    sensorObjAll,
    zoneControlObjAll,
    mapObjAll,
  } = state;
  const lightObj = lightObjAll[activeSerial];
  const {
    description,
    gatewayID,
    mapID,
    type,
    lightModel,
    lightPower,
    nomBatV,
    macArray,
    daliCtlID,
    shortAdd,
    dtkAdd,
    masterSerial,
  } = lightObj || {};
  const handleUpdateLightProps = async (updateObj) => {
    await updateSerialProperty(activeSerial, lightObj, updateObj);
  };
  return (
    <>
      {/* //////////////General///////////////////////General/////////////////////General/////////////////////General////////////// */}
      <Typography variant="h6" align="center">
        General
      </Typography>
      <Divider10 />
      <div style={{ width: "100%" }}>
        <SpaceBetweenDiv title="Serial no" data={activeSerial} />
        <SpaceBetweenDialogInput
          title="Description"
          data={description || ""}
          dialogTitle="Change light description"
          handleSave={async (val) =>
            await handleUpdateLightProps({ description: val })
          }
          showValue
        />
        <AdminContainer>
          <SpaceBetweenDiv
            title="Light style"
            dataColor="white"
            data={
              <DivInline>
                <Typography>{lightObj?.style}</Typography>
                <LightIcon
                  lightStyle={lightObj?.style}
                  width={30}
                  disabledConnectStatus
                  disabledControlL
                  disabledStatus
                />
                <DialogSelect
                  title="Edit light style"
                  menuObj={{}}
                  handleSave={async (newStyle) =>
                    handleUpdateLightProps({ style: newStyle })
                  }
                  initialVal={lightObj?.style || "tube"}
                >
                  {Object.keys(lightStyleMenu)?.map((st) => (
                    <MenuItem key={st} value={st} style={{ textAlign: "right" }}>
                      <DivInline>
                        <Typography display="inline">{lightStyleMenu[st]}</Typography>
                        <LightIcon
                          lightStyle={st}
                          width={30}
                          disabledConnectStatus
                          lightLv={50}
                        />
                      </DivInline>
                    </MenuItem>
                  ))}
                </DialogSelect>
              </DivInline>
            }
          />
        </AdminContainer>
        <SpaceBetweenDialogInput
          title="Light model"
          data={lightModel || ""}
          dialogTitle="Change light model"
          handleSave={async (val) =>
            await handleUpdateLightProps({ lightModel: val })
          }
          showValue
        />
        <SpaceBetweenDialogInput
          title="Light rated power"
          data={lightPower || 0}
          dialogTitle="Change light rated power(W)"
          handleSave={async (val) => {
            const updateObj = {
              lightPower: Number(val),
              minIRequired: (val / nomBatV || 12) * 0.3,
              nomBatI: (val / nomBatV || 12) * 0.4,
            };
            await handleUpdateLightProps(updateObj);
          }}
          showValue
          showValueUnit="W"
        />
        <Divider10 />

        <SpaceBetweenDiv title="Gateway" data={gatewayID} hideComponent={masterSerial} />
        <Divider10 />
        <SpaceBetweenDiv
          title="Functions"
          data={<LightFunction serial={activeSerial} />}
          hideComponent={masterSerial}
        />
        <Divider10 />
        <DivExist show={masterSerial}>
          <SpaceBetweenDiv title="Master light serial no" data={masterSerial} />
        </DivExist>
        <DivExist show={dtkAdd}>
          <SpaceBetweenDiv title="Address" data={dtkAdd} hideComponent={masterSerial} />
        </DivExist>
        <DivExist show={daliCtlID}>
          <SpaceBetweenDiv
            title="Connected to Dali controller ID"
            data={daliCtlID}
          />
          <SpaceBetweenDiv title="Dali short address" data={shortAdd} />
        </DivExist>
        <Divider10 />
        <SpaceBetweenDiv
          title="In Map"
          data={
            <div>
              {mapID
                ?.filter((id) => Object.keys(mapObjAll).includes(id))
                ?.map((id, key) => (
                  <Typography
                    align="right"
                    display="block"
                    variant="caption"
                    key={key}
                  >
                    {mapObjAll[id]?.mapName} [{id}]
                  </Typography>
                ))}
            </div>
          }
        />

        <Divider10 />
        <DivSpaceBetween>
          <Typography>Controlled by Sensor</Typography>
          <LightControlledSensorModal lightObj={lightObj} />
        </DivSpaceBetween>
        <Divider10 />
        <DivSpaceBetween>
          <Typography>Controlled by zone control</Typography>
          <LightControlledZoneControlModal lightObj={lightObj} />
        </DivSpaceBetween>
        <DivExist show={type?.pwm && !daliCtlID}>
          <LightInfoDimPanel />
        </DivExist>
      </div>

      {/* ////////////////Battery  parameters//////////Battery  parameters//////////Battery  parameters////// */}
      <LightInfoBatPanel />

      {/* ///////////////Network//////////////////////////////Network/////////////////////////////Network/////////////////////		   */}
      <DivExist show={!daliCtlID && !masterSerial}>
        <Divider10 />
        <Typography variant="h6" align="center">
          Network
        </Typography>
        <Divider10 />
        <SpaceBetweenDiv
          title="Mac address"
          hideComponent={!dtkAdd}
          handleRefresh={() => lightQueryMacAddress(lightObj)}
          data={gs.macStringOf(macArray)}
        />
      </DivExist>

      {/* ///////////////Other//////////////////////////////Other/////////////////////////////Other/////////////////////		   */}
      <Divider10 />
      <Typography variant="h6" align="center">
        Other
      </Typography>
      <Divider10 />
      <SpaceBetweenCheckbox
        title="Brighten to 100% when bypass is ON"
        data={lightObj?.cutDimOnBypass ? true : false}
        onCheck={async (e) => await changeOneLightSetting(lightObj, 0x0214, { cutDimOnBypass: e.target.checked ? 1 : 0 })}
        hide={!type?.A || daliCtlID || masterSerial}
        handleRefresh={() => queryOneLightSetting(lightObj, 0x0309, null, userObj.uid)}
      />
      {/* <div style={{ width: "100%" }}> */}
      {/* <DivExist show={type?.batVI && level < 1}>
          <SpaceBetweenCheckbox
            title="Enable weekly test"
            data={enableWeekTest ? true : false}
            onCheck={async (e) =>
              await handleUpdateLightProps({ enableWeekTest: e.target.checked })
            }
          />

          <DivExist show={enableWeekTest}>
            <SpaceBetweenSelect
              title="Weekly Test weekday"
              data={weekTestWeekday ?? 0}
              menuObj={weekDayIsoMenu}
              onChange={async (e) => {
                await handleUpdateLightProps({
                  weekTestWeekday: Number(e.target.value),
                });
              }}
            />
            <SpaceBetweenSelect
              title="Weekly Test hour"
              data={weekTestHour ?? 0}
              menuObj={Object.fromEntries(hourMenu?.map((h) => [h, h]))}
              onChange={async (e) => {
                await handleUpdateLightProps({
                  weekTestHour: Number(e.target.value),
                });
              }}
            />
            <SpaceBetweenSelect
              title="Weekly Test minute"
              data={weekTestMin ?? 0}
              menuObj={minuteMenu}
              onChange={async (e) => {
                await handleUpdateLightProps({
                  weekTestMin: Number(e.target.value),
                });
              }}
            />
          </DivExist>
          <SpaceBetweenButton
            variant="contained"
            title="Transfer schedule/email setting/report from other light"
            btnContent="Transfer"
            onBtnClick={() => openDialog("TransferSerialDialog")}
            color="secondary"
          />
        </DivExist> */}
      <DivExist show={!masterSerial && !daliCtlID}>
        <DeviceUpdateFirmwareModal deviceObj={lightObj} />
      </DivExist>

      <DivSpaceBetween style={{ marginBottom: "1vh" }}>
        <Typography>Remarks</Typography>
        <ModalRemarks
          currentRemarks={lightObj?.remarks}
          handleSave={async (r) => {
            await handleUpdateLightProps({ remarks: r });
          }}
          closeOnSave
        />
      </DivSpaceBetween>
      <SpaceBetweenButton
        title="Restart light"
        btnContent="Restart"
        variant="outlined"
        color="secondary"
        marginBottom={5}
        onBtnClick={() => {
          confirmWindow(dispatch, "Confirm restart light?", () => {
            restartLight(activeSerial, lightObj);
          });
        }}
        hideComponent={daliCtlID || userObj.level >= 2 || masterSerial}
      />

      <SpaceBetweenDiv
        title="Copy/Move to other map"
        variant="outlined"
        data={
          <CopyDeviceToMapModal
            deviceID={activeSerial}
            deviceType="light"
            deviceName={description}
          />
        }
      />
    </>
  );
}

