import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Tooltip, Box, Select, MenuItem } from "@mui/material";
import {
    mapAddManyDevices,
    mapRemoveManyDevices
} from "actions/mapActions";
import { ArrowForwardIos, ContentCopy, DriveFileMove } from "@mui/icons-material";
import ModalSM from "components/ModalSM";
import DivCol from "components/DivCol";
import DivInline from "components/DivInline";
import DivExist from "components/DivExist";
import {
    alertWindow,
    confirmWindow,
    showBigProgress,
    hideBigProgress,
} from "actions/screenActions";
import General from "@ecoenghk/general";
import Divider10 from "components/Divider10";
import AdminContainer from "components/AdminContainer";
import { getDocsWhereFS } from "actions/firebaseMgActions";
import { mgPost } from "actions/mongoApiActions";
const gs = new General();

export default function CopyMultiDeviceToMapModal({
    selectedLightArray,
    selectedSensorArray,
    selectedDaliCtlArray,
    selectedGatewayArray,
    goBack,
}) {
    const [state, dispatch] = useContext(SMContext);
    const { mapObjAll, activeMapID, userObj } = state;
    const [open, setOpen] = useState(false);
    const [serverObj, setServerObj] = useState({});
    const [fsServerObjArray, setFsServerObjArray] = useState([]);
    const [otherServerMapArray, setOtherServerMapArray] = useState([]);
    const handleOpen = async () => {
        setOpen(true);
        setServerObj(state.serverObj);
        if (userObj.level === 0) {
            const arr = await getDocsWhereFS("SM_server", "array", "", "", "", "", "", "", "", "");
            setFsServerObjArray(arr);
        }
    }
    async function moveToOtherMap(newMapID) {
        const selectedArrayObj = { gatewayArray: selectedGatewayArray, lightArray: selectedLightArray, sensorArray: selectedSensorArray, daliCtlArray: selectedDaliCtlArray };
        const currentMapObj = mapObjAll[activeMapID];
        confirmWindow(
            dispatch,
            `Confirm move selected devices to map [${mapObjAll[newMapID]?.mapName}]?`,
            async () => {
                await gs.waitFor(1000);
                confirmWindow(dispatch, "Same location in new map?", async () => {
                    showBigProgress(dispatch);
                    await mapAddManyDevices(newMapID, selectedArrayObj, currentMapObj);
                    await gs.waitFor(1000);
                    await mapRemoveManyDevices(currentMapObj, selectedArrayObj);
                    hideBigProgress(dispatch);
                    goBack();
                },
                    async () => {
                        await mapAddManyDevices(newMapID, selectedArrayObj, "");
                        await gs.waitFor(1000);
                        await mapRemoveManyDevices(currentMapObj, selectedArrayObj);
                        hideBigProgress(dispatch);
                        goBack();
                    });

            }
        );
    }
    async function copyToOtherMap(newMapID) {
        const selectedArrayObj = { gatewayArray: selectedGatewayArray, lightArray: selectedLightArray, sensorArray: selectedSensorArray, daliCtlArray: selectedDaliCtlArray };
        confirmWindow(
            dispatch,
            `Confirm copy selected devices to map [${mapObjAll[newMapID]?.mapName}]?`,
            async () => {
                await gs.waitFor(1000);
                confirmWindow(
                    dispatch,
                    "Same location in new map?",
                    async () => {
                        showBigProgress(dispatch);
                        const currentMapObj = mapObjAll[activeMapID];
                        await mapAddManyDevices(newMapID, selectedArrayObj, currentMapObj);
                        hideBigProgress(dispatch);
                        goBack();
                    },
                    async () => {
                        showBigProgress(dispatch);
                        await mapAddManyDevices(newMapID, selectedArrayObj, "");
                        hideBigProgress(dispatch);
                        goBack();
                    }
                );

            }
        );
    }
    let modalTitle = "Move/Copy to other map - ";
    if (selectedLightArray.length > 0)
        modalTitle += `${selectedLightArray.length} lights, `;
    if (selectedSensorArray.length > 0)
        modalTitle += `${selectedSensorArray.length} sensors/switches, `;
    if (selectedGatewayArray.length > 0)
        modalTitle += `${selectedGatewayArray.length} gateways, `;
    if (selectedDaliCtlArray.length > 0)
        modalTitle += `${selectedDaliCtlArray.length} dali controllers, `;
    const changeTargetServer = async (selectedServerID) => {
        if (selectedServerID === serverObj.serverID) {
            setOtherServerMapArray([]);
            return;
        }
        const sObj = fsServerObjArray.find((obj) => obj.serverID === selectedServerID);
        if (!sObj) return;
        setServerObj(sObj);
        const res = await mgPost("expressSocket", { type: "fetchAllMap" }, sObj.localserverUrl);
        setOtherServerMapArray(res?.payload);
    }
    return (
        <>
            <Button
                variant="outlined"
                sx={{ marginBottom: "1vh" }}
                onClick={handleOpen}
                size="small"
            >
                Move/Copy to other map
            </Button>
            <ModalSM
                open={open}
                modalTitle={modalTitle}
                onClose={() => setOpen(false)}
                disableBottomClose
                width="85vw"
                height="80vh"
                backgroundColor="lightgrey"
            >
                <div style={{ width: "85vw", display: "flex", flexWrap: "wrap", overflow: "auto" }}>
                    {Object.keys(mapObjAll || {})
                        .filter((mid) => mid !== activeMapID)
                        .sort((a, b) =>
                            mapObjAll[a].mapName.localeCompare(mapObjAll[b].mapName)
                        )
                        .map((mid) => {
                            return (
                                <MapItem key={mid} mapObj={mapObjAll[mid]}
                                    onCopy={async () => {
                                        await copyToOtherMap(mid);
                                        setOpen(false);
                                    }}
                                    onMove={async () => {
                                        await moveToOtherMap(mid);
                                        setOpen(false);
                                    }}
                                />
                            );
                        })}
                </div>
                <Divider10 />
                <AdminContainer>
                    <DivInline>
                        <Box sx={{ border: "1px solid lightgrey", padding: 2 }}>
                            <Typography variant="caption" display="block">{state.serverObj.serverID}</Typography>
                            <Typography variant="caption" display="block">{state.serverObj.localserverUrl}</Typography>
                        </Box>
                        <ArrowForwardIos />
                        <Box sx={{ border: "1px solid lightgrey", padding: 2 }}>
                            <Typography variant="caption" display="block">{serverObj.serverID}</Typography>
                            <Typography variant="caption" display="block">{serverObj.localserverUrl}</Typography>
                        </Box>
                        <Select
                            size="small"
                            value={serverObj.serverID || ""}
                            onChange={async (e) => {
                                const selectedServerID = e.target.value;
                                await changeTargetServer(selectedServerID);
                            }}
                        >
                            {fsServerObjArray.map((obj) => {
                                return (
                                    <MenuItem value={obj.serverID}>{obj.serverID} - {obj.localserverUrl}</MenuItem>
                                )
                            })}
                        </Select>
                    </DivInline>
                    <Divider10 />
                    {
                        (otherServerMapArray || []).length > 0 && (
                            <DivInline>
                                {otherServerMapArray.map((obj, key) => {
                                    return (
                                        <MapItem
                                            key={key}
                                            mapObj={obj}
                                            onCopy={async () => {
                                                const deviceArrayObj = {
                                                    lightArray: selectedLightArray,
                                                    sensorArray: selectedSensorArray,
                                                    gatewayArray: selectedGatewayArray,
                                                    daliCtlArray: selectedDaliCtlArray
                                                };
                                                const currentMapObj = mapObjAll[activeMapID];
                                                const savedTargetMapID = await mgPost(
                                                    "expressSocket",
                                                    { type: "mapAddManyDevices", mapID: obj.mapID, deviceArrayObj, currentMapObj },
                                                    serverObj.localserverUrl
                                                );
                                                if (savedTargetMapID === obj.mapID) {
                                                    alertWindow(dispatch, "Copy successfully");
                                                }
                                            }}
                                            onMove={async () => { }}
                                        />
                                    )
                                })}
                            </DivInline>
                        )
                    }
                    <Divider10 />
                    <DivExist show={state.serverObj.serverID !== serverObj.serverID && otherServerMapArray.length === 0} >
                        <Typography variant="caption">No map found in this server</Typography>
                    </DivExist>
                </AdminContainer>
            </ModalSM>
        </>
    );
}

function MapItem({ mapObj, onCopy, onMove }) {
    const url = mapObj.mapFileName ? `${global.ip}/img/${mapObj.mapFileName}` : mapObj.mapUrl;
    return (
        <DivCol sx={{ margin: 10, width: "12vw" }}>
            <img src={url} style={{ width: "12vw", height: "6vw" }} alt="" />
            <Typography variant="caption">{mapObj.mapName}</Typography>
            <DivInline
                justifyContent="space-around"
                style={{ width: "100%" }}
            >
                <Tooltip title="Move to this map">
                    <DriveFileMove
                        sx={{ cursor: "pointer" }}
                        onClick={async () => {
                            onMove(mapObj);
                        }}
                    />
                </Tooltip>
                <Tooltip title="Copy to this map">
                    <ContentCopy
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            onCopy(mapObj);
                        }}
                    />
                </Tooltip>
            </DivInline>
        </DivCol>
    )
}