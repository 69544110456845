import React, { useState } from "react";
import { Typography } from "@mui/material";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import IconButtonSave from "components/IconButtonSave";
import DivInline from "components/DivInline";
import { showBigProgress, hideBigProgress } from "actions/screenActions";
import { createZoneControl } from "actions/zcActions";
import DivExist from "components/DivExist";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import IconButtonBack from "components/IconButtonBack";
import ZoneControlIcon from "components/ZoneControlIcon";
export default function AddZcToMapPanel({
  handleClose,
  targetX,
  targetY,
  state,
  dispatch,
  modalSize,
  deviceType,
  nameOfDevice,
}) {

  const { activeMapID } = state;
  const [name, setName] = useState("");
  const [selectedDeviceType, setSelectedDeviceType] = useState(""); //zcSceneButton, timer, zcSwitch
  const handleSave = async () => {
    showBigProgress(dispatch);
    const createDeviceType = deviceType || selectedDeviceType;
    await createZoneControl(activeMapID, createDeviceType, name, targetX, targetY);
    hideBigProgress(dispatch);
    handleClose();
  };

  return (
    <>
      <DivInline hide={modalSize === "small"}>
        <IconButtonBack onBtnClick={handleClose} />
        <Typography variant="h6">Create {nameOfDevice} to map</Typography>
      </DivInline>
      <DivExist show={!deviceType}>
        <Divider10 space={5} />
        <SpaceBetweenSelect
          title="Device Type"
          data={selectedDeviceType || ""}
          onChangeValue={(value) => setSelectedDeviceType(value)}
          menuObj={{
            timer: "Vitural Timer",
            zcSceneButton: "Vitural Button",
            zcSwitch: "VituralSwitch",
          }}
        />
      </DivExist>
      <Divider10 space={5} />
      <SpaceBetweenInput
        data={name}
        title="Name"
        onInput={(e) => setName(e.target.value)}
      />
      <DivExist show={deviceType || selectedDeviceType}>
        <Divider10 space={5} />
        <DivInline justifyContent="center">
          <ZoneControlIcon type={deviceType || selectedDeviceType} width={4} sizeUnit={'vw'} />
        </DivInline>
      </DivExist>
      <Divider10 space={5} />
      <DivInline justifyContent="center">
        <IconButtonSave onBtnClick={handleSave} disabled={!name} />
      </DivInline>
    </>
  );
}
