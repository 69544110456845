import React, { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, IconButton } from "@mui/material";
import { Lock, TouchApp } from "@mui/icons-material";
import ButtonSM from "components/ButtonSM";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import { sensorManualExecuteSetting, sensorDisconnectLight } from "actions/sensorActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import SensorChangeLockLvModal from "./SensorChangeLockLvModal";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import DeviceActionLine from "components/DeviceActionLine";
import DeviceIdAddGwLine from "components/DeviceIdAddGwLine";
import ModalSM from "components/ModalSM";
import SensorNearFieldPanel from "./SensorNearFieldPanel";
import General from "@ecoenghk/general";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import GroupActionConfirmOneGroup from "components/GroupActionConfirmOneGroup";
import AdminContainer from "components/AdminContainer";
import IconButtonDelete from "components/IconButtonDelete";
import { confirmWindow, alertWindow } from "actions/screenActions";
import { mgFindOne } from "actions/mongoApiActions";
const gs = new General();
export default function ControlDevicesMotionPanel({ show }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeSensorID, sensorObjAll, lightObjAll, mapObjAll, activeMapID } = state;
  const [activeNearFieldDeviceObj, setActiveNearFieldDeviceObj] = useState({});
  const activeMapObj = mapObjAll[activeMapID];
  const sensorObj = sensorObjAll[activeSensorID];
  const { setting1, serialMap, sensorMap } = sensorObj || {};
  const lightArrayAll = Object.values(serialMap || {});
  const sensorArrayAll = Object.values(sensorMap || {});
  const lightArray = (lightArrayAll || []).filter(
    (s) => s in activeMapObj.lightObj
  );
  // const lightArrayNotInMap = (lightArrayAll || []).filter(
  //   (s) => !Object.keys(activeMapObj.lightObj || {}).includes(s)
  // );
  const sensorArray = (sensorArrayAll || []).filter((s) =>
    Object.keys(activeMapObj.sensorObj || {}).includes(s)
  );
  // const sensorArrayNotInMap = (sensorArrayAll || []).filter(
  //   (s) => !Object.keys(activeMapObj.sensorObj || {}).includes(s)
  // );
  if (!show) return null;
  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column" }}
      >
        <DivInline justifyContent="center">
          <Typography variant="h6" align="center">
            Control Devices
          </Typography>
          <Tooltip title="Manual execute light action">
            <div>
              <IconButton
                size="small"
                disabled={lightArray.length === 0 && sensorArray.length === 0}
                onClick={() => sensorManualExecuteSetting(sensorObj, 1)}
              >
                <TouchApp />
              </IconButton>
            </div>
          </Tooltip>
          <GroupActionConfirmOneGroup groupDeviceObj={sensorObj} settingNumArray={[1]} />
        </DivInline>
        <Divider10 />
        <DivSpaceBetween>
          <Typography>
            <Lock />
            Priority Level
          </Typography>
          <SensorChangeLockLvModal
            sensorObj={sensorObj}
            buttonNo={1}
            existingLv={sensorObj.lockLevelSet1}
            existingRelease={sensorObj.releaseOnDelayEnd1}
          />
        </DivSpaceBetween>
        <Divider10 />
        {lightArrayAll
          .map((s) => {
            const lightObj = lightObjAll[s] || {};
            return (
              <LightRow key={s} serial={s} lightObj={lightObj} serialSetting={setting1[s]} sensorObj={sensorObj} />
            );
          })}
        <Divider10 />
        {sensorArrayAll.map((s) => {
          const sensorObjConnect = sensorObjAll[s] || {};
          return (
            <SensorRow key={s} deviceObj={sensorObjConnect} deviceSetting={setting1[s]} sensorObj={sensorObj} />
          );
        })}
        {/* <DivExist show={lightArrayNotInMap.length > 0}>
          <Divider10 />
          <Typography>Connected lights not in this map:</Typography>
          {lightArrayNotInMap.map((s) => (
            <Typography variant="caption" key={s} display="block">
              {s}
            </Typography>
          ))}
        </DivExist>
        <DivExist show={sensorArrayNotInMap.length > 0}>
          <Divider10 />
          <Typography>Connected sensors not in this map:</Typography>
          {sensorArrayNotInMap.map((s) => (
            <Typography variant="caption" key={s} display="block">
              {s}
            </Typography>
          ))}
        </DivExist> */}
      </div>
      <ModalSM
        open={!gs.isEmptyJson(activeNearFieldDeviceObj)}
        onClose={() => setActiveNearFieldDeviceObj({})}
        disableBottomClose
      >
        <SensorNearFieldPanel
          sensorObj={sensorObj}
          deviceObj={activeNearFieldDeviceObj}
          handleClose={() => setActiveNearFieldDeviceObj("")}
          dispatch={dispatch}
        />
      </ModalSM>
    </>
  )
}

function LightRow({ serial, lightObj, serialSetting, sensorObj }) {
  const [state, dispatch] = useContext(SMContext);
  const [openNearField, setOpenNearField] = useState(false);
  const { nearFieldSetting } = sensorObj || {};
  let lightInMap = lightObj && !gs.isEmptyJson(lightObj);
  const lightDescription = lightInMap ? lightObj?.description : "Unknown light";

  return (
    <div>
      <DivInline>
        <Typography sx={{ marginRight: "1vw" }} onClick={() => alert(JSON.stringify(serialSetting || {}))} variant="caption">{lightDescription}</Typography>
        <DivExist show={lightInMap}>
          <GroupActionConfirmOneDevice deviceObj={sensorObj} controlledDeviceObj={lightObj} settingNumArray={[1]} />
        </DivExist>
      </DivInline>
      <DivInline>
        <DeviceIdAddGwLine deviceID={serial} address={serialSetting?.dtkAdd} gatewayID={serialSetting?.gatewayID} />
        <DivExist show={lightInMap}>
          <ButtonSM
            tooltip="Near field command setting"
            margin={0}
            onClick={() => setOpenNearField(true)}>
            <NearFieldIcon width={2} sizeUnit={'vw'} color={nearFieldSetting?.[`${serial}_1_${lightObj?.shortAdd || 0}`] ? "red" : "#fff"} />
          </ButtonSM>
        </DivExist>
        <DivExist show={!lightInMap}>
          <Typography variant="caption" color="error">Light not in this map</Typography>
          <IconButtonDelete
            tooltip="Force disconnect"
            size="small"
            onBtnClick={async () => {
              confirmWindow(dispatch, "Force disconnect?", async () => {
                const obj = await mgFindOne("serial", "serial", serial);
                if (!obj) {
                  await gs.waitFor(1000);
                  alertWindow(dispatch, `Light ${serial} not found in database`);
                  return;
                }
                await sensorDisconnectLight(sensorObj, sensorObj.gatewayID, obj);
              });

            }}
          />
        </DivExist>
      </DivInline>
      <DeviceActionLine deviceSetting={serialSetting} deviceObj={lightObj} mainDeviceObj={sensorObj} buttonNo={1} />

      <DivExist show={lightInMap && openNearField}>
        <ModalSM open={openNearField} onClose={() => setOpenNearField(false)} disableBottomClose >
          <SensorNearFieldPanel
            sensorObj={sensorObj}
            deviceObj={lightObj}
            handleClose={() => setOpenNearField(false)}
            dispatch={dispatch}
            buttonNo={0}
          />
        </ModalSM>
      </DivExist>
      <Divider10 space={2} />
    </div>
  );
}

function SensorRow({ deviceObj, deviceSetting, sensorObj }) {
  const [state, dispatch] = useContext(SMContext);
  const [openNearField, setOpenNearField] = useState(false);
  const { nearFieldSetting } = sensorObj || {};
  return (
    <div>
      <DivInline>
        <Typography sx={{ marginRight: "1vw" }} onClick={() => alert(JSON.stringify(deviceSetting || {}))} variant="caption">{deviceObj?.sensorName}</Typography>
        <GroupActionConfirmOneDevice deviceObj={sensorObj} controlledDeviceObj={deviceObj} settingNumArray={[1]} />
      </DivInline>
      <DivInline>
        <DeviceIdAddGwLine deviceID={deviceObj?.sensorID} address={deviceSetting?.dtkAdd} gatewayID={deviceSetting?.gatewayID} />
        <AdminContainer>
          <ButtonSM
            tooltip="Near field command setting"
            margin={0}
            onClick={() => setOpenNearField(true)}>
            <NearFieldIcon width={2} sizeUnit={'vw'} color={nearFieldSetting?.[`${deviceObj.sensorID}_1_0`] ? "red" : "#fff"} />
          </ButtonSM>
        </AdminContainer>
      </DivInline>
      <DeviceActionLine deviceSetting={deviceSetting} deviceObj={deviceObj} mainDeviceObj={sensorObj} buttonNo={1} />
      <AdminContainer>
        <ModalSM open={openNearField} onClose={() => setOpenNearField(false)} disableBottomClose >
          <SensorNearFieldPanel
            sensorObj={sensorObj}
            deviceObj={deviceObj}
            handleClose={() => setOpenNearField(false)}
            dispatch={dispatch}
            buttonNo={0}
          />
        </ModalSM>
      </AdminContainer>
    </div >
  );
}