import { useState, useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, Box, Tabs, Tab } from "@mui/material";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import {
  fetchOneGateway,
  gatewayTestLED,
  transferAllSettingsToGateway,
  gatewayDeletePermanently,
  queryDeviceTtyInterface,
  executeRedisCommand,
  queryGatewayOneSetting,
  controlSingleGateway,
  queryGatewayGroupActionStatus,
} from "actions/gatewayActions";
import moment from "moment";
import {
  confirmWindow,
  promptWindow,
  hideBigProgress,
  openSnackbar,
  showBigProgress,
} from "actions/screenActions";
// import CopyDeviceToMapModal from "containers/map/CopyDeviceToMapModal";
import RedisCommandModal from "containers/gateway/RedisCommandModal";
import { ArrowForwardIos } from "@mui/icons-material";
import General from "@ecoenghk/general";
import IconButtonRefresh from "components/IconButtonRefresh";
import InterfaceDtkPanel from "containers/gateway/InterfaceDtkPanel";
import InterfaceMesh255Panel from "containers/gateway/InterfaceMesh255Panel";
import InterfaceEspPanel from "containers/gateway/InterfaceEspPanel";
import EspNowRouteModal from "./EspNowRouteModal";
import ServerConsoleLogFilter from "containers/server/ServerConsoleLogFilter";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
const gs = new General();

export default function SuperAdminPanel() {
  const [state, dispatch] = useContext(SMContext);
  const [interfaceTab, setInterfaceTab] = useState(0);
  const {
    activeGatewayID,
    gatewayObjAll,
  } = state;
  const gatewayObj = gatewayObjAll[activeGatewayID];
  const { serverID, ttyS2, ttyS3, ttyS7, executeRedisResult } = gatewayObj || {};
  let gpActionStatus = gatewayObj.groupActionStatus || {};
  let groupActionStatusMsg = <Typography>-</Typography>;
  if (gpActionStatus?.timeStamp) {
    if (moment().diff(moment(gpActionStatus?.timeStamp), "seconds") < 30) {
      groupActionStatusMsg = <Tooltip title={JSON.stringify(gpActionStatus)}>
        <Typography>Status:{gpActionStatus.status}</Typography>
      </Tooltip>
    }
  }
  const queryQueueNumber = async () => {
    dispatch({
      type: "UPDATED_GATEWAY_PROPERTY",
      payload: { gatewayID: activeGatewayID, updateObj: { executeRedisResult: "" } }
    })
    await gs.waitFor(500);
    await executeRedisCommand(activeGatewayID, "hlen bq:serialPortQueue:jobs");
  }
  return (
    <>
      <Divider10 />
      <Typography variant="h6" align="center">
        Super Admin
      </Typography>
      <Divider10 />
      <SpaceBetweenDiv title="Server ID" data={serverID} />
      <SpaceBetweenButton
        title="Update latest devices survival"
        btnContent="Update"
        variant="outlined"
        btnSize="small"
        marginTop={5}
        marginBottom={5}
        onBtnClick={async () => await queryGatewayOneSetting(activeGatewayID, 0x000c, "queryDeviceSurvival")}
      />
      {/* <DivExist show={serverID ? true : false}>
        <SpaceBetweenButton
          title="Gateway data Server to FS"
          btnContent="update"
          variant="outlined"
          btnSize="small"
          onBtnClick={async () => {
            await fetchOneGateway(dispatch, activeGatewayID);
          }}
        />
        <SpaceBetweenButton
          title="gateway data FS to Server"
          btnContent="Update"
          variant="outlined"
          btnSize="small"
          onBtnClick={async () => {
            await updateServerDataFromFB(
              socket,
              "singleGateway",
              activeGatewayID
            );
            openSnackbar(dispatch, "command sent to server", "info");
          }}
        />
      </DivExist> */}
      <SpaceBetweenDiv
        title="Baudrate"
        data={gatewayObj.baudrate}
        handleRefresh={async () => queryGatewayOneSetting(activeGatewayID, 0x001e, "queryBaudrate")}
      />
      <Divider10 />
      <DivInline>
        <Typography>Interface</Typography>
        <IconButtonRefresh onBtnClick={() => queryDeviceTtyInterface(activeGatewayID)} />
      </DivInline>
      <Box>
        <Tabs value={interfaceTab} onChange={(evt, newTab) => setInterfaceTab(newTab)}>
          <Tab label="ttyS3" />
          <Tab label="ttyS2" />
          <Tab label="ttyS7" />
        </Tabs>
      </Box>
      <Divider10 />
      <DivExist show={interfaceTab === 0}>
        <InterfacePanel
          ttyS_no="ttyS3"
          ttyObj={ttyS3}
          ttyType={ttyS3?.ttyS_type}
          deviceID={activeGatewayID}
          deviceObj={gatewayObj}
          deviceType={'gateway'}
        />
      </DivExist>
      <DivExist show={interfaceTab === 1}>
        <InterfacePanel
          ttyS_no="ttyS2"
          ttyObj={ttyS2}
          ttyType={ttyS2?.ttyS_type}
          deviceID={activeGatewayID}
          deviceObj={gatewayObj}
          deviceType={'gateway'}
        />
      </DivExist>
      <DivExist show={interfaceTab === 2}>
        <InterfacePanel
          ttyS_no="ttyS7"
          ttyObj={ttyS7}
          ttyType={ttyS7?.ttyS_type}
          deviceID={activeGatewayID}
          deviceObj={gatewayObj}
          deviceType={'gateway'}
        />
      </DivExist>

      <Divider10 />
      <SpaceBetweenButton
        title="Transfer all settings to gateway"
        onBtnClick={() => transferAllSettingsToGateway(gatewayObj)}
        btnContent={<ArrowForwardIos />}
      />

      <SpaceBetweenButton
        title="Test LED"
        btnContent={<ArrowForwardIos />}
        onBtnClick={() => gatewayTestLED(gatewayObj)}
      />
      <ServerConsoleLogFilter filterString={activeGatewayID} />
      <EspNowRouteModal gatewayObj={gatewayObj} />
      <RedisCommandModal gatewayObj={gatewayObj} />
      <SpaceBetweenDiv
        title="Query job queue number"
        handleRefresh={queryQueueNumber}
        data={executeRedisResult?.length < 6 ? executeRedisResult : "-"} />
      <SpaceBetweenDiv
        title="Query group action save status"
        handleRefresh={() => queryGatewayGroupActionStatus(activeGatewayID)}
        data={groupActionStatusMsg}
      />
      <SpaceBetweenButton
        title="Delete gateway permanently"
        btnContent={<ArrowForwardIos />}
        onBtnClick={() => {
          confirmWindow(
            dispatch,
            "Confirm to delete gateway permanently?",
            async () => {
              await gatewayDeletePermanently(gatewayObj);
              dispatch({ type: "CLOSED_GATEWAY_DETAIL_MODAL" });
            }
          );
        }}
      />
    </>
  );
}

function InterfacePanel({ ttyS_no, ttyObj, ttyType, deviceID, deviceObj, deviceType }) {
  // if (ttyType === "DTK") return <InterfaceDtkPanel ttyS_no={ttyS_no} deviceID={deviceID} deviceObj={deviceObj} deviceType={deviceType} />
  // if (ttyType === "mesh255") return <InterfaceMesh255Panel ttyS_no={ttyS_no} deviceID={deviceID} deviceObj={deviceObj} deviceType={deviceType} />
  // if (ttyType === "espNow") return <InterfaceEspPanel ttyS_no={ttyS_no} ttyObj={ttyObj} />
  // if (!ttyObj || !ttyType) return <Typography>Interface not found</Typography>
  const num = ttyS_no.replace("ttyS", "");
  const ttyCommandCode = { ttyS3: 0x0028, ttyS2: 0x002a, ttyS7: 0x002c };
  const ttyQueryCode = { ttyS3: 0x0029, ttyS2: 0x002b, ttyS7: 0x002d };
  const ttyQueryCommandName = { ttyS3: "queryDisableTtyS3", ttyS2: "queryDisableTtyS2", ttyS7: "queryDisableTtyS7" };
  return (
    <>
      {
        (!ttyObj || !ttyType) && <Typography>Interface not found</Typography>
      }
      {
        ttyType === "DTK" && <InterfaceDtkPanel ttyS_no={ttyS_no} deviceID={deviceID} deviceObj={deviceObj} deviceType={deviceType} />
      }
      {
        ttyType === "mesh255" && <InterfaceMesh255Panel ttyS_no={ttyS_no} deviceID={deviceID} deviceObj={deviceObj} deviceType={deviceType} />
      }
      {
        ttyType === "espNow" && <InterfaceEspPanel ttyS_no={ttyS_no} ttyObj={ttyObj} />
      }
      {
        ttyType &&
        <SpaceBetweenSwitch
          title={`Disable ${ttyS_no}`}
          data={deviceObj?.[`disableTtyS${num}`] ? true : false}
          onChange={e => {
            const d = e.target.checked;
            controlSingleGateway(deviceID, ttyCommandCode[ttyS_no], { [`disableTtyS${num}`]: d });
          }}
          handleRefresh={async () => queryGatewayOneSetting(deviceID, ttyQueryCode[ttyS_no], ttyQueryCommandName[ttyS_no])}
        />
      }
    </>
  )
}